export const allstate = {
    "states": [
      {
        "id": "1",
        "type": "Union Territory",
        "capital": "Port Blair",
        "code": "AN",
        "name": "Andaman and Nicobar Islands",
        "districts": [
          {
            "id": "1",
            "name": "Nicobar"
          },
          {
            "id": "2",
            "name": "North and Middle Andaman"
          },
          {
            "id": "3",
            "name": "South Andaman"
          }
        ]
      },
      {
        "id": "2",
        "type": "State",
        "capital": "Amaravati",
        "code": "AP",
        "name": "Andhra Pradesh",
        "districts": [
          {
            "id": "1",
            "name": "Anantapur"
          },
          {
            "id": "2",
            "name": "Chittoor"
          },
          {
            "id": "3",
            "name": "East Godavari"
          },
          {
            "id": "4",
            "name": "Guntur"
          },
          {
            "id": "5",
            "name": "Krishna"
          },
          {
            "id": "6",
            "name": "Kurnool"
          },
          {
            "id": "7",
            "name": "Nellore"
          },
          {
            "id": "8",
            "name": "Prakasam"
          },
          {
            "id": "9",
            "name": "Srikakulam"
          },
          {
            "id": "10",
            "name": "Visakhapatnam"
          },
          {
            "id": "11",
            "name": "Vizianagaram"
          },
          {
            "id": "12",
            "name": "West Godavari"
          },
          {
            "id": "13",
            "name": "YSR Kadapa"
          }
        ]
      },
      {
        "id": "3",
        "type": "State",
        "capital": "Itanagar",
        "code": "AR",
        "name": "Arunachal Pradesh",
        "districts": [
          {
            "id": "1",
            "name": "Tawang"
          },
          {
            "id": "2",
            "name": "West Kameng"
          },
          {
            "id": "3",
            "name": "East Kameng"
          },
          {
            "id": "4",
            "name": "Papum Pare"
          },
          {
            "id": "5",
            "name": "Kurung Kumey"
          },
          {
            "id": "6",
            "name": "Kra Daadi"
          },
          {
            "id": "7",
            "name": "Lower Subansiri"
          },
          {
            "id": "8",
            "name": "Upper Subansiri"
          },
          {
            "id": "9",
            "name": "West Siang"
          },
          {
            "id": "10",
            "name": "East Siang"
          },
          {
            "id": "11",
            "name": "Siang"
          },
          {
            "id": "12",
            "name": "Upper Siang"
          },
          {
            "id": "13",
            "name": "Lower Siang"
          },
          {
            "id": "14",
            "name": "Lower Dibang Valley"
          },
          {
            "id": "15",
            "name": "Dibang Valley"
          },
          {
            "id": "16",
            "name": "Anjaw"
          },
          {
            "id": "17",
            "name": "Lohit"
          },
          {
            "id": "18",
            "name": "Namsai"
          },
          {
            "id": "19",
            "name": "Changlang"
          },
          {
            "id": "20",
            "name": "Tirap"
          },
          {
            "id": "21",
            "name": "Longding"
          }
        ]
      },
      {
        "id": "4",
        "type": "State",
        "capital": "Dispur",
        "code": "AS",
        "name": "Assam",
        "districts": [
          {
            "id": "1",
            "name": "Baksa"
          },
          {
            "id": "2",
            "name": "Barpeta"
          },
          {
            "id": "3",
            "name": "Biswanath"
          },
          {
            "id": "4",
            "name": "Bongaigaon"
          },
          {
            "id": "5",
            "name": "Cachar"
          },
          {
            "id": "6",
            "name": "Charaideo"
          },
          {
            "id": "7",
            "name": "Chirang"
          },
          {
            "id": "8",
            "name": "Darrang"
          },
          {
            "id": "9",
            "name": "Dhemaji"
          },
          {
            "id": "10",
            "name": "Dhubri"
          },
          {
            "id": "11",
            "name": "Dibrugarh"
          },
          {
            "id": "12",
            "name": "Goalpara"
          },
          {
            "id": "13",
            "name": "Golaghat"
          },
          {
            "id": "14",
            "name": "Hailakandi"
          },
          {
            "id": "15",
            "name": "Hojai"
          },
          {
            "id": "16",
            "name": "Jorhat"
          },
          {
            "id": "17",
            "name": "Kamrup Metropolitan"
          },
          {
            "id": "18",
            "name": "Kamrup"
          },
          {
            "id": "19",
            "name": "Karbi Anglong"
          },
          {
            "id": "20",
            "name": "Karimganj"
          },
          {
            "id": "21",
            "name": "Kokrajhar"
          },
          {
            "id": "22",
            "name": "Lakhimpur"
          },
          {
            "id": "23",
            "name": "Majuli"
          },
          {
            "id": "24",
            "name": "Morigaon"
          },
          {
            "id": "25",
            "name": "Nagaon"
          },
          {
            "id": "26",
            "name": "Nalbari"
          },
          {
            "id": "27",
            "name": "Dima Hasao"
          },
          {
            "id": "28",
            "name": "Sivasagar"
          },
          {
            "id": "29",
            "name": "Sonitpur"
          },
          {
            "id": "30",
            "name": "South Salmara-Mankachar"
          },
          {
            "id": "31",
            "name": "Tinsukia"
          },
          {
            "id": "32",
            "name": "Udalguri"
          },
          {
            "id": "33",
            "name": "West Karbi Anglong"
          }
        ]
      },
      {
        "id": "5",
        "type": "State",
        "capital": "Patna",
        "code": "BR",
        "name": "Bihar",
        "districts": [
          {
            "id": "1",
            "name": "Araria"
          },
          {
            "id": "2",
            "name": "Arwal"
          },
          {
            "id": "3",
            "name": "Aurangabad"
          },
          {
            "id": "4",
            "name": "Banka"
          },
          {
            "id": "5",
            "name": "Begusarai"
          },
          {
            "id": "6",
            "name": "Bhagalpur"
          },
          {
            "id": "7",
            "name": "Bhojpur"
          },
          {
            "id": "8",
            "name": "Buxar"
          },
          {
            "id": "9",
            "name": "Darbhanga"
          },
          {
            "id": "10",
            "name": "East Champaran (Motihari)"
          },
          {
            "id": "11",
            "name": "Gaya"
          },
          {
            "id": "12",
            "name": "Gopalganj"
          },
          {
            "id": "13",
            "name": "Jamui"
          },
          {
            "id": "14",
            "name": "Jehanabad"
          },
          {
            "id": "15",
            "name": "Kaimur (Bhabua)"
          },
          {
            "id": "16",
            "name": "Katihar"
          },
          {
            "id": "17",
            "name": "Khagaria"
          },
          {
            "id": "18",
            "name": "Kishanganj"
          },
          {
            "id": "19",
            "name": "Lakhisarai"
          },
          {
            "id": "20",
            "name": "Madhepura"
          },
          {
            "id": "21",
            "name": "Madhubani"
          },
          {
            "id": "22",
            "name": "Munger (Monghyr)"
          },
          {
            "id": "23",
            "name": "Muzaffarpur"
          },
          {
            "id": "24",
            "name": "Nalanda"
          },
          {
            "id": "25",
            "name": "Nawada"
          },
          {
            "id": "26",
            "name": "Patna"
          },
          {
            "id": "27",
            "name": "Purnia (Purnea)"
          },
          {
            "id": "28",
            "name": "Rohtas"
          },
          {
            "id": "29",
            "name": "Saharsa"
          },
          {
            "id": "30",
            "name": "Samastipur"
          },
          {
            "id": "31",
            "name": "Saran"
          },
          {
            "id": "32",
            "name": "Sheikhpura"
          },
          {
            "id": "33",
            "name": "Sheohar"
          },
          {
            "id": "34",
            "name": "Sitamarhi"
          },
          {
            "id": "35",
            "name": "Siwan"
          },
          {
            "id": "36",
            "name": "Supaul"
          },
          {
            "id": "37",
            "name": "Vaishali"
          },
          {
            "id": "38",
            "name": "West Champaran"
          }
        ]
      },
      {
        "id": "6",
        "type": "Union Territory",
        "capital": "Chandigarh",
        "code": "CG",
        "name": "Chandigarh",
        "districts": [
          {
            "id": "1",
            "name": "Chandigarh"
          }
        ]
      },
      {
        "id": "7",
        "type": "State",
        "capital": "Bilaspur (Judiciary), Raipur",
        "code": "CH",
        "name": "Chhattisgarh",
        "districts": [
          {
            "id": "1",
            "name": "Balod"
          },
          {
            "id": "2",
            "name": "Baloda Bazar"
          },
          {
            "id": "3",
            "name": "Balrampur"
          },
          {
            "id": "4",
            "name": "Bastar"
          },
          {
            "id": "5",
            "name": "Bemetara"
          },
          {
            "id": "6",
            "name": "Bijapur"
          },
          {
            "id": "7",
            "name": "Bilaspur"
          },
          {
            "id": "8",
            "name": "Dantewada (South Bastar)"
          },
          {
            "id": "9",
            "name": "Dhamtari"
          },
          {
            "id": "10",
            "name": "Durg"
          },
          {
            "id": "11",
            "name": "Gariyaband"
          },
          {
            "id": "12",
            "name": "Janjgir-Champa"
          },
          {
            "id": "13",
            "name": "Jashpur"
          },
          {
            "id": "14",
            "name": "Kabirdham (Kawardha)"
          },
          {
            "id": "15",
            "name": "Kanker (North Bastar)"
          },
          {
            "id": "16",
            "name": "Kondagaon"
          },
          {
            "id": "17",
            "name": "Korba"
          },
          {
            "id": "18",
            "name": "Korea (Koriya)"
          },
          {
            "id": "19",
            "name": "Mahasamund"
          },
          {
            "id": "20",
            "name": "Mungeli"
          },
          {
            "id": "21",
            "name": "Narayanpur"
          },
          {
            "id": "22",
            "name": "Raigarh"
          },
          {
            "id": "23",
            "name": "Raipur"
          },
          {
            "id": "24",
            "name": "Rajnandgaon"
          },
          {
            "id": "25",
            "name": "Sukma"
          },
          {
            "id": "26",
            "name": "Surajpur  "
          },
          {
            "id": "27",
            "name": "Surguja"
          }
        ]
      },
      {
        "id": "8",
        "type": "Union Territory",
        "capital": "Daman",
        "code": "DH",
        "name": "Dadra and Nagar Haveli and Daman and Diu",
        "districts": [
          {
            "id": "1",
            "name": "Daman"
          },
          {
            "id": "2",
            "name": "Diu"
          },
          {
            "id": "3",
            "name": "Dadra & Nagar Haveli"
          }
        ]
      },
      {
        "id": "10",
        "type": "National Capital Territory",
        "capital": "New Delhi",
        "code": "DL",
        "name": "Delhi",
        "districts": [
          {
            "id": "1",
            "name": "Central Delhi"
          },
          {
            "id": "2",
            "name": "East Delhi"
          },
          {
            "id": "3",
            "name": "New Delhi"
          },
          {
            "id": "4",
            "name": "North Delhi"
          },
          {
            "id": "5",
            "name": "North East  Delhi"
          },
          {
            "id": "6",
            "name": "North West  Delhi"
          },
          {
            "id": "7",
            "name": "Shahdara"
          },
          {
            "id": "8",
            "name": "South Delhi"
          },
          {
            "id": "9",
            "name": "South East Delhi"
          },
          {
            "id": "10",
            "name": "South West  Delhi"
          },
          {
            "id": "11",
            "name": "West Delhi"
          }
        ]
      },
      {
        "id": "11",
        "type": "State",
        "capital": "Panaji",
        "code": "GA",
        "name": "Goa",
        "districts": [
          {
            "id": "1",
            "name": "North Goa"
          },
          {
            "id": "2",
            "name": "South Goa"
          }
        ]
      },
      {
        "id": "12",
        "type": "State",
        "capital": "Gandhinagar",
        "code": "GJ",
        "name": "Gujarat",
        "districts": [
          {
            "id": "1",
            "name": "Ahmedabad"
          },
          {
            "id": "2",
            "name": "Amreli"
          },
          {
            "id": "3",
            "name": "Anand"
          },
          {
            "id": "4",
            "name": "Aravalli"
          },
          {
            "id": "5",
            "name": "Banaskantha (Palanpur)"
          },
          {
            "id": "6",
            "name": "Bharuch"
          },
          {
            "id": "7",
            "name": "Bhavnagar"
          },
          {
            "id": "8",
            "name": "Botad"
          },
          {
            "id": "9",
            "name": "Chhota Udepur"
          },
          {
            "id": "10",
            "name": "Dahod"
          },
          {
            "id": "11",
            "name": "Dangs (Ahwa)"
          },
          {
            "id": "12",
            "name": "Devbhoomi Dwarka"
          },
          {
            "id": "13",
            "name": "Gandhinagar"
          },
          {
            "id": "14",
            "name": "Gir Somnath"
          },
          {
            "id": "15",
            "name": "Jamnagar"
          },
          {
            "id": "16",
            "name": "Junagadh"
          },
          {
            "id": "17",
            "name": "Kachchh"
          },
          {
            "id": "18",
            "name": "Kheda (Nadiad)"
          },
          {
            "id": "19",
            "name": "Mahisagar"
          },
          {
            "id": "20",
            "name": "Mehsana"
          },
          {
            "id": "21",
            "name": "Morbi"
          },
          {
            "id": "22",
            "name": "Narmada (Rajpipla)"
          },
          {
            "id": "23",
            "name": "Navsari"
          },
          {
            "id": "24",
            "name": "Panchmahal (Godhra)"
          },
          {
            "id": "25",
            "name": "Patan"
          },
          {
            "id": "26",
            "name": "Porbandar"
          },
          {
            "id": "27",
            "name": "Rajkot"
          },
          {
            "id": "28",
            "name": "Sabarkantha (Himmatnagar)"
          },
          {
            "id": "29",
            "name": "Surat"
          },
          {
            "id": "30",
            "name": "Surendranagar"
          },
          {
            "id": "31",
            "name": "Tapi (Vyara)"
          },
          {
            "id": "32",
            "name": "Vadodara"
          },
          {
            "id": "33",
            "name": "Valsad"
          }
        ]
      },
      {
        "id": "13",
        "type": "State",
        "capital": "Chandigarh",
        "code": "HR",
        "name": "Haryana",
        "districts": [
          {
            "id": "1",
            "name": "Ambala"
          },
          {
            "id": "2",
            "name": "Bhiwani"
          },
          {
            "id": "3",
            "name": "Charkhi Dadri"
          },
          {
            "id": "4",
            "name": "Faridabad"
          },
          {
            "id": "5",
            "name": "Fatehabad"
          },
          {
            "id": "6",
            "name": "Gurgaon"
          },
          {
            "id": "7",
            "name": "Hisar"
          },
          {
            "id": "8",
            "name": "Jhajjar"
          },
          {
            "id": "9",
            "name": "Jind"
          },
          {
            "id": "10",
            "name": "Kaithal"
          },
          {
            "id": "11",
            "name": "Karnal"
          },
          {
            "id": "12",
            "name": "Kurukshetra"
          },
          {
            "id": "13",
            "name": "Mahendragarh"
          },
          {
            "id": "14",
            "name": "Mewat"
          },
          {
            "id": "15",
            "name": "Palwal"
          },
          {
            "id": "16",
            "name": "Panchkula"
          },
          {
            "id": "17",
            "name": "Panipat"
          },
          {
            "id": "18",
            "name": "Rewari"
          },
          {
            "id": "19",
            "name": "Rohtak"
          },
          {
            "id": "20",
            "name": "Sirsa"
          },
          {
            "id": "21",
            "name": "Sonipat"
          },
          {
            "id": "22",
            "name": "Yamunanagar"
          }
        ]
      },
      {
        "id": "14",
        "type": "State",
        "capital": "Shimla",
        "code": "HP",
        "name": "Himachal Pradesh",
        "districts": [
          {
            "id": "1",
            "name": "Bilaspur"
          },
          {
            "id": "2",
            "name": "Chamba"
          },
          {
            "id": "3",
            "name": "Hamirpur"
          },
          {
            "id": "4",
            "name": "Kangra"
          },
          {
            "id": "5",
            "name": "Kinnaur"
          },
          {
            "id": "6",
            "name": "Kullu"
          },
          {
            "id": "7",
            "name": "Lahaul &amp; Spiti"
          },
          {
            "id": "8",
            "name": "Mandi"
          },
          {
            "id": "9",
            "name": "Shimla"
          },
          {
            "id": "10",
            "name": "Sirmaur (Sirmour)"
          },
          {
            "id": "11",
            "name": "Solan"
          },
          {
            "id": "12",
            "name": "Una"
          }
        ]
      },
      {
        "id": "15",
        "type": "Union Territory",
        "capital": "Jammu (Winter), Srinagar (Summer)",
        "code": "JK",
        "name": "Jammu and Kashmir",
        "districts": [
          {
            "id": "1",
            "name": "Anantnag"
          },
          {
            "id": "2",
            "name": "Bandipore"
          },
          {
            "id": "3",
            "name": "Baramulla"
          },
          {
            "id": "4",
            "name": "Budgam"
          },
          {
            "id": "5",
            "name": "Doda"
          },
          {
            "id": "6",
            "name": "Ganderbal"
          },
          {
            "id": "7",
            "name": "Jammu"
          },
          {
            "id": "8",
            "name": "Kargil"
          },
          {
            "id": "9",
            "name": "Kathua"
          },
          {
            "id": "10",
            "name": "Kishtwar"
          },
          {
            "id": "11",
            "name": "Kulgam"
          },
          {
            "id": "12",
            "name": "Kupwara"
          },
          {
            "id": "13",
            "name": "Leh"
          },
          {
            "id": "14",
            "name": "Poonch"
          },
          {
            "id": "15",
            "name": "Pulwama"
          },
          {
            "id": "16",
            "name": "Rajouri"
          },
          {
            "id": "17",
            "name": "Ramban"
          },
          {
            "id": "18",
            "name": "Reasi"
          },
          {
            "id": "19",
            "name": "Samba"
          },
          {
            "id": "20",
            "name": "Shopian"
          },
          {
            "id": "21",
            "name": "Srinagar"
          },
          {
            "id": "22",
            "name": "Udhampur"
          }
        ]
      },
      {
        "id": "16",
        "type": "State",
        "capital": "Ranchi",
        "code": "JH",
        "name": "Jharkhand",
        "districts": [
          {
            "id": "1",
            "name": "Bokaro"
          },
          {
            "id": "2",
            "name": "Chatra"
          },
          {
            "id": "3",
            "name": "Deoghar"
          },
          {
            "id": "4",
            "name": "Dhanbad"
          },
          {
            "id": "5",
            "name": "Dumka"
          },
          {
            "id": "6",
            "name": "East Singhbhum"
          },
          {
            "id": "7",
            "name": "Garhwa"
          },
          {
            "id": "8",
            "name": "Giridih"
          },
          {
            "id": "9",
            "name": "Godda"
          },
          {
            "id": "10",
            "name": "Gumla"
          },
          {
            "id": "11",
            "name": "Hazaribag"
          },
          {
            "id": "12",
            "name": "Jamtara"
          },
          {
            "id": "13",
            "name": "Khunti"
          },
          {
            "id": "14",
            "name": "Koderma"
          },
          {
            "id": "15",
            "name": "Latehar"
          },
          {
            "id": "16",
            "name": "Lohardaga"
          },
          {
            "id": "17",
            "name": "Pakur"
          },
          {
            "id": "18",
            "name": "Palamu"
          },
          {
            "id": "19",
            "name": "Ramgarh"
          },
          {
            "id": "20",
            "name": "Ranchi"
          },
          {
            "id": "21",
            "name": "Sahibganj"
          },
          {
            "id": "22",
            "name": "Seraikela-Kharsawan"
          },
          {
            "id": "23",
            "name": "Simdega"
          },
          {
            "id": "24",
            "name": "West Singhbhum"
          }
        ]
      },
      {
        "id": "17",
        "type": "State",
        "capital": "Bengaluru",
        "code": "KA",
        "name": "Karnataka",
        "districts": [
          {
            "id": "1",
            "name": "Bagalkot"
          },
          {
            "id": "2",
            "name": "Ballari (Bellary)"
          },
          {
            "id": "3",
            "name": "Belagavi (Belgaum)"
          },
          {
            "id": "4",
            "name": "Bengaluru (Bangalore) Rural"
          },
          {
            "id": "5",
            "name": "Bengaluru (Bangalore) Urban"
          },
          {
            "id": "6",
            "name": "Bidar"
          },
          {
            "id": "7",
            "name": "Chamarajanagar"
          },
          {
            "id": "8",
            "name": "Chikballapur"
          },
          {
            "id": "9",
            "name": "Chikkamagaluru (Chikmagalur)"
          },
          {
            "id": "10",
            "name": "Chitradurga"
          },
          {
            "id": "11",
            "name": "Dakshina Kannada"
          },
          {
            "id": "12",
            "name": "Davangere"
          },
          {
            "id": "13",
            "name": "Dharwad"
          },
          {
            "id": "14",
            "name": "Gadag"
          },
          {
            "id": "15",
            "name": "Hassan"
          },
          {
            "id": "16",
            "name": "Haveri"
          },
          {
            "id": "17",
            "name": "Kalaburagi (Gulbarga)"
          },
          {
            "id": "18",
            "name": "Kodagu"
          },
          {
            "id": "19",
            "name": "Kolar"
          },
          {
            "id": "20",
            "name": "Koppal"
          },
          {
            "id": "21",
            "name": "Mandya"
          },
          {
            "id": "22",
            "name": "Mysuru (Mysore)"
          },
          {
            "id": "23",
            "name": "Raichur"
          },
          {
            "id": "24",
            "name": "Ramanagara"
          },
          {
            "id": "25",
            "name": "Shivamogga (Shimoga)"
          },
          {
            "id": "26",
            "name": "Tumakuru (Tumkur)"
          },
          {
            "id": "27",
            "name": "Udupi"
          },
          {
            "id": "28",
            "name": "Uttara Kannada (Karwar)"
          },
          {
            "id": "29",
            "name": "Vijayapura (Bijapur)"
          },
          {
            "id": "30",
            "name": "Yadgir"
          }
        ]
      },
      {
        "id": "18",
        "type": "State",
        "capital": "Thiruvananthapuram",
        "code": "KL",
        "name": "Kerala",
        "districts": [
          {
            "id": "1",
            "name": "Alappuzha"
          },
          {
            "id": "2",
            "name": "Ernakulam"
          },
          {
            "id": "3",
            "name": "Idukki"
          },
          {
            "id": "4",
            "name": "Kannur"
          },
          {
            "id": "5",
            "name": "Kasaragod"
          },
          {
            "id": "6",
            "name": "Kollam"
          },
          {
            "id": "7",
            "name": "Kottayam"
          },
          {
            "id": "8",
            "name": "Kozhikode"
          },
          {
            "id": "9",
            "name": "Malappuram"
          },
          {
            "id": "10",
            "name": "Palakkad"
          },
          {
            "id": "11",
            "name": "Pathanamthitta"
          },
          {
            "id": "12",
            "name": "Thiruvananthapuram"
          },
          {
            "id": "13",
            "name": "Thrissur"
          },
          {
            "id": "14",
            "name": "Wayanad"
          }
        ]
      },
      {
        "id": "19",
        "type": "Union Territory",
        "capital": "Leh, Kargil",
        "code": "LA",
        "name": "Ladakh",
        "districts": [
          {
            "id": "1",
            "name": "Kargil"
          },
          {
            "id": "2",
            "name": "Leh"
          }
        ]
      },
      {
        "id": "20",
        "type": "Union Territory",
        "capital": "Kavaratti",
        "code": "LD",
        "name": "Lakshadweep",
        "districts": [
          {
            "id": "1",
            "name": "Agatti"
          },
          {
            "id": "2",
            "name": "Amini"
          },
          {
            "id": "3",
            "name": "Androth"
          },
          {
            "id": "4",
            "name": "Bithra"
          },
          {
            "id": "5",
            "name": "Chethlath"
          },
          {
            "id": "6",
            "name": "Kavaratti"
          },
          {
            "id": "7",
            "name": "Kadmath"
          },
          {
            "id": "8",
            "name": "Kalpeni"
          },
          {
            "id": "9",
            "name": "Kilthan"
          },
          {
            "id": "10",
            "name": "Minicoy"
          }
        ]
      },
      {
        "id": "21",
        "type": "State",
        "capital": "Bhopal",
        "code": "MP",
        "name": "Madhya Pradesh",
        "districts": [
          {
            "id": "1",
            "name": "Agar Malwa"
          },
          {
            "id": "2",
            "name": "Alirajpur"
          },
          {
            "id": "3",
            "name": "Anuppur"
          },
          {
            "id": "4",
            "name": "Ashoknagar"
          },
          {
            "id": "5",
            "name": "Balaghat"
          },
          {
            "id": "6",
            "name": "Barwani"
          },
          {
            "id": "7",
            "name": "Betul"
          },
          {
            "id": "8",
            "name": "Bhind"
          },
          {
            "id": "9",
            "name": "Bhopal"
          },
          {
            "id": "10",
            "name": "Burhanpur"
          },
          {
            "id": "11",
            "name": "Chhatarpur"
          },
          {
            "id": "12",
            "name": "Chhindwara"
          },
          {
            "id": "13",
            "name": "Damoh"
          },
          {
            "id": "14",
            "name": "Datia"
          },
          {
            "id": "15",
            "name": "Dewas"
          },
          {
            "id": "16",
            "name": "Dhar"
          },
          {
            "id": "17",
            "name": "Dindori"
          },
          {
            "id": "18",
            "name": "Guna"
          },
          {
            "id": "19",
            "name": "Gwalior"
          },
          {
            "id": "20",
            "name": "Harda"
          },
          {
            "id": "21",
            "name": "Hoshangabad"
          },
          {
            "id": "22",
            "name": "Indore"
          },
          {
            "id": "23",
            "name": "Jabalpur"
          },
          {
            "id": "24",
            "name": "Jhabua"
          },
          {
            "id": "25",
            "name": "Katni"
          },
          {
            "id": "26",
            "name": "Khandwa"
          },
          {
            "id": "27",
            "name": "Khargone"
          },
          {
            "id": "28",
            "name": "Mandla"
          },
          {
            "id": "29",
            "name": "Mandsaur"
          },
          {
            "id": "30",
            "name": "Morena"
          },
          {
            "id": "31",
            "name": "Narsinghpur"
          },
          {
            "id": "32",
            "name": "Neemuch"
          },
          {
            "id": "33",
            "name": "Panna"
          },
          {
            "id": "34",
            "name": "Raisen"
          },
          {
            "id": "35",
            "name": "Rajgarh"
          },
          {
            "id": "36",
            "name": "Ratlam"
          },
          {
            "id": "37",
            "name": "Rewa"
          },
          {
            "id": "38",
            "name": "Sagar"
          },
          {
            "id": "39",
            "name": "Satna"
          },
          {
            "id": "40",
            "name": "Sehore"
          },
          {
            "id": "41",
            "name": "Seoni"
          },
          {
            "id": "42",
            "name": "Shahdol"
          },
          {
            "id": "43",
            "name": "Shajapur"
          },
          {
            "id": "44",
            "name": "Sheopur"
          },
          {
            "id": "45",
            "name": "Shivpuri"
          },
          {
            "id": "46",
            "name": "Sidhi"
          },
          {
            "id": "47",
            "name": "Singrauli"
          },
          {
            "id": "48",
            "name": "Tikamgarh"
          },
          {
            "id": "49",
            "name": "Ujjain"
          },
          {
            "id": "50",
            "name": "Umaria"
          },
          {
            "id": "51",
            "name": "Vidisha"
          }
        ]
      },
      {
        "id": "22",
        "type": "State",
        "capital": "Mumbai",
        "code": "MH",
        "name": "Maharashtra",
        "districts": [
          {
            "id": "1",
            "name": "Ahmednagar"
          },
          {
            "id": "2",
            "name": "Akola"
          },
          {
            "id": "3",
            "name": "Amravati"
          },
          {
            "id": "4",
            "name": "Aurangabad"
          },
          {
            "id": "5",
            "name": "Beed"
          },
          {
            "id": "6",
            "name": "Bhandara"
          },
          {
            "id": "7",
            "name": "Buldhana"
          },
          {
            "id": "8",
            "name": "Chandrapur"
          },
          {
            "id": "9",
            "name": "Dhule"
          },
          {
            "id": "10",
            "name": "Gadchiroli"
          },
          {
            "id": "11",
            "name": "Gondia"
          },
          {
            "id": "12",
            "name": "Hingoli"
          },
          {
            "id": "13",
            "name": "Jalgaon"
          },
          {
            "id": "14",
            "name": "Jalna"
          },
          {
            "id": "15",
            "name": "Kolhapur"
          },
          {
            "id": "16",
            "name": "Latur"
          },
          {
            "id": "17",
            "name": "Mumbai City"
          },
          {
            "id": "18",
            "name": "Mumbai Suburban"
          },
          {
            "id": "19",
            "name": "Nagpur"
          },
          {
            "id": "20",
            "name": "Nanded"
          },
          {
            "id": "21",
            "name": "Nandurbar"
          },
          {
            "id": "22",
            "name": "Nashik"
          },
          {
            "id": "23",
            "name": "Osmanabad"
          },
          {
            "id": "24",
            "name": "Palghar"
          },
          {
            "id": "25",
            "name": "Parbhani"
          },
          {
            "id": "26",
            "name": "Pune"
          },
          {
            "id": "27",
            "name": "Raigad"
          },
          {
            "id": "28",
            "name": "Ratnagiri"
          },
          {
            "id": "29",
            "name": "Sangli"
          },
          {
            "id": "30",
            "name": "Satara"
          },
          {
            "id": "31",
            "name": "Sindhudurg"
          },
          {
            "id": "32",
            "name": "Solapur"
          },
          {
            "id": "33",
            "name": "Thane"
          },
          {
            "id": "34",
            "name": "Wardha"
          },
          {
            "id": "35",
            "name": "Washim"
          },
          {
            "id": "36",
            "name": "Yavatmal"
          }
        ]
      },
      {
        "id": "23",
        "type": "State",
        "capital": "Imphal",
        "code": "MN",
        "name": "Manipur",
        "districts": [
          {
            "id": "1",
            "name": "Bishnupur"
          },
          {
            "id": "2",
            "name": "Chandel"
          },
          {
            "id": "3",
            "name": "Churachandpur"
          },
          {
            "id": "4",
            "name": "Imphal East"
          },
          {
            "id": "5",
            "name": "Imphal West"
          },
          {
            "id": "6",
            "name": "Jiribam"
          },
          {
            "id": "7",
            "name": "Kakching"
          },
          {
            "id": "8",
            "name": "Kamjong"
          },
          {
            "id": "9",
            "name": "Kangpokpi"
          },
          {
            "id": "10",
            "name": "Noney"
          },
          {
            "id": "11",
            "name": "Pherzawl"
          },
          {
            "id": "12",
            "name": "Senapati"
          },
          {
            "id": "13",
            "name": "Tamenglong"
          },
          {
            "id": "14",
            "name": "Tengnoupal"
          },
          {
            "id": "15",
            "name": "Thoubal"
          },
          {
            "id": "16",
            "name": "Ukhrul"
          }
        ]
      },
      {
        "id": "24",
        "type": "State",
        "capital": "Shillong",
        "code": "ML",
        "name": "Meghalaya",
        "districts": [
          {
            "id": "1",
            "name": "East Garo Hills"
          },
          {
            "id": "2",
            "name": "East Jaintia Hills"
          },
          {
            "id": "3",
            "name": "East Khasi Hills"
          },
          {
            "id": "4",
            "name": "North Garo Hills"
          },
          {
            "id": "5",
            "name": "Ri Bhoi"
          },
          {
            "id": "6",
            "name": "South Garo Hills"
          },
          {
            "id": "7",
            "name": "South West Garo Hills "
          },
          {
            "id": "8",
            "name": "South West Khasi Hills"
          },
          {
            "id": "9",
            "name": "West Garo Hills"
          },
          {
            "id": "10",
            "name": "West Jaintia Hills"
          },
          {
            "id": "11",
            "name": "West Khasi Hills"
          }
        ]
      },
      {
        "id": "25",
        "type": "State",
        "capital": "Aizawl",
        "code": "MZ",
        "name": "Mizoram",
        "districts": [
          {
            "id": "1",
            "name": "Aizawl"
          },
          {
            "id": "2",
            "name": "Champhai"
          },
          {
            "id": "3",
            "name": "Kolasib"
          },
          {
            "id": "4",
            "name": "Lawngtlai"
          },
          {
            "id": "5",
            "name": "Lunglei"
          },
          {
            "id": "6",
            "name": "Mamit"
          },
          {
            "id": "7",
            "name": "Saiha"
          },
          {
            "id": "8",
            "name": "Serchhip"
          }
        ]
      },
      {
        "id": "26",
        "type": "State",
        "capital": "Kohima",
        "code": "NL",
        "name": "Nagaland",
        "districts": [
          {
            "id": "1",
            "name": "Dimapur"
          },
          {
            "id": "2",
            "name": "Kiphire"
          },
          {
            "id": "3",
            "name": "Kohima"
          },
          {
            "id": "4",
            "name": "Longleng"
          },
          {
            "id": "5",
            "name": "Mokokchung"
          },
          {
            "id": "6",
            "name": "Mon"
          },
          {
            "id": "7",
            "name": "Peren"
          },
          {
            "id": "8",
            "name": "Phek"
          },
          {
            "id": "9",
            "name": "Tuensang"
          },
          {
            "id": "10",
            "name": "Wokha"
          },
          {
            "id": "11",
            "name": "Zunheboto"
          }
        ]
      },
      {
        "id": "27",
        "type": "State",
        "capital": "Bhubaneswar",
        "code": "OR",
        "name": "Odisha",
        "districts": [
          {
            "id": "1",
            "name": "Angul"
          },
          {
            "id": "2",
            "name": "Balangir"
          },
          {
            "id": "3",
            "name": "Balasore"
          },
          {
            "id": "4",
            "name": "Bargarh"
          },
          {
            "id": "5",
            "name": "Bhadrak"
          },
          {
            "id": "6",
            "name": "Boudh"
          },
          {
            "id": "7",
            "name": "Cuttack"
          },
          {
            "id": "8",
            "name": "Deogarh"
          },
          {
            "id": "9",
            "name": "Dhenkanal"
          },
          {
            "id": "10",
            "name": "Gajapati"
          },
          {
            "id": "11",
            "name": "Ganjam"
          },
          {
            "id": "12",
            "name": "Jagatsinghapur"
          },
          {
            "id": "13",
            "name": "Jajpur"
          },
          {
            "id": "14",
            "name": "Jharsuguda"
          },
          {
            "id": "15",
            "name": "Kalahandi"
          },
          {
            "id": "16",
            "name": "Kandhamal"
          },
          {
            "id": "17",
            "name": "Kendrapara"
          },
          {
            "id": "18",
            "name": "Kendujhar (Keonjhar)"
          },
          {
            "id": "19",
            "name": "Khordha"
          },
          {
            "id": "20",
            "name": "Koraput"
          },
          {
            "id": "21",
            "name": "Malkangiri"
          },
          {
            "id": "22",
            "name": "Mayurbhanj"
          },
          {
            "id": "23",
            "name": "Nabarangpur"
          },
          {
            "id": "24",
            "name": "Nayagarh"
          },
          {
            "id": "25",
            "name": "Nuapada"
          },
          {
            "id": "26",
            "name": "Puri"
          },
          {
            "id": "27",
            "name": "Rayagada"
          },
          {
            "id": "28",
            "name": "Sambalpur"
          },
          {
            "id": "29",
            "name": "Sonepur"
          },
          {
            "id": "30",
            "name": "Sundargarh"
          }
        ]
      },
      {
        "id": "28",
        "type": "Union Territory",
        "capital": "Puducherry",
        "code": "PY",
        "name": "Puducherry",
        "districts": [
          {
            "id": "1",
            "name": "Karaikal"
          },
          {
            "id": "2",
            "name": "Mahe"
          },
          {
            "id": "3",
            "name": "Pondicherry"
          },
          {
            "id": "4",
            "name": "Yanam"
          }
        ]
      },
      {
        "id": "29",
        "type": "State",
        "capital": "Chandigarh",
        "code": "PB",
        "name": "Punjab",
        "districts": [
          {
            "id": "1",
            "name": "Amritsar"
          },
          {
            "id": "2",
            "name": "Barnala"
          },
          {
            "id": "3",
            "name": "Bathinda"
          },
          {
            "id": "4",
            "name": "Faridkot"
          },
          {
            "id": "5",
            "name": "Fatehgarh Sahib"
          },
          {
            "id": "6",
            "name": "Fazilka"
          },
          {
            "id": "7",
            "name": "Ferozepur"
          },
          {
            "id": "8",
            "name": "Gurdaspur"
          },
          {
            "id": "9",
            "name": "Hoshiarpur"
          },
          {
            "id": "10",
            "name": "Jalandhar"
          },
          {
            "id": "11",
            "name": "Kapurthala"
          },
          {
            "id": "12",
            "name": "Ludhiana"
          },
          {
            "id": "13",
            "name": "Mansa"
          },
          {
            "id": "14",
            "name": "Moga"
          },
          {
            "id": "15",
            "name": "Muktsar"
          },
          {
            "id": "16",
            "name": "Nawanshahr (Shahid Bhagat Singh Nagar)"
          },
          {
            "id": "17",
            "name": "Pathankot"
          },
          {
            "id": "18",
            "name": "Patiala"
          },
          {
            "id": "19",
            "name": "Rupnagar"
          },
          {
            "id": "20",
            "name": "Sahibzada Ajit Singh Nagar (Mohali)"
          },
          {
            "id": "21",
            "name": "Sangrur"
          },
          {
            "id": "22",
            "name": "Tarn Taran"
          }
        ]
      },
      {
        "id": "30",
        "type": "State",
        "capital": "Jaipur",
        "code": "RJ",
        "name": "Rajasthan",
        "districts": [
          {
            "id": "1",
            "name": "Ajmer"
          },
          {
            "id": "2",
            "name": "Alwar"
          },
          {
            "id": "3",
            "name": "Banswara"
          },
          {
            "id": "4",
            "name": "Baran"
          },
          {
            "id": "5",
            "name": "Barmer"
          },
          {
            "id": "6",
            "name": "Bharatpur"
          },
          {
            "id": "7",
            "name": "Bhilwara"
          },
          {
            "id": "8",
            "name": "Bikaner"
          },
          {
            "id": "9",
            "name": "Bundi"
          },
          {
            "id": "10",
            "name": "Chittorgarh"
          },
          {
            "id": "11",
            "name": "Churu"
          },
          {
            "id": "12",
            "name": "Dausa"
          },
          {
            "id": "13",
            "name": "Dholpur"
          },
          {
            "id": "14",
            "name": "Dungarpur"
          },
          {
            "id": "15",
            "name": "Hanumangarh"
          },
          {
            "id": "16",
            "name": "Jaipur"
          },
          {
            "id": "17",
            "name": "Jaisalmer"
          },
          {
            "id": "18",
            "name": "Jalore"
          },
          {
            "id": "19",
            "name": "Jhalawar"
          },
          {
            "id": "20",
            "name": "Jhunjhunu"
          },
          {
            "id": "21",
            "name": "Jodhpur"
          },
          {
            "id": "22",
            "name": "Karauli"
          },
          {
            "id": "23",
            "name": "Kota"
          },
          {
            "id": "24",
            "name": "Nagaur"
          },
          {
            "id": "25",
            "name": "Pali"
          },
          {
            "id": "26",
            "name": "Pratapgarh"
          },
          {
            "id": "27",
            "name": "Rajsamand"
          },
          {
            "id": "28",
            "name": "Sawai Madhopur"
          },
          {
            "id": "29",
            "name": "Sikar"
          },
          {
            "id": "30",
            "name": "Sirohi"
          },
          {
            "id": "31",
            "name": "Sri Ganganagar"
          },
          {
            "id": "32",
            "name": "Tonk"
          },
          {
            "id": "33",
            "name": "Udaipur"
          }
        ]
      },
      {
        "id": "31",
        "type": "State",
        "capital": "Gangtok",
        "code": "SK",
        "name": "Sikkim",
        "districts": [
          {
            "id": "1",
            "name": "East Sikkim"
          },
          {
            "id": "2",
            "name": "North Sikkim"
          },
          {
            "id": "3",
            "name": "South Sikkim"
          },
          {
            "id": "4",
            "name": "West Sikkim"
          }
        ]
      },
      {
        "id": "32",
        "type": "State",
        "capital": "Chennai",
        "code": "TN",
        "name": "Tamil Nadu",
        "districts": [
          {
            "id": "1",
            "name": "Ariyalur"
          },
          {
            "id": "2",
            "name": "Chennai"
          },
          {
            "id": "3",
            "name": "Coimbatore"
          },
          {
            "id": "4",
            "name": "Cuddalore"
          },
          {
            "id": "5",
            "name": "Dharmapuri"
          },
          {
            "id": "6",
            "name": "Dindigul"
          },
          {
            "id": "7",
            "name": "Erode"
          },
          {
            "id": "8",
            "name": "Kanchipuram"
          },
          {
            "id": "9",
            "name": "Kanyakumari"
          },
          {
            "id": "10",
            "name": "Karur"
          },
          {
            "id": "11",
            "name": "Krishnagiri"
          },
          {
            "id": "12",
            "name": "Madurai"
          },
          {
            "id": "13",
            "name": "Nagapattinam"
          },
          {
            "id": "14",
            "name": "Namakkal"
          },
          {
            "id": "15",
            "name": "Nilgiris"
          },
          {
            "id": "16",
            "name": "Perambalur"
          },
          {
            "id": "17",
            "name": "Pudukkottai"
          },
          {
            "id": "18",
            "name": "Ramanathapuram"
          },
          {
            "id": "19",
            "name": "Salem"
          },
          {
            "id": "20",
            "name": "Sivaganga"
          },
          {
            "id": "21",
            "name": "Thanjavur"
          },
          {
            "id": "22",
            "name": "Theni"
          },
          {
            "id": "23",
            "name": "Thoothukudi (Tuticorin)"
          },
          {
            "id": "24",
            "name": "Tiruchirappalli"
          },
          {
            "id": "25",
            "name": "Tirunelveli"
          },
          {
            "id": "26",
            "name": "Tiruppur"
          },
          {
            "id": "27",
            "name": "Tiruvallur"
          },
          {
            "id": "28",
            "name": "Tiruvannamalai"
          },
          {
            "id": "29",
            "name": "Tiruvarur"
          },
          {
            "id": "30",
            "name": "Vellore"
          },
          {
            "id": "31",
            "name": "Viluppuram"
          },
          {
            "id": "32",
            "name": "Virudhunagar"
          }
        ]
      },
      {
        "id": "33",
        "type": "State",
        "capital": "Hyderabad",
        "code": "TS",
        "name": "Telangana",
        "districts": [
          {
            "id": "1",
            "name": "Adilabad"
          },
          {
            "id": "2",
            "name": "Bhadradri Kothagudem"
          },
          {
            "id": "3",
            "name": "Hyderabad"
          },
          {
            "id": "4",
            "name": "Jagtial"
          },
          {
            "id": "5",
            "name": "Jangaon"
          },
          {
            "id": "6",
            "name": "Jayashankar Bhoopalpally"
          },
          {
            "id": "7",
            "name": "Jogulamba Gadwal"
          },
          {
            "id": "8",
            "name": "Kamareddy"
          },
          {
            "id": "9",
            "name": "Karimnagar"
          },
          {
            "id": "10",
            "name": "Khammam"
          },
          {
            "id": "11",
            "name": "Komaram Bheem Asifabad"
          },
          {
            "id": "12",
            "name": "Mahabubabad"
          },
          {
            "id": "13",
            "name": "Mahabubnagar"
          },
          {
            "id": "14",
            "name": "Mancherial"
          },
          {
            "id": "15",
            "name": "Medak"
          },
          {
            "id": "16",
            "name": "Medchal"
          },
          {
            "id": "17",
            "name": "Nagarkurnool"
          },
          {
            "id": "18",
            "name": "Nalgonda"
          },
          {
            "id": "19",
            "name": "Nirmal"
          },
          {
            "id": "20",
            "name": "Nizamabad"
          },
          {
            "id": "21",
            "name": "Peddapalli"
          },
          {
            "id": "22",
            "name": "Rajanna Sircilla"
          },
          {
            "id": "23",
            "name": "Rangareddy"
          },
          {
            "id": "24",
            "name": "Sangareddy"
          },
          {
            "id": "25",
            "name": "Siddipet"
          },
          {
            "id": "26",
            "name": "Suryapet"
          },
          {
            "id": "27",
            "name": "Vikarabad"
          },
          {
            "id": "28",
            "name": "Wanaparthy"
          },
          {
            "id": "29",
            "name": "Warangal (Rural)"
          },
          {
            "id": "30",
            "name": "Warangal (Urban)"
          },
          {
            "id": "31",
            "name": "Yadadri Bhuvanagiri"
          }
        ]
      },
      {
        "id": "34",
        "type": "State",
        "capital": "Agartala",
        "code": "TR",
        "name": "Tripura",
        "districts": [
          {
            "id": "1",
            "name": "Dhalai"
          },
          {
            "id": "2",
            "name": "Gomati"
          },
          {
            "id": "3",
            "name": "Khowai"
          },
          {
            "id": "4",
            "name": "North Tripura"
          },
          {
            "id": "5",
            "name": "Sepahijala"
          },
          {
            "id": "6",
            "name": "South Tripura"
          },
          {
            "id": "7",
            "name": "Unakoti"
          },
          {
            "id": "8",
            "name": "West Tripura"
          }
        ]
      },
      {
        "id": "35",
        "type": "State",
        "capital": "Lucknow",
        "code": "UP",
        "name": "Uttar Pradesh",
        "districts": [
          {
            "id": "1",
            "name": "Agra"
          },
          {
            "id": "2",
            "name": "Aligarh"
          },
          {
            "id": "3",
            "name": "Allahabad"
          },
          {
            "id": "4",
            "name": "Ambedkar Nagar"
          },
          {
            "id": "5",
            "name": "Amethi (Chatrapati Sahuji Mahraj Nagar)"
          },
          {
            "id": "6",
            "name": "Amroha (J.P. Nagar)"
          },
          {
            "id": "7",
            "name": "Auraiya"
          },
          {
            "id": "8",
            "name": "Azamgarh"
          },
          {
            "id": "9",
            "name": "Baghpat"
          },
          {
            "id": "10",
            "name": "Bahraich"
          },
          {
            "id": "11",
            "name": "Ballia"
          },
          {
            "id": "12",
            "name": "Balrampur"
          },
          {
            "id": "13",
            "name": "Banda"
          },
          {
            "id": "14",
            "name": "Barabanki"
          },
          {
            "id": "15",
            "name": "Bareilly"
          },
          {
            "id": "16",
            "name": "Basti"
          },
          {
            "id": "17",
            "name": "Bhadohi"
          },
          {
            "id": "18",
            "name": "Bijnor"
          },
          {
            "id": "19",
            "name": "Budaun"
          },
          {
            "id": "20",
            "name": "Bulandshahr"
          },
          {
            "id": "21",
            "name": "Chandauli"
          },
          {
            "id": "22",
            "name": "Chitrakoot"
          },
          {
            "id": "23",
            "name": "Deoria"
          },
          {
            "id": "24",
            "name": "Etah"
          },
          {
            "id": "25",
            "name": "Etawah"
          },
          {
            "id": "26",
            "name": "Faizabad"
          },
          {
            "id": "27",
            "name": "Farrukhabad"
          },
          {
            "id": "28",
            "name": "Fatehpur"
          },
          {
            "id": "29",
            "name": "Firozabad"
          },
          {
            "id": "30",
            "name": "Gautam Buddha Nagar"
          },
          {
            "id": "31",
            "name": "Ghaziabad"
          },
          {
            "id": "32",
            "name": "Ghazipur"
          },
          {
            "id": "33",
            "name": "Gonda"
          },
          {
            "id": "34",
            "name": "Gorakhpur"
          },
          {
            "id": "35",
            "name": "Hamirpur"
          },
          {
            "id": "36",
            "name": "Hapur (Panchsheel Nagar)"
          },
          {
            "id": "37",
            "name": "Hardoi"
          },
          {
            "id": "38",
            "name": "Hathras"
          },
          {
            "id": "39",
            "name": "Jalaun"
          },
          {
            "id": "40",
            "name": "Jaunpur"
          },
          {
            "id": "41",
            "name": "Jhansi"
          },
          {
            "id": "42",
            "name": "Kannauj"
          },
          {
            "id": "43",
            "name": "Kanpur Dehat"
          },
          {
            "id": "44",
            "name": "Kanpur Nagar"
          },
          {
            "id": "45",
            "name": "Kanshiram Nagar (Kasganj)"
          },
          {
            "id": "46",
            "name": "Kaushambi"
          },
          {
            "id": "47",
            "name": "Kushinagar (Padrauna)"
          },
          {
            "id": "48",
            "name": "Lakhimpur - Kheri"
          },
          {
            "id": "49",
            "name": "Lalitpur"
          },
          {
            "id": "50",
            "name": "Lucknow"
          },
          {
            "id": "51",
            "name": "Maharajganj"
          },
          {
            "id": "52",
            "name": "Mahoba"
          },
          {
            "id": "53",
            "name": "Mainpuri"
          },
          {
            "id": "54",
            "name": "Mathura"
          },
          {
            "id": "55",
            "name": "Mau"
          },
          {
            "id": "56",
            "name": "Meerut"
          },
          {
            "id": "57",
            "name": "Mirzapur"
          },
          {
            "id": "58",
            "name": "Moradabad"
          },
          {
            "id": "59",
            "name": "Muzaffarnagar"
          },
          {
            "id": "60",
            "name": "Pilibhit"
          },
          {
            "id": "61",
            "name": "Pratapgarh"
          },
          {
            "id": "62",
            "name": "RaeBareli"
          },
          {
            "id": "63",
            "name": "Rampur"
          },
          {
            "id": "64",
            "name": "Saharanpur"
          },
          {
            "id": "65",
            "name": "Sambhal (Bhim Nagar)"
          },
          {
            "id": "66",
            "name": "Sant Kabir Nagar"
          },
          {
            "id": "67",
            "name": "Shahjahanpur"
          },
          {
            "id": "68",
            "name": "Shamali (Prabuddh Nagar)"
          },
          {
            "id": "69",
            "name": "Shravasti"
          },
          {
            "id": "70",
            "name": "Siddharth Nagar"
          },
          {
            "id": "71",
            "name": "Sitapur"
          },
          {
            "id": "72",
            "name": "Sonbhadra"
          },
          {
            "id": "73",
            "name": "Sultanpur"
          },
          {
            "id": "74",
            "name": "Unnao"
          },
          {
            "id": "75",
            "name": "Varanasi"
          }
        ]
      },
      {
        "id": "36",
        "type": "State",
        "capital": "Dehradun",
        "code": "UK",
        "name": "Uttarakhand",
        "districts": [
          {
            "id": "1",
            "name": "Almora"
          },
          {
            "id": "2",
            "name": "Bageshwar"
          },
          {
            "id": "3",
            "name": "Chamoli"
          },
          {
            "id": "4",
            "name": "Champawat"
          },
          {
            "id": "5",
            "name": "Dehradun"
          },
          {
            "id": "6",
            "name": "Haridwar"
          },
          {
            "id": "7",
            "name": "Nainital"
          },
          {
            "id": "8",
            "name": "Pauri Garhwal"
          },
          {
            "id": "9",
            "name": "Pithoragarh"
          },
          {
            "id": "10",
            "name": "Rudraprayag"
          },
          {
            "id": "11",
            "name": "Tehri Garhwal"
          },
          {
            "id": "12",
            "name": "Udham Singh Nagar"
          },
          {
            "id": "13",
            "name": "Uttarkashi"
          }
        ]
      },
      {
        "id": "37",
        "type": "State",
        "capital": "Kolkata",
        "code": "WB",
        "name": "West Bengal",
        "districts": [
          {
            "id": "1",
            "name": "Alipurduar"
          },
          {
            "id": "2",
            "name": "Bankura"
          },
          {
            "id": "3",
            "name": "Birbhum"
          },
          {
            "id": "4",
            "name": "Burdwan (Bardhaman)"
          },
          {
            "id": "5",
            "name": "Cooch Behar"
          },
          {
            "id": "6",
            "name": "Dakshin Dinajpur (South Dinajpur)"
          },
          {
            "id": "7",
            "name": "Darjeeling"
          },
          {
            "id": "8",
            "name": "Hooghly"
          },
          {
            "id": "9",
            "name": "Howrah"
          },
          {
            "id": "10",
            "name": "Jalpaiguri"
          },
          {
            "id": "11",
            "name": "Kalimpong"
          },
          {
            "id": "12",
            "name": "Kolkata"
          },
          {
            "id": "13",
            "name": "Malda"
          },
          {
            "id": "14",
            "name": "Murshidabad"
          },
          {
            "id": "15",
            "name": "Nadia"
          },
          {
            "id": "16",
            "name": "North 24 Parganas"
          },
          {
            "id": "17",
            "name": "Paschim Medinipur (West Medinipur)"
          },
          {
            "id": "18",
            "name": "Purba Medinipur (East Medinipur)"
          },
          {
            "id": "19",
            "name": "Purulia"
          },
          {
            "id": "20",
            "name": "South 24 Parganas"
          },
          {
            "id": "21",
            "name": "Uttar Dinajpur (North Dinajpur)"
          }
        ]
      }
    ]
  }