import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { useLocation } from "react-router";
import Table from "../table/table";
import { allstate, datetimeFormat, isEmpty } from "../../lib/common";

const options = [
  { value: "all", label: "All" },
  { value: "Approved", label: "Approved" },
  { value: "Blocked", label: "Blocked" },
];
const options1 = [
  { value: "Name all", label: " All" },
  { value: "ascending", label: "A-Z" },
  { value: "descending", label: "Z-A" },
];

const options2 = [
  { value: "Register all", label: "All" },
  { value: "older", label: "Older" },
  { value: "latest", label: "Latest" },
];



function Riders() {
  const axiosFile = useAxiosFile();

  const [listData, setListData] = useState([]);

  const [all, setAll] = useState("all");
  const [alllabel, setAlllabel] = useState("All");

  const [names, setNames] = useState("all");
  const [nameslabel, setNameslabel] = useState("All");


  const [registers, setRegisters] = useState("all");
  const [registerslabel, setRegisterslabel] = useState("All");

  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    if (pages && limit) {
      listdata("data");
    }
  }, [all, names, registers]);

  useEffect(() => {
    if (pages && limit) {
      listdata("pages");
    }
  }, [pages, limit,registers]);

  const listdata = async (data) => {
    if (data == "pages") {
      let senddata = {
        from: "status",
        type: all,
        name: names,
        registeredAt: registers,
        page: pages,
        limit: limit,
      };
     

      var data = await axiosFile.listdata(senddata);
     

      setListData(data?.data?.data??[]);
    } else {
     
        
      let senddata = {
        from: "status",
        type: all,
        name: names,
        registeredAt: registers,
        page: pages,
        limit: limit,
      };
     
      var res = await axiosFile.listdata(senddata);
     
      if (res?.success == "success") {
        setListData(res?.data?.data??[]);
      }
    }
  };

  const column = [
    {
      key: "S.NO",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    { key: "userid", label: "Rider ID", html: "" },

    { key: "rider", label: "Rider Name", html: "" },
    { key: "gender", label: "Gender", html: "" },
    { key: "email", label: "Mail Id", html: "" },
    { key: "mobile", label: "Mobile Number", html: "" },
    { key: "state", label: "State",   html: (cell, row, rowind, colind) => (
      <div>
          {allstate?.states?.find(it=>it.code==cell)?.name}
      </div>
    ),  },

    { key: "city", label: "City", html: "" },
    { key: "registeredAt", label: "Register",   html: (cell, row, rowind, colind) => (
      <div>
          {datetimeFormat(row.registeredAt)}
      </div>
    ), },

    {
      key: "_id",
      label: "Rider history",
      html: (cell, row, rowind, colind) => (
        <div>
          
          <NavLink className="ride_history_btn mt-3" to={{pathname:'/users-ride-history',state:{"userid":row?._id}}}>
            Rider history
            {row.actionTwo}
          </NavLink>
        </div>
      ),
    },
    // {
    //   key: "_id",
    //   label: "Actions",
    //   html: (cell, row, rowind, colind) => (
    //     <div>
    //       {" "}
    //       <NavLink
    //         className="active_btn"
    //         to={{ pathname: `/rider-status`, state: { _id: row._id } }}
    //       >
       
    //         {row.actions?row.actions:"Pending"}
    //         {/* {row.actionsOne} */}
    //       </NavLink>
    //     </div>
    //   ),
    // },
  ];

  const Pagination = (limit, page) => {
    setpages(page);
    setLimit(limit);
  };
  const resetbutton =  () => {
    setRegisters('all')
    setAll('all')
    setAlllabel('All')
    setNames('ascending')
    setNameslabel('All')
    setRegisters('all')
    setRegisterslabel('All')
    setpages(1)
    setLimit(5)
  }

  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">Riders</h4>
          </div>
         
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row ">
                <div className="col-12 col-md-9 d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                  <p className="table_title">Filter by</p>
                  {/* <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select
                      options={options}
                      placeholder="Status"
                      value={{label:alllabel}}
                      onChange={(e) => (setAll(e.value),setAlllabel(e.label))}
                    />
                  </div> */}
                  <div className="select_div w-100 ml-1 ml-md-3  mt-3 mt-sm-0">
                    <div>Name Sorting</div>
                    <Select
                      options={options1}
                      placeholder="Name"
                      value={{label:nameslabel}}
                      onChange={(e) =>( setNames(e.value),setNameslabel(e.label))}
                    />
                  </div>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                  <div>Date Sorting</div>

                    <Select
                      options={options2}
                      placeholder="Register"
                      value={{label:registerslabel}}
                      onChange={(e) => (setRegisters(e.value),setRegisterslabel(e.label))}
                    />
                  </div>
                </div>
     
                {/* <div className="col-12 col-md-3 mt-4 mt-md-0 ">
                  <NavLink
                    className="d-flex align-items-center justify-content-center rst_btn mx-auto ml-sm-auto mx-sm-0  surcharge_btn"
                    style={{ whiteSpace: "nowrap" }}
                    to=""
                  >
                    Add New Riders
                    <span>
                      <AiOutlinePlus />
                    </span>
                  </NavLink>
                </div> */}
                 <div className="ml-sm-auto mt-3  ipt_wid_100">

<button class="convert_btn reset_btn ml-sm-3 mt-3 mt-sm-0" onClick={() => resetbutton()}>
          Reset
        </button>
        </div>
               
              </div>
      

              <div className="row mt-md-3">
                <div className="col-12 grid-margin">
                  <div className="card ride_card bor_none">
                    <div className="card-body table_card_body">
                      <div className="table_rider_width_set">
                        <Table
                          column={column}
                          data={listData}
                          Pagination={Pagination}
                          excel={true}
                          csv={true}
                          print={true}
                          search={true}
                          fileName={"Riders"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Riders;
