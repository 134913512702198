import React from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { NavLink } from "react-router-dom";
// import carAvailableImg from "../../assets/images/youcab/carAvailableMap.PNG";

const DriverBankDetails = () => {
  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Driver Bank Account Details
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card form-card">
            <div className="card-body pt-5">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Bank Name
                  </label>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="HDFC"
                    className="new_input"
                  />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputEmail1" className="label-color">
                    Account Holder Name
                  </label>

                  <Form.Control
                    type="email"
                    className="form-control new_input"
                    id="exampleInputEmail1"
                    placeholder="Demo Account"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputPassword1"
                    className="label-color"
                  >
                    Account Number
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control new_input"
                    id="exampleInputPassword1"
                    placeholder="14656565526565"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputConfirmPassword1"
                    className="label-color"
                  >
                    Re Enter Account Number
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control new_input"
                    id="exampleInputConfirmPassword1"
                    placeholder="14656565526565"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Enter IFSC Code
                  </label>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="HDFC58465146"
                    className="new_input"
                  />
                </Form.Group>

                <div className="mt-5">
                  <NavLink
                    to="/dashboard"
                    type="submit"
                    className=" convert_btn reset_btn mr-4"
                  >
                    Update
                  </NavLink>
                  <NavLink to="/dashboard" className=" convert_btn ">
                    Cancel
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverBankDetails;
