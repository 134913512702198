import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { NavLink } from "react-router-dom";

const options = [
  { value: "Online", label: "Online" },
  { value: "Offline", label: "Offline" },
];
const DriverMode = () => {
  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Drivers/Status
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9 col-md-7 col-xl-6 grid-margin stretch-card">
          <div className="card form-card">
            <div className="card-body pt-5">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Driver Mode
                  </label>
                  <Select
                    options={options}
                    placeholder="Online"
                    className="react_input_select"
                  />
                </Form.Group>

                <div className="mt-5">
                  <NavLink to="/dashboard" className="mr-4 convert_btn ">
                    Cancel
                  </NavLink>
                  <NavLink
                    to="/dashboard"
                    type="submit"
                    className=" convert_btn reset_btn"
                  >
                    Save
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverMode;
