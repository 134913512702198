import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/youcab/sidebar/youcab logo.png";
import notificationIcon from "../../assets/images/youcab/notification.svg";
import settingIcon from "../../assets/images/youcab/setting.svg";
import config from "../../../src/app/actions/config";

import { FiSearch } from "react-icons/fi";
const Navbar = () => {
  const { admindetails } = useSelector((state) => state.Youcabdata);
  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
  const toggleRightSidebar = () => {
    document.querySelector(".right-sidebar").classList.toggle("open");
  };
  // render() {
  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center remove_black">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        {/* <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button> */}
        <ul className="navbar-nav w-100">
          <li className="nav-item w-100">
            {/* <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
              <div className="search_input d-flex align-items-center">
                <FiSearch className="search_icon" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                />
              </div>
            </form> */}
          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right d-flex align-items-center">
          {/* <div>
            <NavLink to="/notification-history">
              <img
                src={notificationIcon}
                alt=""
                className="navbar_new_icons mr-3"
              />
            </NavLink>
          </div> */}
          <div>
            <NavLink className="" to="/settings">
              <img src={settingIcon} alt="" className="navbar_new_icons" />
            </NavLink>
          </div>
          <Dropdown alignRight as="li" className="nav-item">
            <Dropdown.Toggle
              as="a"
              className="nav-link cursor-pointer no-caret"
            >
              <div className="navbar-profile">
                <NavLink to="/profile">
                  <img
                    className="img-xs rounded-circle"
                    src={
                      admindetails.profile
                        ? `${config.ImG}/adminimages/${admindetails._id}/${admindetails.profile}`
                        : require("../../assets/images/noimg.png")
                    }
                    alt="profile"
                  />
                  {/* <p className="mb-0 d-none d-sm-block navbar-profile-name">
                    <Trans>Henry Klein</Trans>
                  </p> */}
                </NavLink>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={() => toggleOffcanvas()}
        >
          <span className="mdi mdi-format-line-spacing"></span>
        </button>
      </div>
    </nav>
  );
  // }
};

export default Navbar;
