import React, { useState } from "react";
import Select from "react-select";

import { SlCalender } from "react-icons/sl";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import DatePicker from "react-datepicker";
import dailyEarnings from "../../assets/svg/dailyEarnings.svg";
import totalRides from "../../assets/svg/totalrides.svg";
import dsCancel from "../../assets/svg/ds_cancel.svg";
import completeRides from "../../assets/svg/completerides.svg";
import totalRev from "../../assets/svg/totalrevenue.svg";
import { NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

const revenueDetailsData = [
  {
    id: 1,
    driverName: "Riders",
    bankName: "HDFC",
    accountNumber: "152151515105151",
    bankIFSCcode: "HDFC465688",
    actionOne: "Account Update",
    actionTwo: "Due Details",
  },
  {
    id: 2,
    driverName: "Riders",
    bankName: "HDFC",
    accountNumber: "152151515105151",
    bankIFSCcode: "HDFC465688",
    actionOne: "Account Update",
    actionTwo: "Due Details",
  },
  {
    id: 3,
    driverName: "Riders",
    bankName: "HDFC",
    accountNumber: "152151515105151",
    bankIFSCcode: "HDFC465688",
    actionOne: "Account Update",
    actionTwo: "Due Details",
  },
  {
    id: 4,
    driverName: "Riders",
    bankName: "HDFC",
    accountNumber: "152151515105151",
    bankIFSCcode: "HDFC465688",
    actionOne: "Account Update",
    actionTwo: "Due Details",
  },
  {
    id: 5,
    driverName: "Riders",
    bankName: "HDFC",
    accountNumber: "152151515105151",
    bankIFSCcode: "HDFC465688",
    actionOne: "Account Update",
    actionTwo: "Due Details",
  },
  {
    id: 5,
    driverName: "Riders",
    bankName: "HDFC",
    accountNumber: "152151515105151",
    bankIFSCcode: "HDFC465688",
    actionOne: "Account Update",
    actionTwo: "Due Details",
  },
];

const RevenueDetails = () => {
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">Details</h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className=""> Driver Name </th>

                      <th className=""> Bank Name</th>
                      <th className=""> Account Number</th>
                      <th className=""> Bank IFSC Code</th>

                      <th className="">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenueDetailsData.map((val) => {
                      return (
                        <tr key={val.rideId}>
                          <td className="">{val.driverName}</td>

                          <td className="">{val.bankName}</td>
                          <td className="">{val.accountNumber}</td>
                          <td className="">{val.bankIFSCcode}</td>

                          <td className="d-flex flex-column align-items-center">
                            <NavLink
                              className="active_btn skyblue_gradient account_btn"
                              to="/driver-bank-details"
                            >
                              {val.actionOne}
                            </NavLink>
                            <NavLink
                              className="active_btn mt-3 blue_gradient"
                              to="/driver-due-details"
                            >
                              {val.actionTwo}
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          
          <div className="card card_bg">
            <div className="card-body">
              <div className="row mt-4">
             
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RevenueDetails;
