import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import { DateTimeForm, LoadToast, datetimeFormat, isEmpty, updateToast, validation } from "../../lib/common";
import useAxiosFile from "../actions/useaxios";
import config from "../actions/config";
import { toast } from "react-toastify";
import  {CKEditor} from "@ckeditor/ckeditor5-react"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';




const Editcms = () => {
  let data = useLocation();

  const propsdata = data.state


  const axiosFile = useAxiosFile()
  const history = useHistory();
  const [errors, setError] = useState({})

  var initialdata={
    _id:propsdata?._id,
    action:"update",
    question:propsdata?.question,
    answer:propsdata?.answer
  }
  const [cmsdata,setcmsdata]=useState(initialdata)



  const Submit = async () => {
    const id = LoadToast();
    let err = await validation(Object.keys(initialdata), cmsdata);

    if (Object.keys(err).length > 0) {
      updateToast(id, "fix");
      setError(err);
      return;
    }


    var resp = await axiosFile.CMSlist(cmsdata)


    updateToast(
        id,
        !isEmpty(resp) ? resp?.msg: "Try again",
        resp.success
      );
      if(resp?.success == 'success'){
        setError({})
        history.push('/cms')
      }


  }




  const changefn = (e) => {
    var { id, value } = e.target

    setcmsdata({ ...cmsdata, ...{ [e.target.id]: e.target.value } })

  };

  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
             Edit CMS
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/cms"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card form-card">
            <div className="pt-5 ">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
Question                  </label>
                  <Form.Control
                    type="text"
                    id="question"
                    disabled={true}
                    placeholder="Coupon Code"
                    className="new_input" value={cmsdata?.question} onChange={(e) => changefn(e)}
                  />
                  <span id="Coupon Code-error" className="error-msg">{errors && errors.question}</span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
Answer                  </label>
<CKEditor

                    editor={ ClassicEditor }
                    data={cmsdata?.answer}


                    onChange={ ( event, editor ) => {
                        const data = editor.getData();

                        setcmsdata({ ...cmsdata, ...{ ["answer"]: data } })

                    } }


                />

                </Form.Group>
                <span id="Coupon Code-error" className="error-msg">{errors && errors.answer}</span>

                {/* <div>


                            </div> */}

                <div className="mt-5">
                  <button type="button" className=" convert_btn reset_btn" onClick={() => Submit()}>
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editcms;
