import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Form, Pagination } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { allstate } from "../../map/india";
import useAxiosFile from "../actions/useaxios";
import { EncryptData, LoadToast, isEmpty, updateToast } from "../../lib/common";
import ErrorMsg from "../basic-ui/ErrorMsg";
import { useSelector } from "react-redux";
import Delete from "../delete/delete";
import Autocomplete from "react-google-autocomplete";
import config from '../actions/config'
import { Trans } from "react-i18next";
import { toast } from "react-toastify";


const AllState = allstate.states.map((it) => {
  return { label: it.name, value: it.code, id: "state" };
});


const optionsThree = [
  { value: "Yes", label: "Restrict", id: "restrict" },
  { value: "No", label: "Not", id: "restrict" },
];

const DriversAdd = () => {
  /** lp */
  const { pathname, state } = useLocation();
  const { admindetails } = useSelector((state) => state.Youcabdata);

  const history = useHistory();
  const axiosFile = useAxiosFile();
  const [buttontxt, setbutton] = useState("Start");
  const [show, setshow] = useState(false);
  const [pages, Setpages] = useState(1);
  const [Limit, setLimit] = useState(0);
  const [Error, setError] = useState({});
  const [pathnames, setpathname] = useState(pathname);
  const [varients, setvarients] = useState([]);

  const [form, setform] = useState({
    _id:"",
   vehiclenumber:"",
   vechicletype:"",
   document: [ {
    type: "Aadhar/PAN/Passport (Local address proof)",
    status: "pending",
    key:"adhaar",
    files:[]
},
{
    type: "Driving license",
    status: "pending",
    key:"drivinglicense",
    files:[]
},
{
    type: "RC",
    status: "pending",
    key:"RC",
    files:[]
},
{
    type: "Vehicle Insurance",
    status: "pending",
    key:"insurance",
    files:[]
  }]
  });

  const [List, SetList] = useState([]);
  // useEffect(() => {
  //   if (admindetails?._id)
  //     // setform({ ...form, ...{ ["adminid"]: admindetails?._id } });
  // }, [admindetails?._id]);

  useEffect(() => {
    setpathname(pathname);
  }, [pathname, state, pages, Limit]);

  useEffect(() => {
    setform({...form,...state.Documents})
  }, [pathname, state]);


  useEffect(() => {
    if (pages&&pathnames == "/vehiclearients") {
      getList();
    }
  }, [pathnames, pages,Limit]);



  const getList = async (_id) => {
    // 
    var { success, data, msg } = await axiosFile.vehiclevarient({
      action: 'all',
      _id: _id,
      page: pages,
      limit: Limit
    })
  
    // setform({ ...form, ...data?.[0], ...{ cityfrom: !isEmpty(data?.[0]?.cityfrom) && data?.[0]?.cityfrom, cityto: !isEmpty(data?.[0]?.cityto) && data?.[0]?.cityto } })
    if (!isEmpty(data)) SetList(data)
    else SetList([])
  }



console.log("state.statestate.state",state.Documents)

  useEffect(()=>{
    getboundryList();
    
  },[])
  const getboundryList = async (_id) => {
    try{
    

    var data = await axiosFile.geolocation({
      action: "boundaryfromprice",
      _id: _id,
      page: 1,
      limit: 5
    })
   
    
    if(!isEmpty(data)) {
      
      SetList(data?.saved?.data);setvarients(data?.saved1?.data)}
      else SetList([])
  }
  catch(e){
    console.error("coming error",e)
  }
  }


  const ONCHANGE = (e) => {
    
      const { label, value, id,checked,files,payload } = e?.target ;
      if(files){
        let index = form?.document?.findIndex(it=>it.key==id)
        console.log("indexindexindex",index,id,form?.document?.[index])
        setform({
            ...form,
            ...{ 
                document:[
                    ...form?.document?.slice(0,index),
                    {
                    
                            title: form?.document?.[index]?.type?form?.document?.[index]?.type:form?.document?.[index]?.title,
                            key: form?.document?.[index]?.key,
                            type:  form?.document?.[index]?.type?form?.document?.[index]?.type:form?.document?.[index]?.title,
                            ['files']:[...files]?.slice(0,4)  },
                        // ...form?.document?.[index+1,form?.document],

                 
                    ...form?.document?.slice(index+1,form?.document?.length),
                ]
                ,},
          });
      }
      else{
        setform({
            ...form,
            ...{ [id]:id == "mcd" ? checked: value },
          });
      }
      setError({})
      setbutton("Start")
     
    };

  const Validation = (docu) => {
    // const validation = (docu) => {
        let error = {}
        console.log("docudocudocudocudocudocu",docu)
        if (docu.find(it => (it.key)?.toLowerCase().includes('adhaar'))?.files.length == 0) {
            error.adhaar = "Adhaar is required"
            //   setError({...Error,...{adhaar:""}})
        }
        if (docu.find(it => (it.key)?.toLowerCase().includes('drivinglicense'))?.files.length == 0) {
            // setError({...Error,...{adhaar:""}})
            error.drivinglicense = "Driving License is required"
        }
        if (docu.find(it => (it.key)?.toLowerCase().includes('rc'))?.files.length == 0) {
            error.RC = "RC is required"
        }
        if (docu.find(it => (it.key)?.toLowerCase().includes('insurance'))?.files.length == 0) {
            // setError({...Error,...{adhaar:""}})
            error.insurance = "Vehicle Insurance is required"
        }
        if (isEmpty(form?.vechicletype)) { error.vechicletype = 'Vehicle Type is reuquired' }

        if (isEmpty(form?.vehiclenumber)) { error.vehiclenumber = 'Vehicle Number is required' }

        console.log("{error",error)
        if (!isEmpty(error)) {
            setError(error)
            return true
        }
        else return false
    }
//   };

  const updateval = async () => {
    var instdata = {}; var instarr = [];

    setbutton("Processing");
    // const id = LoadToast();
    console.log("Validation(form?.document)Validation(form?.document)Validation(form?.document)",Validation(form?.document),form)
    if (Validation(form?.document)) {
        setbutton('TryAgain')
        return false
    }
    else{
      console.info("itemitemitemitemitemitemitemitemitem",form?.document)

var data=form?.document?.map((data) => {
         if (data?.files?.length != 0) {
          return   instdata[data.key] = data.files
            

        }  })

console.log("instarrinstarrinstarrinstarrinstarr",instarr,instdata)
            var datal = await axiosFile.Idproofupload(
                {
                    // ...form,
                    // form,
                    ...{vechicletype:form?.vechicletype,vehiclenumber:form?.vehiclenumber},
                    _id:form?._id,
                    arrimg:JSON.stringify(form?.document),
                    ...instdata
                })
                console.log("msgmsgmsg",datal)
            if (!datal?.error) {
                  toast.success(datal?.msg)
               
                setbutton('Start')
                history.push('/drivers')


            }
            else {
                toast.error(datal?.msg)
                setbutton('TryAgain')
            }
  
    }

  };


  const Edit = (_id) => {
    setbutton("Start");
    history.push(`/vehiclearients-edit`, { state: _id });
  };
  const Deletes = (_id) => {
  
    setform(_id);
    setshow(true)
    // updateval('delete',_id)
  };


  /** lp */

  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed ">
        <div className="row align-items-center py-3">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings  mb-0">
             Drivers Document Upload {" "}
            </h4>
          </div>
          <div className="col-3 col-sm-2 d-flex justify-content-end ">
            <div className="hoverable_a"
           
              onClick={() => {
             
                  history.push("/drivers")
                  setform({})
                  setbutton('Start')
                
              }}>
              { "Back"}
            </div>
          </div>
        </div>
      </div>
      <>
        {(<div className="row row_pad_top">
         <div className="col-md-9 grid-margin stretch-card">
           <div className="card form-card">
             <div className="">
               <form className="forms-sample">
                 <Form.Group>
                   <label
                     htmlFor="exampleInputUsername1"
                     className="label-color"
                   >
                     Vehicle Number
                   </label>
                   <Form.Control
                     type="text"
                     id="vehiclenumber"
                     placeholder="Enter Vehicle Number"
                     className="new_input"
                     value={form?.vehiclenumber}
                     onChange={(e) => ONCHANGE(e)}
                   />
                   <ErrorMsg msg={Error?.vehiclenumber} />


                 </Form.Group>

                 <Form.Group>
                  <label
                    htmlFor="exampleInputPassword1"
                    className="label-color"
                  >
                    Vehicle varients
                  </label>
                 
                      <Select
                        id="vechicletype"
                        options={varients}
                        placeholder="SUV"
                          className="react_input_select"
                          onChange={(e) =>
                            setform({
                              ...form,
                              ...{ "vechicletype": e?.value },
                            })
                          }
                          value={{label:varients?.find((it)=>it._id == form?.vechicletype)?.label,value:form?.vechicletype}}

                        />
                  <span id="vechicletype-error" className="error-msg">
                    {Error && Error?.vechicletype}
                  </span>
                </Form.Group>

                 
                 
                
{form?.document?.map(it=>{
    return(
      <Form.Group className="mb-0">
      
       
                 <Form.Group>
                    <label>{it.type}</label>
                    <div className="custom-file custom_file_browse">
                      <Form.Control
                        type="file"
                        className="new_input"
                        id={it.key}
                        onChange={ONCHANGE}
                        // lang="es"
                        multiple
                      />
                      <label
                        className="custom-file-label"
                        htmlFor={it.key}
                      >
                        Upload image
                      </label>
                    </div>
                  </Form.Group>
               <div className="flex_img_div_upload">
              {
                it?.files?.map(ig=>{
                    return(
<div className="uploaded_img">
                        <img src={typeof ig == "object" ? URL.createObjectURL(ig):ig?.image ? `${config.ImG}/vehicle/${ig?._id}/${ig?.image}`:"https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png"} />
                        </div>
                    )
                })
              } 
              </div>    
              <ErrorMsg msg={Error?.image} />


                 </Form.Group>
    )
})}


                 <div className="mt-4">
                   <button
                     type="button"
                     className=" convert_btn reset_btn mr-4"
                     disabled={!["Start", "Try Again"]?.includes(buttontxt)}
                     onClick={() =>
                       ["Start", "Try Again"]?.includes(buttontxt)
                         ? updateval()
                         : undefined
                     }
                   >
                     {buttontxt}
                   </button>
                 </div>
               </form>
             </div>
           </div>
         </div>
       </div>
       
        ) }
        {show && (
          <Delete
            show={show}
            _id={form?._id}
            setshow={setshow}
            text={'Vehicle Varient'}
            Deletes={()=>updateval('delete',form)}
          />
        )}{" "}
      </>
    </div>
  );
};

export default DriversAdd;
