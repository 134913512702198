import React from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const options = [
  { value: "ValueOne", label: "Value One" },
  { value: "ValueTwo", label: "Value Two" },
  { value: "ValueThree", label: "Value Three" },
];
const riderDetails = [
  {
    id: 1,
    gender: "Male",
    name: "James",
    mail: "James@gmail.com",
    mobileNo: "9876543210",
    city: "madurai",
    registerAt: "26-09-23",
    actionsOne: "Active",
    actionTwo: "Ride history",
  },
  {
    id: 2,
    name: "James",
    gender: "Male",
    mail: "James@gmail.com",
    mobileNo: "9876543210",
    city: "madurai",
    registerAt: "26-09-23",
    actionsOne: "Active",
    actionTwo: "Ride history",
  },
  {
    id: 3,
    name: "James",
    gender: "Male",

    mail: "James@gmail.com",
    mobileNo: "9876543210",
    city: "madurai",
    registerAt: "26-09-23",
    actionsOne: "Active",
    actionTwo: "Ride history",
  },
  {
    id: 4,
    name: "James",
    gender: "Male",

    mail: "James@gmail.com",
    mobileNo: "9876543210",
    city: "madurai",
    registerAt: "26-09-23",
    actionsOne: "Active",
    actionTwo: "Ride history",
  },
  {
    id: 5,
    name: "James",
    gender: "Male",

    mail: "James@gmail.com",
    mobileNo: "9876543210",
    city: "madurai",
    registerAt: "26-09-23",
    actionsOne: "Active",
    actionTwo: "Ride history",
  },
];

const TotalRides = () => {
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">Total Rides</h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-8 d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row pr-lg-0 ">
                  <p className="table_title">Filter by</p>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="Status" />
                  </div>
                  <div className="select_div w-100 ml-1 ml-md-3  mt-3 mt-sm-0">
                    <Select options={options} placeholder="Import" />
                  </div>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="Export" />
                  </div>
                </div>
                <div className="col-12 col-md-4  mt-4 mt-md-0 ">
                  <NavLink
                    className="d-flex align-items-center justify-content-center rst_btn mx-auto ml-sm-auto mx-sm-0  surcharge_btn"
                    style={{ whiteSpace: "nowrap" }}
                    to="/add-new-riders"
                  >
                    Add New Riders
                    <span className="ml-3 ml-md-1 ml-xl-1">
                      <AiOutlinePlus />
                    </span>
                  </NavLink>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 grid-margin">
                  <div className="card ride_card">
                    <div className="card-body table_card_body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className=""> Rider Name </th>
                              <th className="">Gender </th>
                              <th className=""> Mail</th>
                              <th className=""> Mobile Number </th>
                              <th className=""> City</th>
                              <th className=""> Register At</th>
                              <th className=""> Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {riderDetails.map((val) => {
                              return (
                                <tr key={val.rideId}>
                                  <td className="" style={{ color: "#055D9F" }}>
                                    {val.name}
                                  </td>
                                  <td className="">{val.gender}</td>
                                  <td className="">{val.mail}</td>
                                  <td className="">{val.mobileNo}</td>
                                  <td className="">{val.city}</td>
                                  <td className="">{val.registerAt}</td>
                                  <td className="d-flex flex-column align-items-center">
                                    <NavLink
                                      className="active_btn"
                                      to="/rider-status"
                                    >
                                      {val.actionsOne}
                                    </NavLink>
                                    <NavLink
                                      className="ride_history_btn mt-3"
                                      to="/rider-history"
                                    >
                                      {val.actionTwo}
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-end justify-content-md-end pagination_row mt-3 mx-0">
        <p className="mb-0 pagination_btn">Prev</p>
        <div className="pagination_overflow">
          <div className="dis_inline_parent">
            <div className="pagination_active convert_btn reset_btn dis_inline">
              1
            </div>
            <div className="pagination_count convert_btn dis_inline">2</div>
            <div className="pagination_count convert_btn dis_inline">3</div>
            <div className="pagination_count convert_btn dis_inline">4</div>
            <div className="pagination_count convert_btn dis_inline">5</div>
          </div>
        </div>

        <p className="mb-0 pagination_btn ">Next</p>
      </div>
    </div>
  );
};

export default TotalRides;
