import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { SlCalender } from "react-icons/sl";

import { NavLink, useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import {  dateFormat, dateFormat1, isEmpty } from "../../lib/common";
import useAxiosFile from "../actions/useaxios";
import config from "../actions/config";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker'
import moment from 'moment'

const AddDiscountAndOffers = () => {
  let data = useLocation();
  
  const search = data?.state?.data._id != null ? data?.state?.data._id : undefined
  

  const axiosFile = useAxiosFile()
  const history = useHistory();
  const [addnewcoupondata, setAddnewcoupondata] = useState({
    CouponTitle:"",
    CouponBody:"",
    CouponCode:"",
    CouponType:"",
    CouponPercentage:"",
    CouponPrice:"",
    CouponStartTime:"",
    CouponEndTime:"",
    CouponApplicableFor:"",
    CouponApplicableForIndividual:"",
    CouponImage:"",
  })
  const [errors, setError] = useState({})

  
  useEffect(() => {
    if (data?.state?.data) {
      setAddnewcoupondata(data?.state?.data)

    }
  }, [])
  const addcoupondata = async (e) => {
    var value = addvalidation()
    console.log("___________________________",addnewcoupondata,value)
    setError({})
    if (!isEmpty(value)) {
      setError(value)
    } else {
      
      let senddata;
      if (search != undefined) {

        senddata = ({ ...addnewcoupondata, ...{ _id: search, action: "update" } })

      }

      else {
        senddata = ({ ...addnewcoupondata, ...{ action: "add" } })

      }
      
      // debugger

      var data = await axiosFile.addcoupondata(senddata)
      console.log("datadatadata",data)
      if (data.success == "success") {
        history.push('/discounts_and_offers')
        toast.success(data.msg)  }
      else{
        toast.error(data.msg) }
    }
  }
  const addvalidation = () => {
       let errors = {}
       console.log("addnewcoupondata?.CouponImage?.name",addnewcoupondata?.CouponImage?.name,isEmpty(addnewcoupondata?.CouponImage)||isEmpty(addnewcoupondata?.CouponImage?.name))
       if(isEmpty(addnewcoupondata?.CouponTitle)) errors.CouponTitle  = " Coupon Title is required"
       if(isEmpty(addnewcoupondata?.CouponCode)) errors.CouponCode  = " Coupon Code is required"
      if((typeof addnewcoupondata?.CouponImage == "object" && isEmpty(addnewcoupondata?.CouponImage?.data))||(typeof addnewcoupondata?.CouponImage == "string" && isEmpty(addnewcoupondata?.CouponImage)))
      
       //  if(search && (isEmpty(addnewcoupondata?.CouponImage)||isEmpty(addnewcoupondata?.CouponImage?.name))) errors.CouponImage  = " Coupon Image is requireds"
      //  if(!search && isEmpty(addnewcoupondata?.CouponImage?.name)) errors.CouponImage  = " Coupon Image is required"
       if(!isEmpty(addnewcoupondata?.CouponImage)&&(typeof addnewcoupondata?.CouponImage == "object" && !addnewcoupondata?.CouponImage?.type?.includes("image"))) errors.CouponImage  = " Upaload Image format only"
       if(isEmpty(addnewcoupondata?.CouponType)) errors.CouponType  = " Coupon Type is required"
       if((addnewcoupondata?.CouponTitle =="price")&&isEmpty(addnewcoupondata?.CouponPrice)) errors.CouponPrice  = " Coupon Price is required"
       if((addnewcoupondata?.CouponTitle =="percentage")&&isEmpty(addnewcoupondata?.CouponPercentage)) errors.CouponPercentage  = " Coupon Percentage is required"
     
    return errors
  }



  const changefn = (e,val) => {
    var { id, value ,files} = e.target
    setAddnewcoupondata({ ...addnewcoupondata, ...{ [id]: val?val:files?files[0]:value } })

  };

  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              {data?.state?.status == "edit" ? "Edit Discount" : "Add Discount"}
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/discounts_and_offers"
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row pt-3">
      <div className="card-title new_headings_small mt-3  mb-4 col-12 col-sm-12 label-color" style={{textColor:"#fff"}}>Coupon Details</div>

        <div className="col-md-9 grid-margin stretch-card">
          <div className="card form-card">
            <div className="">
              <form className="forms-sample">
             
                
              <div className="">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Coupon Title*
                  </label>
                  <Form.Control
                    type="text"
                    id="CouponTitle"
                    placeholder="Enter Coupon Title"
                    className="new_input" value={addnewcoupondata?.CouponTitle} onChange={(e) => changefn(e)}
                  />
                  <span id="Coupon Code-error" className="error-msg">{errors && errors.CouponTitle}</span>
                </Form.Group>

                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Coupon Description
                  </label>
                  <Form.Control
                    type="text"
                    id="CouponBody"
                    placeholder="Description about coupon"
                    className="new_input" value={addnewcoupondata?.CouponBody} onChange={(e) => changefn(e)}
                  />
                  {/* <span id="Discount-error" className="error-msg">{errors && errors.CouponBody}</span> */}

                </Form.Group>

                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Coupon Image*
                  </label>



                    <div className="custom-file custom_file_browse">
                      <Form.Control
                      type="file"
                      id="CouponImage"
                      placeholder="Pick Image"
                      className="new_input" onChange={(e) => changefn(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="CouponImage"
                      >
                        Upload image
                      </label>
                    </div>

                    <span id="Discount-error" className="error-msg">{errors && errors.CouponImage}</span>
                  {
                    addnewcoupondata?.CouponImage ?
                    <div className="uploaded_img mt-3">
                    <img src={typeof addnewcoupondata?.CouponImage == "object" ? URL.createObjectURL(addnewcoupondata?.CouponImage):`${config.ImG}/coupon/${addnewcoupondata?._id}/${addnewcoupondata?.CouponImage}`} alt="coupon"/>
                    </div>
                    :<></>
                  }
                </Form.Group>


                <Form.Group>
                <label
                  htmlFor="exampleInputUsername1"
                  className="label-color"
                >
                  Coupon Code*
                </label>
                <Form.Control
                  type="text"
                  id="CouponCode"
                  placeholder="Coupon Code"
                  className="new_input" value={addnewcoupondata?.CouponCode} onChange={(e) => changefn(e)}
                />
                <span id="Coupon Code-error" className="error-msg">{errors && errors.CouponCode}</span>
              </Form.Group>
            </div>
              
         
            <div className="row">
      <div className="card-title new_headings_small mt-3  mb-4 col-12 col-sm-12 label-color" style={{textColor:"#fff"}}>Coupon  Offer Type(Amount / Percentage) *</div>
                
            <div className="mr-4  col-md-6 grid-margin stretch-card">
            
            <Form.Group className="mb-0">
            <label
                  htmlFor="exampleInputUsername1"
                  className="label-color"
                >
                  Discount Type*
                </label>
                <br/>
                <div className="d-flex align-items-center mt-3">
                <label
                  htmlFor="exampleInputUsername1"
                  className="label-color mb-0"
                >
                  Percentage
                </label>
                
                <Form.Control
                  type="radio"
                  id="CouponType"
                  placeholder="discount percentage"
                  className="new_input input_radio_custom ml-2"
                   checked={addnewcoupondata?.CouponType =="percentage" ? true:false} 
                   onChange={(e) => changefn(e,"percentage")}
                  name="same"
                />
                </div>
                <div className="d-flex align-items-center mt-3">
                  <label
                  htmlFor="exampleInputUsername1"
                  className="label-color mb-0"
                >
                Price
                </label>

                
                  <Form.Control
                  name="same"
                  type="radio"
                  id="CouponType"
                  // placeholder="discount percentage"
                  className="new_input input_radio_custom ml-2"  checked={addnewcoupondata?.CouponType =="price" ? true:false} onChange={(e) => changefn(e,"price")}
                />
              </div>
                <span id="Discount-error" className="error-msg">{errors && errors.CouponType}</span>

              </Form.Group>
 
 
              

            
          </div>
            
          </div>
          {
                addnewcoupondata?.CouponType == "percentage"?
                <Form.Group>
                <label
                  htmlFor="exampleInputUsername1"
                  className="label-color"
                >
                  Discount In Percentage*
                </label>
                <Form.Control
                  type="text"
                  id="CouponPercentage"
                  placeholder="discount percentage"
                  className="new_input" value={addnewcoupondata?.CouponPercentage} onChange={(e) => changefn(e)}
                />
                <span id="Discount-error" className="error-msg">{errors && errors.CouponPercentage}</span>

              </Form.Group>:
               addnewcoupondata?.CouponType == "price"?
               <Form.Group>
               <label
                 htmlFor="exampleInputUsername1"
                 className="label-color"
               >
                 Discount In Amount*
               </label>
               <Form.Control
                 type="text"
                 id="CouponPrice"
                 placeholder="discount percentage"
                 className="new_input" value={addnewcoupondata?.CouponPrice} onChange={(e) => changefn(e)}
               />
               <span id="Discount-error" className="error-msg">{errors && errors.CouponPrice}</span>

             </Form.Group>:<></>}
      <div className="card-title new_headings_small mt-3 px-0  mb-4 col-12 col-sm-12 label-color" style={{textColor:"#fff"}}>Durations</div>


          <div className="row">
                
            <div className="col-md-12 grid-margin stretch-card mb-0">
            <div className="card form-card">
              <Form.Group>
                <label
                  htmlFor="CouponStartTime"
                  className="label-color"
                >
                  Start Date
                </label>
              <div className="custom-date-picker form-control new_input date_minht justify-content-around">

                {/* <Form.Control
                  type="date"
                  id="CouponStartTime"
                  placeholder="Coupon Code"
                  className="new_input" value={(addnewcoupondata?.CouponStartTime)} onChange={(e) => changefn(e)}
                /> */}
                <div className="row w-100">
                      <div className="col-10">
                 <DatePicker
                          className=" "
                          selected={addnewcoupondata?.CouponStartTime}
                          onChange={(date) => {setAddnewcoupondata({...addnewcoupondata,...{["CouponStartTime"]:date}})}}
                          value={
                            addnewcoupondata?.CouponStartTime!=="" ?
                            moment(
                              addnewcoupondata?.CouponStartTime
                          ).format(
                            "MM-DD-YYYY"
                          )
                          :
                          "To"
                        }
                          minDate={
                            addnewcoupondata?.CouponStartTime?addnewcoupondata?.CouponStartTime:new Date()
                        }
                        />
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                        <SlCalender style={{ color: "#403C3E" }} />
                      </div>
                        </div>
                        </div>
                {/* <span id="Coupon Code-error" className="error-msg">{errors && errors.CouponStartTime}</span> */}
              </Form.Group>
              </div>
</div>
</div>
<div className="row">
<div className="col-md-12 grid-margin stretch-card">

<div className="card form-card">
              <Form.Group>
                <label
                  htmlFor="CouponEndTime"
                  className="label-color"
                >
                 Expiry Date
                </label>
              <div className="custom-date-picker form-control new_input date_minht justify-content-around">
              <div className="row w-100">
                      <div className="col-10">
                <DatePicker
                          className=" "
                          selected={addnewcoupondata?.CouponEndTime}
                          onChange={(date) => {setAddnewcoupondata({...addnewcoupondata,...{["CouponEndTime"]:date}})}}
                          value={
                            addnewcoupondata?.CouponEndTime!=="" ?
                            moment(
                              addnewcoupondata?.CouponEndTime
                          ).format(
                            "MM-DD-YYYY"
                          )
                          :
                          "To"
                        }
                          minDate={
                            addnewcoupondata?.CouponEndTime? addnewcoupondata?.CouponEndTime:new Date()
                        }
                        />
                        </div>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                        <SlCalender style={{ color: "#403C3E" }} />
                      </div>
                        </div>

                        </div>
                        
                {/* <Form.Control
                  type="date"
                  id="CouponEndTime"
                  placeholder="discount percentage"
                  className="new_input" value={addnewcoupondata?.CouponEndTime} onChange={(e) => changefn(e)}
                /> */}
                {/* <span id="Discount-error" className="error-msg">{errors && errors.CouponEndTime}</span> */}

              </Form.Group>
              </div>
              </div>
              </div>      
             

                <div className="mt-3">
                  <button type="button" className=" convert_btn reset_btn" onClick={() => addcoupondata()}>
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDiscountAndOffers;
