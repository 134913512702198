import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "../../lib/common";
import { toast } from "react-toastify";
import useAxiosFile from "../actions/useaxios";
import config from "../actions/config";
const optionsThree = [
  { value: "riders", label: "All Riders", id: "Whom" },
  { value: "drivers", label: "All Drivers", id: "Whom" },
  { value: "individualuser", label: "Individual User", id: "Whom" },
  { value: "individualdriver", label: "Individual Driver", id: "Whom" },
];

const PushNotifications = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [sendata, setsendata] = useState({});
  const axiosFile = useAxiosFile();

  
  useEffect(() => {
    if (!isEmpty(state)) {
      setsendata({
        ...sendata,
        ...{
          ["title"]: state?.CouponTitle,
          ["body"]: state?.CouponCode + " " + state?.CouponBody,
          ["_id"]: state?._id,
          ["_id"]: state?._id,
          ["Image"]: state?.CouponImage,
          ["message"]: `${state?.CouponTitle ?? ""} \n ${
            state?.CouponCode ?? ""
          } \n ${state?.CouponBody ?? ""}`,
        },
      });
    }
  }, []);

  const onChange = (e) => {
    seterrors({});
    const { value, id, files } = e?.target ?? e;
    console.log("onChangeonChangeonChange----", id, value);
    let message =
      id == "title"
        ? `${value ?? ""} \n  ${sendata?.body ?? ""}`
        : id == "body"
        ? `${sendata?.title ?? ""} \n ${value ?? ""}`
        : `${sendata?.title ?? ""} \n  ${sendata?.body ?? ""}`;
    setsendata({
      ...sendata,
      ...{
        [id]: files ? files[0] : value,
        ["message"]: message,
      },
    });
  };

  const [errors, seterrors] = useState({});

  const Vaidation = () => {
    let errors = {};
    if (isEmpty(sendata?.Whom)) errors.Whom = "Select Users";
    if (isEmpty(sendata?.title)) errors.title = "Select title";
    if (sendata?._id && isEmpty(sendata?.Image)) errors.Image = "Select Image";
    if (isEmpty(sendata?._id) && isEmpty(sendata?.Image?.name))
      errors.Image = "Select Image";
    console.log("typeof sendata?.Image", typeof sendata?.Image);
    // if((typeof sendata?.Image== "object" && isEmpty(sendata?.Image?.data))||(typeof sendata?.Image == "string" && isEmpty(sendata?.Image))) errors.Image = "Select Image"

    if (!isEmpty(errors)) {
      seterrors(errors);
      return true;
    } else return false;
  };
  const SendNotification = async () => {
    seterrors({});
    var validation = Vaidation();
    if (validation) {
      return false;
    }
    var data = await axiosFile.pushnotification(sendata);

    console.log("data", data);
    if (data?.success == "success") {
      if (state) history.push("discounts_and_offers");
      else history.push("notification-history");
      toast.success(data.msg);
    } else {
      toast.error(data.msg);
    }
  };

  console.log("onChangeonChangeonChange", sendata);

  return (
    <div>
      <div className="title_fixed">
        <div className="row align-items-center py-3">
          <div className="col-12 col-sm-8">
            <h4 className="card-title new_headings  mb-0">
              Push Notifications
            </h4>
          </div>
          <div className="col-12 col-sm-4 d-flex justify-content-end mt-3 mt-sm-0">
            <p className="text-left text-sm-right  text-dark notification_btn px-3 py-2 mb-0">
              <a href="/notification-history" style={{ color: "white" }}>
                Notification History
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="row row_pad_top">
        <div className="col-md-9 grid-margin stretch-card">
          <div className="card form-card">
            <div className="">
              {!isEmpty(state) ? (
                <form className="forms-sample">
                  <Form.Group>
                    <label
                      htmlFor="exampleInputConfirmPassword1"
                      className="label-color"
                    >
                      Select Users
                    </label>
                    <Select
                      options={optionsThree}
                      placeholder="Select Drivers"
                      className="react_input_select"
                      id="Whom"
                      onChange={onChange}
                    />
                    <span id="Discount-error" className="error-msg">
                      {errors && errors?.Whom}
                    </span>
                  </Form.Group>
                  {String(sendata?.Whom)?.includes("individual") ? (
                    <Form.Group>
                      <label
                        htmlFor="exampleInputUsername1"
                        className="label-color"
                      >
                        Enter Email Id (User and Driver must be availble in App)
                      </label>
                      <Form.Control
                        type="email"
                        className="form-control new_input"
                        id="individual"
                        onChange={onChange}
                      />
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  <Form.Group>
                    <label
                      htmlFor="exampleInputPassword1"
                      className="label-color"
                    >
                      Message
                    </label>
                    <div className="form-control new_input">
                     
                    </div>
                  </Form.Group>
                  {sendata?.Image ? (
                        <div className="uploaded_img mt-3">
                        <img
                          // height={250}
                          // width={250}
                          src={
                            typeof sendata?.Image == "object"
                              ? URL.createObjectURL(sendata?.Image)
                              : `${config.ImG}/coupon/${sendata?._id}/${sendata?.Image}`
                          }
                          alt="coupon"
                        />
                        </div>
                      ) : (
                        <></>
                      )}
                      <p className="text-dark">{sendata?.message}</p>
                </form>
              ) : (
                <form className="forms-sample">
                  <Form.Group>
                    <label
                      htmlFor="exampleInputUsername1"
                      className="label-color"
                    >
                      Title
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control new_input"
                      id="title"
                      onChange={onChange}
                    />
                    <span id="Discount-error" className="error-msg">
                      {errors && errors?.title}
                    </span>
                  </Form.Group>
                  <Form.Group>
                    <label
                      htmlFor="exampleInputUsername1"
                      className="label-color"
                    >
                      Description
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control new_input"
                      id="body"
                      onChange={onChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label
                      htmlFor="exampleInputUsername1"
                      className="label-color"
                    >
                      Coupon Image*
                    </label>

                    <div className="custom-file custom_file_browse">
                      <Form.Control
                        type="file"
                        id="Image"
                        placeholder="Pick Image"
                        className="new_input"
                        onChange={(e) => onChange(e)}
                      />
                      <label className="custom-file-label" htmlFor="Image">
                        Upload image
                      </label>
                    </div>

                    <span id="Discount-error" className="error-msg">
                      {errors && errors.Image}
                    </span>
                    {sendata?.Image ? (
                      <div className="uploaded_img mt-3">
                        <img
                          src={
                            typeof sendata?.Image == "object"
                              ? URL.createObjectURL(sendata?.Image)
                              : `${config.ImG}/coupon/${sendata?._id}/${sendata?.Image}`
                          }
                          alt="coupon"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <label
                      htmlFor="exampleInputConfirmPassword1"
                      className="label-color"
                    >
                      Select Users
                    </label>
                    <Select
                      options={optionsThree}
                      placeholder="Select Users"
                      className="react_input_select"
                      onChange={onChange}
                    />
                    <span id="Discount-error" className="error-msg">
                      {errors && errors?.Whom}
                    </span>
                  </Form.Group>
                  {String(sendata?.Whom)?.includes("individual") ? (
                    <Form.Group>
                      <label
                        htmlFor="exampleInputUsername1"
                        className="label-color"
                      >
                        Enter Email Id (User and Driver must be availble in App)
                      </label>
                      <Form.Control
                        type="email"
                        className="form-control new_input"
                        id="individual"
                        onChange={onChange}
                      />
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  <Form.Group>
                    <label
                      htmlFor="exampleInputPassword1"
                      className="label-color"
                    >
                      Message
                    </label>
                    {(sendata?.Image || sendata?.message) && (
                      <div className="flex_notification_div">
                        {sendata?.Image ? (
                          <div className="border-0 mt-0">
                          <img
                            // height={250}
                            // width={250}
                            src={
                              typeof sendata?.Image == "object"
                                ? URL.createObjectURL(sendata?.Image)
                                : `${config.ImG}/coupon/${sendata?._id}/${sendata?.Image}`
                            }
                            alt="coupon"
                          />
                          </div>
                        ) : (
                          <></>
                        )}
                        {sendata?.message}
                      </div>
                    )}
                  </Form.Group>
                </form>
              )}

              <div className="mt-5">
                <Button
                  onClick={() => SendNotification()}
                  className="convert_btn reset_btn mr-4"
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PushNotifications;
