import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";

// youcab

import dashboard from "../../assets/images/youcab/sidebar/svg/dashboard.svg";
import riders from "../../assets/images/youcab/sidebar/svg/riders.svg";
import drivers from "../../assets/images/youcab/sidebar/svg/drivers.svg";
import booking from "../../assets/images/youcab/sidebar/svg/booking.svg";
import tripRoute from "../../assets/images/youcab/sidebar/svg/trip_route.svg";
import ratecardAndSurge from "../../assets/images/youcab/sidebar/svg/ratecard_surge.svg";
import discountsAndOffers from "../../assets/images/youcab/sidebar/svg/discounts_offers.svg";
import trackCabs from "../../assets/images/youcab/sidebar/svg/track_cabs.svg";
import pushNotifications from "../../assets/images/youcab/sidebar/svg/push_notifications.svg";
import revenue from "../../assets/images/youcab/sidebar/svg/earnings.svg";
import geolocationBoundary from "../../assets/images/youcab/sidebar/svg/geo_locations.svg";
import serverMonitoring from "../../assets/images/youcab/sidebar/svg/server_monitoring.svg";
import logout from "../../assets/images/youcab/sidebar/svg/logout.svg";
import { toast } from "react-toastify";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    
    return (
      <nav
        className="sidebar sidebar-offcanvas"
        id="sidebar"
        style={{ zIndex: "10000" }}
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top border-top-right-radius">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img
              src={require("../../assets/images/youcab/sidebar/youcab logo.png")}
              alt="logo"
            />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
        </div>
        <ul className="nav">
          {/* <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img
                    className="img-xs rounded-circle "
                    src={require("../../assets/images/faces/face15.jpg")}
                    alt="profile"
                  />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal">
                    <Trans>Henry Klein</Trans>
                  </h5>
                  <span>
                    <Trans>Gold Member</Trans>
                  </span>
                </div>
              </div>
              <Dropdown alignRight>
                <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebar-dropdown preview-list">
                  <a
                    href="!#"
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>Account settings</Trans>
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a
                    href="!#"
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>Change Password</Trans>
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a
                    href="!#"
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-calendar-today text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>To-do list</Trans>
                      </p>
                    </div>
                  </a>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li> */}
          {/* <li className="nav-item nav-category">
            <span className="nav-link">
              <Trans>Navigation</Trans>
            </span>
          </li> */}
          <li
            className={
              this.isPathActive("/dashboard")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                <img src={dashboard} alt="dashboard" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/riders")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/riders">
              <span className="menu-icon">
                <img src={riders} alt="riders" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Riders</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/drivers")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/drivers">
              <span className="menu-icon">
                <img src={drivers} alt="drivers" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Drivers</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/car-availablity")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/car-availablity">
              <span className="menu-icon">
                <img src={booking} alt="bookings" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Bookings</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/trip-route")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/trip-route">
              <span className="menu-icon">
                <img src={tripRoute} alt="trip route" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Trip Route</Trans>
              </span>
            </Link>
          </li>
          {/* <li
            className={
              this.isPathActive("/ratecard-and-surge")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/ratecard-and-surge">
              <span className="menu-icon">
                <img
                  src={ratecardAndSurge}
                  alt="ratecard and surge"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Rate Card and Surge</Trans>
              </span>
            </Link>
          </li> */}
          <li
            className={
              this.isPathActive("/discounts_and_offers")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/discounts_and_offers">
              <span className="menu-icon">
                <img
                  src={discountsAndOffers}
                  alt="discount and offers"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Discounts and offers</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/track-cabs")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            {/* <Link className="nav-link" to="/track-cabs">
              <span className="menu-icon">
                <img src={trackCabs} alt="track cabs" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Track cabs</Trans>
              </span>
            </Link> */}
          </li>
          <li
            className={
              this.isPathActive("/push-notifications")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/push-notifications">
              <span className="menu-icon">
                <img
                  src={pushNotifications}
                  alt="Push Notifications"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Push Notifications</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/revenue")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/revenue">
              <span className="menu-icon">
                <img src={revenue} alt="revenue" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Revenue</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/geolocation-boundary")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/geolocation-boundary">
              <span className="menu-icon">
                <img
                  src={geolocationBoundary}
                  alt="geolocation-boundary"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Geolocation Boundary</Trans>
              </span>
            </Link>
          </li>          <li
            className={
              this.isPathActive("/mcd")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/mcd">
              <span className="menu-icon">
                <img
                  src={geolocationBoundary}
                  alt="geolocation-boundary"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>MCD Price</Trans>
              </span>
            </Link>
          </li>



          <li
            className={
              this.isPathActive("/vehiclearients")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/vehiclearients">
              <span className="menu-icon">
                <img
                  src={geolocationBoundary}
                  alt="geolocation-boundary"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Vehicle Varients</Trans>
              </span>
            </Link>
          </li>

          
          <li
            className={
              this.isPathActive("/server-monitoring")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            {/* <Link className="nav-link" to="/server-monitoring">
              <span className="menu-icon">
                <img
                  src={serverMonitoring}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Server Monitoring</Trans>
              </span>
            </Link> */}
          </li>
          <li
            className={
              this.isPathActive("/driverfetch")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/driverfetch">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/priceCalc.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Driver fetch</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/price-calculations")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/price-calculations">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/priceCalc.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Price Calculation</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/support")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/support">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/priceCalc.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>support</Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/cms")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/cms">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/priceCalc.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>CMS</Trans>
              </span>
            </Link>
          </li>
          
           <li
            className={
              this.isPathActive("/user-report")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/user-report">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/report.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>User Report</Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/driver-report")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/driver-report">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/report.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Clear Dues</Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/driver_all_report")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/driver_all_report">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/report.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Driver Report</Trans>
              </span>
            </Link>
          </li>

           <li
            className={
              this.isPathActive("/ride-report")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <Link className="nav-link" to="/ride-report">
              <span className="menu-icon">
                <img
                  src={require("../../assets/images/youcab/report.svg")}
                  alt="server monitoring"
                  className="img-fluid"
                />
              </span>
              <span className="menu-title">
                <Trans>Ride Report</Trans>
              </span>
            </Link>
          </li> 

          <li
            className={
              this.isPathActive("/logout")
                ? "nav-item menu-items link_gap active"
                : "nav-item link_gap menu-items"
            }
          >
            <div
              className="nav-link"
              onClick={() => {
                document.cookie = "token_a" + "=" + "";
                localStorage.removeItem("Auth");
                this.props.history.push("/");
                toast.success("Logout successfully")
              }}
            >
              <span className="menu-icon">
                <img src={logout} alt="logout" className="img-fluid" />
              </span>
              <span className="menu-title">
                <Trans>Logout</Trans>
              </span>
            </div>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
