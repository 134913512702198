import { io } from "socket.io-client";
// import eventEmitter from "./eventEmitter";
import config from '../actions/config'

export const socket = io(config.Socket,{
    path: '/socket.io',
    query:{_id:"admin"},
  autoConnect: true,
  // reconnection: true,
  // reconnectionAttempts: Infinity,
  // reconnectionDelay: 10,
  transports: [ 'websocket'],
  jsonp: false,
  // clientTimeout:500,
});

export const key = {
    availablecaruser: "availablecaruser",
    availablecar: "availablecar",
    alluser: "alluser",
    getalluser: "getalluser",
    getalldriver: "getalldriver",
    alldriver: "alldriver",
    alldriverlength: "alldriverlength",
    alluserlength: "alluserlength",
};
