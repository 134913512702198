import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose, AiFillEye } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import Table from "../table/table";
import config from "../../../src/app/actions/config";
// import emptyimage from '../../assests/images/noimg.png'
import emptyimage from "../../assets/images/noimg.png";
import { isEmpty } from "../../lib/common";
import { Button, Form, Modal } from "react-bootstrap";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const options = [
  { value: "all", label: "All" },
  { value: "ascending", label: "Price low to high" },
  { value: "descending", label: "Price high to low" },
];

// const varients = [
//   { value: "all", label: "All" },
//   { value: "ascending", label: "Price low to high" },
//   { value: "descending", label: "Price high to low" },
// ];

const PriceCalculations = () => {
  const axiosFile = useAxiosFile();
  const [vehiletypedata, setVehicletypedata] = useState([]);
  const [notdeleteid, setNotdeletedid] = useState(false);
  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);
  const [deleteId, setDeleteId] = useState();
  const [baseprice, setBaseprice] = useState("all");
  const [show, setshow] = useState(false);
  const [addvehicletype, setAddvehicletype] = useState("all");
  const [varients, setvarients] = useState([{label:"All",value:"all"}]);


  const history = useHistory()

  useEffect(()=>{
    getboundryList();
    
  },[])
  const getboundryList = async (_id) => {
    try{
    

    var data = await axiosFile.geolocation({
      action: "boundaryfromprice",
      _id: _id,
      page: 1,
      limit: 5
    })
   
    
    if(!isEmpty(data)) {
      
    setvarients([{label:"All",value:"all"},...data?.saved1?.data])}
    
  }
  catch(e){
    console.error("coming error",e)
  }
  }

  const handleClose = ()=> setshow(!show)

  const [Basepricelabel,setBasepricelabel
  ] = useState("All");


  useEffect(() => {

    if (pages && !isEmpty(limit)) {
      vehicletypelist();
    }
  }, [baseprice,limit,pages,addvehicletype]);

  // useEffect(() => {
  //
  //   if (pages && limit) {
  //     deletevehicletype()
  //   }
  // }, [pages, limit]);

  const vehicletypelist = async () => {

    var sendata = {
      type: "all",
      price: baseprice,
      page: pages,
      limit: limit,
      addvehicletype:addvehicletype
    };

    var res = await axiosFile.vehicletypelist(sendata);


    setVehicletypedata(res?.data?.data);
  };

  const deletevehicletype = async () => {
    try {
      // debugger
      let senddata = {
        _id: deleteId,
        type: "delete",
      };

      // debugger

      var data = await axiosFile.deletevehicletype(senddata);



      // setDiscountoffers(data)

      document.getElementById("closeexampleModalCenter").click();

      vehicletypelist();
      if (data?.success == "success") {
        setNotdeletedid(true);
      }
    } catch (e) {

    }
  };

  const Pagination = (limit, page) => {
    setpages(page)
    setLimit(limit)
  }
  const resetbutton =  () => {
    // setVehicletypedata([])
    setBaseprice("all")
    setpages(1)
    setLimit(5)
    setBasepricelabel('All')
  }
  const onSelectionchange=(e)=>{
    setBaseprice(e.value)
    setBasepricelabel(e.label)
  }
  const column = [
    { key: 'S.No', label: 'S.No', html: (cell, row, rowind, colind) => <div>{rowind + 1}</div> },
    // { key: 'vehicletype', label: 'Type', html: '' },
    { key: "vehiclemodel", label: "Varients", html: (cell, row, rowind, colind) => <div>{cell}</div> },
    { key: "area", label: "Zone ", html: (cell, row, rowind, colind) => <div>{cell}</div> },
    { key: "priceperkm", label: "Price/m", html: (cell, row, rowind, colind) => <div>{cell}</div>  },
    { key: "pricepermin", label: "Price/min", html: "" },
    { key: "areazone", label: "Area Zone", html: "" ,},
    { key: "areazoneid", label: "Area Zone Id", html: "" },
    { key: "vehiclevarients", label: "Vehicle Varient Id", html: ""},
    // { key: "vehiclemodel", label: "Vehicle Modal", html: ""},
    { key: "pricesurge", label: "Price Surge", html: "" },
    { key: "baseprice", label: "Base Price", html: "" },
    { key: "tolltax", label: "Toll Tax", html: "" },
    { key: "insurance", label: "Insurance", html: "" },
    { key: "gst", label: "GST in %", html: "" },
    { key: "commission", label: "Commission in %", html: "" },
    { key: "peaktimecharge", label: "Peakt Tme Charge", html: "" },
    // { key: "mcdtax", label: "MCD", html: "" },
    // { key: "mcdtax", label: "MCD", html: "" },
    { key: "usercancellationtimelimit", label: "User Cancellation Time Limit", html: "" },
    { key: "usercancellationcharges", label: "User Cancellation Charges", html: "" },
    { key: "waitingtimelimit", label: "Waiting Time Limit", html: "" },
    { key: "waitingchargespermin", label: "Waiting charges / minute", html: "" },
    { key: "transitwaitingfeepermin", label: "Transit Waiting Fee / minute", html: "" },
    { key: "others1", label: "Others1", html: "" },
    { key: "others2", label: "Others2", html: "" },
    { key: "others3", label: "Others3", html: "" },

    { key: "paymentmodesetting", label: "Payment Mode setting", html: "" },
   
    {
      key: "_id",
      label: "Edit",
      html: (cell, row, rowind, colind) => (
        <div>

          <NavLink
            className="active_btn"
            to={{
              pathname: `/add-vehicle-type`,
              state: { data: row, status: "edit" },
            }}
          >
            Edit
          </NavLink>
        </div>
      ),
    },
    // {
    //   key: "_id",
    //   label: "Delete",
    //   html: (cell, row, rowind, colind) => (
    //     <div>
    //       {" "}
    //       <button
    //         className="active_btn delte_btn mr-3 p-20"
    //         data-toggle="modal"
    //         data-target="#exampleModalCenter"
    //         onClick={() => {
    //           setDeleteId(row?._id);
    //         }}
    //       >
    //         Delete
    //       </button>
    //     </div>
    //   ),
    // },
  ];
  const importXcel = () => {

    // const ws = XLSX.utils.aoa_to_sheet(exceldata);
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const datas = new Blob([excelBuffer], { type: fileType });
    // filesaver.saveAs(datas, fileName + fileExtension);
  };
  const handleChange = (e) =>{
      const { files } = e?.target
      
      const { name , type} = files[0]
      if(type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
        Upload(files[0])
      }
      else{
        toast.error("Upload XLSX format only")
        
      }

  }
    const Upload = async(files) =>{
      var res = await axiosFile.UploadExcel({files:files});
      
      if(res.success == "success"){ toast.success("Upload Successfully")
      vehicletypelist()
    setshow(false)
    }
  
  }
  return (
    <>
      <div style={{ color: "black" }}>
        <div className="title_fixed">
          <div className="row">
            <div className="col-9 col-sm-10">
              <h4 className="card-title new_headings mt-3  mb-4 ">
                Price Calculations
              </h4>
              <h3 className=" mt-3  mb-4 ">
               Note : Strictly Dont edit excel header's , value of _id , areazone ,areazoneid,vehiclearients , vehiclevarients , area, vehiclemodel
              </h3>
            </div>
            <div className="col-3 col-sm-2 mt-3 ">
              <NavLink
                className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
                to="/dashboard"
                
              >
                Back
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="card card_bg">
              <div className="card-body">
                <div className="row mt-5">
                  <div className="col-12 col-md-9 col-lg-12 col-xl-9 d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                    <div className="select_div w-100  ml-md-3 mt-3 mt-sm-0  slt_min-width">
                    <div>Price Sort</div>
                      <Select
                        options={options}
                        value={{label:Basepricelabel}}
                        onChange={(e) => onSelectionchange(e)}
                        placeholder="All"
                        className="select_border_clr"
                      />
                      
                    </div>

                    <div className="select_div w-100  ml-md-3 mt-3 mt-sm-0  slt_min-width">
                    <div>Vehicle Varient</div>

                    <Select
                          id="vehiclevarients"
                          options={varients}
                          placeholder="All"
                          className="react_input_select"
                          onChange={(e) => setAddvehicletype(e?.value)}
                          value={{label:varients?.find((it)=>it.value == addvehicletype)?.label,value:addvehicletype}}
                    />
                        </div>
                    <div className="ml-sm-3 mt-3 mt-sm-0 ipt_wid_100">
                          <button class="convert_btn reset_btn ml-sm-3 mt-3 mt-sm-0" onClick={() => history.push({pathname:'/editvehicleprice',state:{addvehicletype,name:varients?.find((it)=>it.value == addvehicletype)?.label}})}>
                      Click to Edit {varients?.find((it)=>it.value == addvehicletype)?.label} varients
                      </button>
                     
                    </div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-12  mt-4 mt-md-0 mt-lg-3 col-xl-3 mt-xl-0">
                  
                  {/* <Button onClick={()=>setshow(true)}>Import</Button> */}
                  <hr/>
                    <Button
                    onClick={()=>setshow(true)}
                      className="d-flex align-items-center rst_btn mx-auto ml-sm-auto mx-sm-0  surcharge_btn justify-content-center"
                      // to="/add-vehicle-type"
                      style={{ height: "39px" }}
                    >
                      Import Excel to Update
                      <span>
                        <AiOutlinePlus />
                      </span>
                    </Button>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 grid-margin">
                    <div className="card ride_card  bor_none">
                      <div className="card-body table_card_body">
                        <div className="">
                          <Table
                            column={column}
                            data={vehiletypedata}
                            Pagination={Pagination}
                            excel={true}
                            csv={false}
                            print={false}
                            search={true}
                            fileName={"PriceCalculations"}
                          />
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal content */}
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Confirm Delete
              </h5>
              <button
                type="button"
                id="closeexampleModalCenter"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure delete this car type?</p>
              <div className="del_div mt-3 d-flex align-items-center justify-content-end">
                <button
                  className="del_btn mr-3 active_btn "
                  onClick={() => deletevehicletype()}
                >
                  Delete
                </button>
                <NavLink
                  to="/dashboard"
                  className="can_btn active_btn"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
<Form>
<Form.Control
                 type="file"
                 id="image"
                 placeholder="Car Type"
                 className="new_input new_input_add"
                 onChange={(event) => handleChange(event)}
               />

</Form>
        
        </Modal.Body>

      </Modal>
    </>
  );
};

export default PriceCalculations;
