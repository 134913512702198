import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import { SlCalender } from "react-icons/sl";

const options = [
  { value: "Sedan", label: "Sedan" },
  { value: "Innova", label: "Innova" },
  { value: "Honda", label: "Honda" },
];

const Booking = () => {
  const [value, onChange] = useState("10:00");
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">New Booking</h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 grid-margin stretch-card">
          <div className="card form-card">
            <div className=" pt-5">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Pickup Location
                  </label>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Enter Pickup Location"
                    className="new_input"
                  />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputEmail1" className="label-color">
                    Drop Location
                  </label>

                  <Form.Control
                    type="email"
                    className="form-control new_input"
                    id="exampleInputEmail1"
                    placeholder="Enter Drop Location"
                  />
                </Form.Group>
                <div className="row">
                  <div className="col-sm-6">
                    <Form.Group>
                      <label
                        htmlFor="exampleInputPassword1"
                        className="label-color"
                      >
                        Car Type
                      </label>
                      <Select
                        options={options}
                        placeholder="Select Car Type"
                        className="react_input_select"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group>
                      <label
                        htmlFor="exampleInputPassword1"
                        className="label-color"
                      >
                        Date And Time
                      </label>

                      <div className="custom-date-picker form-control new_input">
                        <div className="row">
                          <div className="col-9">
                            <DatePicker
                              className=" "
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center justify-content-end">
                            <SlCalender style={{ color: "#403C3E" }} />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <p
                  style={{ color: "#76a488", fontSize: "16px" }}
                  className="mb-4"
                >
                  Passenger Details
                </p>

                <Form.Group>
                  <label
                    htmlFor="exampleInputConfirmPassword1"
                    className="label-color"
                  >
                    Full Name
                  </label>
                  <Form.Control
                    type="password"
                    className="form-control new_input"
                    id="exampleInputConfirmPassword1"
                    placeholder=""
                  />
                </Form.Group>
                <div className="row">
                  <div className="col-sm-6">
                    <Form.Group>
                      <label
                        htmlFor="exampleInputPassword1"
                        className="label-color"
                      >
                        Mail
                      </label>
                      <Form.Control
                        type="password"
                        className="form-control new_input"
                        id="exampleInputPassword1"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group>
                      <label
                        htmlFor="exampleInputPassword1"
                        className="label-color"
                      >
                        Contact Number
                      </label>
                      <Form.Control
                        type="password"
                        className="form-control new_input"
                        id="exampleInputPassword1"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                </div>
                <Form.Group>
                  <label
                    htmlFor="exampleInputPassword1"
                    className="label-color"
                  >
                    Add Note
                  </label>
                  <textarea
                    className="form-control new_input"
                    id="exampleTextarea1"
                    rows="4"
                  ></textarea>
                </Form.Group>
                <div className="mt-5">
                  <NavLink
                    to="/dashboard"
                    type="submit"
                    className=" convert_btn reset_btn mr-4"
                  >
                    Assign Drivers
                  </NavLink>
                  <NavLink to="/dashboard" className=" convert_btn ">
                    Cancel
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
