import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {store} from './redux/store'
import { Provider } from 'react-redux';

ReactDOM.render(
  <BrowserRouter>
  <Provider store={store} >
   <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      
      theme="colored"
      
      toastStyle={{
        marginRight:'10px',
        width:'300px',
        border:'1px',
        borderRadius:'10px'
      }}
    />
    <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
