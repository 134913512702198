import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { isEmpty } from "../../lib/common";
import { toast } from "react-toastify";
import Select from "react-select";


const EditVehicleType = () => {
  let data = useLocation();

    
  const axiosFile = useAxiosFile();
  const history = useHistory();

  const [addvehicletype, setAddvehicletype] = useState({});
  const [List, SetList] = useState([]);
  const [varients, setvarients] = useState([]);
  

  const [check, setCheck] = useState({
    CaseOnly: false,
    CaseOnline: false,
    CustomerWish: false,
  });
  const [errors, setError] = useState({});


  useEffect(() => {
    if (data?.state?.addvehicletype) {
      setAddvehicletype({vehiclevarients : data?.state?.addvehicletype,paymentmodesetting:'CustomerWish'});

     
        var checkbox = { ...check };
        checkbox.CaseOnly = false;
        checkbox.CaseOnline = false;
        checkbox.CustomerWish = true;
        setCheck(checkbox);
      }
    
  }, []);

  const addvehicledata = async () => {
    var value = addvalidation();
   
    if (!isEmpty(value)) {
     
      setError(value);
    } else {
     
      let senddata;
     
        senddata = { ...addvehicletype, ...{ type: "updateall" } };
   


      var data = await axiosFile.addvehicledata(senddata);
      
      setAddvehicletype(data?.data?.data);
      if ((data.success == "success")) {
    toast.success("Updated successfully")
     history.push("/price-calculations");
      }
      else{
        toast.error(data?.msg?data?.msg:"Try Again")
      }
    }
  };
  const addvalidation = () => {


    let errors = {};
    


    if (!(addvehicletype?.priceperkm)) {
      errors.priceperkm = "Price per km field is required";
    }
    //
    if (!(addvehicletype?.pricepermin)) {
      errors.pricepermin = "Price per min field is required";
    }
    //
    if (!(addvehicletype?.pricesurge)) {
      errors.pricesurge = "Pricesurge field is required";
    }
    //
    if (!(addvehicletype?.insurance)) {
      errors.insurance = "Insurance field is required";
    }
    //
    if (!(addvehicletype?.tolltax)) {
      errors.tolltax = "Toll tax field is required";
    }
   
    if (!(addvehicletype?.gst)) {
      errors.gst = "Gst field is required";
    }

    if (!(addvehicletype?.commission)) {
      errors.commission = "Commission field is required";
    }
    //
    if (!(addvehicletype?.peaktimecharge)) {
      errors.peaktimecharge = "Peak time charge field is required";
    }

    //
    if (!(addvehicletype?.usercancellationtimelimit)) {
      errors.usercancellationtimelimit =
        "Usercancellation time limit field is required";
    }

    //
    if (!(addvehicletype?.usercancellationcharges)) {
      errors.usercancellationcharges =
        "User cancellation charges field is required";
    }
    if (!(addvehicletype?.transitwaitingfeepermin)) {
      errors.transitwaitingfeepermin =
        "On Transit  charges field is required";
    }


    //
    if (!(addvehicletype?.waitingtimelimit)) {
      errors.waitingtimelimit = "Waiting time limit field is required";
    }

    //
    if (!(addvehicletype?.waitingchargespermin)) {
      errors.waitingchargespermin = "Waiting charges per min field is required";
    }

   


    //
    if (!(addvehicletype?.paymentmodesetting)) {
      errors.paymentmodesetting = "Payment mode setting field is required";
    }
  

    return errors;
  };

 

  const changefn = (e) => {
    setError({})

    var { id, value } = e.target;
    setAddvehicletype({
      ...addvehicletype,
      ...{ [e.target.id]: e.target.value },
    });
  };

  const changefn1 = (e, data) => {

    setError({})
    setAddvehicletype({ ...addvehicletype, ...{ [e.target.id]: data } });
    if (data == "Cash Only") {
      var data = { ...check };
      data.CaseOnly = true;
      data.CaseOnline = false;
      data.CustomerWish = false;
      setCheck(data);
    } else if (data == "Cash Online") {
      var data = { ...check };
      data.CaseOnly = false;
      data.CaseOnline = true;
      data.CustomerWish = false;
      setCheck(data);
    } else {
      var data = { ...check };
      data.CaseOnly = false;
      data.CaseOnline = false;
      data.CustomerWish = true;
      setCheck(data);
    }
  };


  


  useEffect(()=>{
    getboundryList();
    
  },[])
  const getboundryList = async (_id) => {
    try{
    

    var data = await axiosFile.geolocation({
      action: "boundaryfromprice",
      _id: _id,
      page: 1,
      limit: 5
    })
   
    
    if(!isEmpty(data)) {
      
      SetList(data?.saved?.data);setvarients(data?.saved1?.data)}
      else SetList([])
  }
  catch(e){
    console.error("coming error",e)
  }
  }
console.log("data?.statedata?.state",data?.state)

  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              { "Update Price based on "+data?.state?.name+" varients "}
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 grid-margin stretch-card ">
          <div className="card form-card">
            <div className="pt-5">
              <form className="forms-sample">

             
              
                <Form.Group>
                  <label
                    htmlFor="exampleInputPassword1"
                    className="label-color"
                  >
                    Vehicle varients
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control new_input"
                    id="priceperkm"
                    placeholder="₹240"
                    disabled={true}
                    value={data?.state?.name}
                    onChange={(e) => changefn(e)}
                  />
                      </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputConfirmPassword1"
                    className="label-color"
                  >
                    Price per km
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control new_input"
                    id="priceperkm"
                    placeholder="₹240"
                    value={addvehicletype?.priceperkm}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="priceperkm-error" className="error-msg">
                    {errors && errors.priceperkm}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Price per Min
                  </label>
                  <Form.Control
                    type="text"
                    id="pricepermin"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.pricepermin}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="pricepermin-error" className="error-msg">
                    {errors && errors.pricepermin}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Price Surge
                  </label>
                  <Form.Control
                    type="text"
                    id="pricesurge"
                    placeholder="₹56.2"
                    className="new_input"
                    value={addvehicletype?.pricesurge}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="pricesurge-error" className="error-msg">
                    {errors && errors.pricesurge}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                   Base Price
                  </label>
                  <Form.Control
                    type="text"
                    id="baseprice"
                    placeholder="₹56.2"
                    className="new_input"
                    value={addvehicletype?.baseprice}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="pricesurge-error" className="error-msg">
                    {errors && errors.baseprice}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Insurance
                  </label>
                  <Form.Control
                    type="text"
                    id="insurance"
                    placeholder="₹62"
                    className="new_input"
                    value={addvehicletype?.insurance}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="insurance-error" className="error-msg">
                    {errors && errors.insurance}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Toll Tax
                  </label>
                  <Form.Control
                    type="text"
                    id="tolltax"
                    placeholder="₹21.25"
                    className="new_input"
                    value={addvehicletype?.tolltax}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="tolltax-error" className="error-msg">
                    {errors && errors.tolltax}
                  </span>
                </Form.Group>
           
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    GST %
                  </label>
                  <Form.Control
                    type="text"
                    id="gst"
                    placeholder="₹18.25"
                    className="new_input"
                    value={addvehicletype?.gst}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="gste-error" className="error-msg">
                    {errors && errors.gst}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Commission %
                  </label>
                  <Form.Control
                    type="text"
                    id="commission"
                    placeholder="₹12.25"
                    className="new_input"
                    value={addvehicletype?.commission}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="commission-error" className="error-msg">
                    {errors && errors.commission}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Peak time charge
                  </label>
                  <Form.Control
                    type="text"
                    id="peaktimecharge"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.peaktimecharge}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="peaktimecharge-error" className="error-msg">
                    {errors && errors.peaktimecharge}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    User cancellation time limit (in Minute)
                  </label>
                  <Form.Control
                    type="text"
                    id="usercancellationtimelimit"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.usercancellationtimelimit}
                    onChange={(e) => changefn(e)}
                  />
                  <span
                    id="usercancellationtimelimit-error"
                    className="error-msg"
                  >
                    {errors && errors.usercancellationtimelimit}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    User cancellation charges
                  </label>
                  <Form.Control
                    type="text"
                    id="usercancellationcharges"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.usercancellationcharges}
                    onChange={(e) => changefn(e)}
                  />
                  <span
                    id="usercancellationcharges-error"
                    className="error-msg"
                  >
                    {errors && errors.usercancellationcharges}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Waiting time limit (in Minutes)
                  </label>
                  <Form.Control
                    type="text"
                    id="waitingtimelimit"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.waitingtimelimit}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="waitingtimelimit-error" className="error-msg">
                    {errors && errors.waitingtimelimit}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Waiting charges per minute
                  </label>
                  <Form.Control
                    type="text"
                    id="waitingchargespermin"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.waitingchargespermin}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="waitingchargespermin-error" className="error-msg">
                    {errors && errors.waitingchargespermin}
                  </span>
                </Form.Group>
                {/* <Form.Group>
                    <label
                      htmlFor="exampleInputUsername1"
                      className="label-color"
                    >
                      In Route / Transit waiting fee per minute
                    </label>
                    <Form.Control
                      type="text"
                      id="exampleInputUsername1"
                      placeholder="₹10"
                      className="new_input"value={addvehicletype?.pricepermin} onChange={(e) => changefn(e)}
                    />
                  </Form.Group> */}
                {/* <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    In Route / Transit waiting fee per minute
                  </label>
                  <Form.Control
                    type="text"
                    id="transitwaitingfeepermin"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.transitwaitingfeepermin}
                    onChange={(e) => changefn(e)}
                  />
                  <span
                    id="transitwaitingfeepermin-error"
                    className="error-msg"
                  >
                    {errors && errors.transitwaitingfeepermin}
                  </span>
                </Form.Group> */}
    <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    On Transit Fee  (in Minute)
                  </label>
                  <Form.Control
                    type="text"
                    id="transitwaitingfeepermin"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.transitwaitingfeepermin}
                    onChange={(e) => changefn(e)}
                  />
                  <span
                    id="usercancellationtimelimit-error"
                    className="error-msg"
                  >
                    {errors && errors.transitwaitingfeepermin}
                  </span>
                </Form.Group>

                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Others 1 - OTP Charge 
                  </label>
                  <Form.Control
                    type="text"
                    id="others1"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.others1}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="others1-error" className="error-msg">
                    {errors && errors.others1}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Others 2 - Map charge
                  </label>
                  <Form.Control
                    type="text"
                    id="others2"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.others2}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="others2-error" className="error-msg">
                    {errors && errors.others2}
                  </span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Others 3 - Platform Fee
                  </label>
                  <Form.Control
                    type="text"
                    id="others3"
                    placeholder="₹10"
                    className="new_input"
                    value={addvehicletype?.others3}
                    onChange={(e) => changefn(e)}
                  />
                  <span id="others3-error" className="error-msg">
                    {errors && errors.others3}
                  </span>
                </Form.Group>
                {
                  addvehicletype?.paymentmodesetting &&
                    addvehicletype?.paymentmodesetting[0]
                }
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Payment Mode Setting
                  </label>
                  <div className="row mt-3" style={{ color: "#403C3E" }}>
                    <div className="col-10">
                      <div className="row">
                        <div className="col-12 col-sm-4">
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              id="paymentmodesetting"
                              name="CaseOnly"
                              className="custom_checkbox"
                              checked={check.CaseOnly}
                              value={addvehicletype?.paymentmodesetting}
                              onChange={(e) => changefn1(e, "Cash Only")}
                            />
                            <p
                              className="mb-0 ml-3"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Cash Only
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4 mt-3 mt-sm-0">
                          {" "}
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              id="paymentmodesetting"
                              name="CaseOnline"
                              className="custom_checkbox"
                              checked={check.CaseOnline}
                              value={addvehicletype?.paymentmodesetting}
                              onChange={(e) => changefn1(e, "Cash Online")}
                            />
                            <p
                              className="mb-0 ml-3"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Cash Online
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4  mt-3 mt-sm-0">
                          {" "}
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              id="paymentmodesetting"
                              name="CustomerWish"
                              className="custom_checkbox"
                              checked={check.CustomerWish}
                              value={addvehicletype?.paymentmodesetting}
                              onChange={(e) => changefn1(e, "Customer Wish")}
                            />
                            <p
                              className="mb-0 ml-3"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Customer Wish
                            </p>
                          </div>
                        </div>
                        <span id="others3-error" className="error-msg">
                    {errors && errors.paymentmodesetting}
                  </span>

                      </div>
                    </div>
                  </div>
                </Form.Group>

                <div className="mt-5">
                  <button
                    type="button"
                    className=" convert_btn reset_btn mr-4"
                    onClick={() => addvehicledata()}
                  >
                    Update
                  </button>
                  <NavLink to="/dashboard" className=" convert_btn ">
                    Cancel
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVehicleType;
