import React from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const options = [
  { value: "ValueOne", label: "Value One" },
  { value: "ValueTwo", label: "Value Two" },
  { value: "ValueThree", label: "Value Three" },
];

const riderDetails = [
  {
    id: 1,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 2,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 3,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 4,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 5,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 6,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 7,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 8,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 9,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 10,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
];

const CanceledRides = () => {
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Cancelled Rides
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-9 d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                  <p className="table_title">Filter by</p>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="status" />
                  </div>
                  <div className="select_div w-100  ml-1 ml-md-3  mt-3 mt-sm-0">
                    <Select options={options} placeholder="Import" />
                  </div>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="Export" />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 grid-margin">
                  <div className="card ride_card">
                    <div className="card-body table_card_body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className=""> Ride Id </th>
                              <th className=""> Ride Name</th>
                              <th className=""> Date </th>
                              <th className="table_textStart">
                                {" "}
                                Pickup Address
                              </th>
                              <th className="table_textStart"> Drop Address</th>
                              <th className=""> Cancel By</th>
                              <th className=""> Reason for cancellation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {riderDetails.map((val) => {
                              return (
                                <tr key={val.rideId}>
                                  <td className="">{val.rideId}</td>
                                  <td className="">{val.rideName}</td>
                                  <td className="">{val.date}</td>
                                  <td className="table_textStart">
                                    <p>{val.pickupAddress.door}</p>
                                    <p>{val.pickupAddress.street}</p>
                                    <p>{val.pickupAddress.Landmark}</p>
                                    <p>{val.pickupAddress.city}</p>
                                  </td>
                                  <td className="table_textStart">
                                    <p>{val.dropAddress.door}</p>
                                    <p>{val.dropAddress.street}</p>
                                    <p>{val.dropAddress.Landmark}</p>
                                    <p>{val.dropAddress.city}</p>
                                  </td>
                                  <td className="">{val.cancelBy}</td>
                                  <td className="">{val.cancellationReason}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-center justify-content-end justify-content-md-end pagination_row mt-3 mx-0">
        <p className="mb-0 pagination_btn">Prev</p>
        <div className="pagination_overflow">
          <div className="dis_inline_parent">
            <div className="pagination_active convert_btn reset_btn dis_inline">
              1
            </div>
            <div className="pagination_count convert_btn dis_inline">2</div>
            <div className="pagination_count convert_btn dis_inline">3</div>
            <div className="pagination_count convert_btn dis_inline">4</div>
            <div className="pagination_count convert_btn dis_inline">5</div>
          </div>
        </div>

        <p className="mb-0 pagination_btn ">Next</p>
      </div>
    </div>
  );
};

export default CanceledRides;
