import React from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const options = [
  { value: "ValueOne", label: "Value One" },
  { value: "ValueTwo", label: "Value Two" },
  { value: "ValueThree", label: "Value Three" },
];
const riderDetails = [
  {
    id: 1,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 2,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 3,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 4,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 5,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 6,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 7,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 8,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 9,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 10,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
  {
    id: 11,
    rideId: "125444144",
    rideType: "Ride",
    driverName: "Testing",
    riderName: "Testing 1",
    rideDate: "Sep 26,2023",
    totalRide: "$56,000",
    siteCommission: "$156",
    driverPayment: "$56,000",
    adminEarning: "$156",
    payment: "Cash",
  },
];

const TotalRevenue = () => {
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Total Revenue
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-9 d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                  <p className="table_title">Filter by</p>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="Status" />
                  </div>
                  <div className="select_div w-100  ml-1 ml-md-3  mt-3 mt-sm-0">
                    <Select options={options} placeholder="Import" />
                  </div>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="Export" />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 grid-margin">
                  <div className="card ride_card">
                    <div className="card-body table_card_body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className=""> Ride Id </th>
                              <th className=""> Ride Type</th>
                              <th className=""> Driver Name</th>
                              <th className=""> Rider Name</th>
                              <th className=""> Ride Date</th>
                              <th className=""> Total Ride</th>
                              <th className=""> Site Commission</th>
                              <th className=""> Driver Payment</th>
                              <th className=""> Admin Earning</th>
                              <th className=""> Payment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {riderDetails.map((val) => {
                              return (
                                <tr key={val.rideId}>
                                  <td className="">{val.rideId}</td>
                                  <td className="">{val.rideType}</td>
                                  <td className="">{val.driverName}</td>

                                  <td className="">{val.riderName}</td>
                                  <td className="">{val.rideDate}</td>
                                  <td className="">{val.totalRide}</td>
                                  <td className="">{val.siteCommission}</td>
                                  <td className="">{val.driverPayment}</td>
                                  <td className="">{val.adminEarning}</td>
                                  <td className="">{val.payment}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-end justify-content-md-end pagination_row mt-3 mx-0">
        <p className="mb-0 pagination_btn">Prev</p>
        <div className="pagination_overflow">
          <div className="dis_inline_parent">
            <div className="pagination_active convert_btn reset_btn dis_inline">
              1
            </div>
            <div className="pagination_count convert_btn dis_inline">2</div>
            <div className="pagination_count convert_btn dis_inline">3</div>
            <div className="pagination_count convert_btn dis_inline">4</div>
            <div className="pagination_count convert_btn dis_inline">5</div>
          </div>
        </div>

        <p className="mb-0 pagination_btn ">Next</p>
      </div>
    </div>
  );
};

export default TotalRevenue;
