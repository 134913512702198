import React, { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import { CSVLink, CSVDownload } from "react-csv";

import * as filesaver from "file-saver";
import XLSX from "sheetjs-style";
import { isEmpty } from "../../lib/common";
// import { Tooltip } from '@mui/material';

const fileType =
  "appliction/vnd.openxmlfoemates-officedocument.spreadsheetml.sheet;charser=UTF-8";
const fileExtension = ".xlsx";
const Table = ({
  column = [],
  data = [],
  fileName = "excel",
  Pagination = () => {},
  excel = false,
  csv = false,
  print = false,
  search = true,
}) => {
  const [page, setpage] = useState(1);
  const [colcount, setColcount] = useState(0);
  const [PageList, setPageList] = useState([1, 2, 3, 4, 5]);
  const LimitOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 500, value: 500 },
    { label: 1000, value: 1000 },
    { label: "All", value: Number.MAX_SAFE_INTEGER },
  ];
  const [Limit, setLimit] = useState(LimitOptions[0]?.value ?? 5);

  var columndata = column.map((it) => it.key);

  useEffect(() => {

    setColcount(columndata.length);
  }, []);
  const exceldata = useMemo(() => {

    var dr= data?.map((it) => {
      return column
        .map((ih) => {
          
          return ih.key && { [ih.key]:!isEmpty(it?.[ih.key])?
            typeof(it?.[ih.key])=='object'?Object.values(it?.[ih.key])[0]:it?.[ih.key]:"" };
          // return ih.key && { [ih.key]: it?.[ih.key] };
        })
        .filter((ik) => ik && ik)
        .reduce((io, acc) => {
          return { ...io, ...acc };
        });
    });

    
    return dr
  }, [data]);


  const exportXcel = () => {

    const ws = XLSX.utils.json_to_sheet(exceldata);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const datas = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(datas, fileName + fileExtension);
  };



{}
  const csvReport = {
    data: exceldata,
    filename: `${fileName}`,
  };

  function myFunction(search) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = search;

    filter = input.toUpperCase();
    table = document.getElementById("html_table");
    tr = table.getElementsByTagName("tr");
    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      var dat = column.map((it, ind) => {
        td = it ? tr[i].getElementsByTagName("td")[ind] : "";

        if (td) {
          txtValue = td.textContent || td.innerText;

          return txtValue?.toUpperCase()?.indexOf(filter) > -1;
        }
      });
      if (dat.includes(true)) {
        tr[i].style.display = "";
      } else tr[i].style.display = "none";

    }
  }
  const Rows = useMemo(() => {
    return Array.isArray(data)
      ? data.map((it, ind) => {
          return (
            <tr>
              {column.map((ir, colind) => {
                const { key, label, html ,show} = ir;
                let cell = it?.[key],
                  row = it,
                  rowind = ind + parseInt((page - 1) * Limit),
                  colindex = colind;
                var data = () =>
                  html ? html(cell, row, rowind, colindex) : <>{cell}</>;
                return <td>{data()}</td>;
              })}
            </tr>
          );
        })
      : [];
  }, [data]);

  useEffect(() => {
    Pagination(Limit?Limit:5, page?page:1);
  }, [page, Limit]);

  return (
    <div>
      <div className="row mt-4">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
              <div className="row mx-auto mt-3 mb-3">
                <div className="col-12 col-md-8 col-lg-8 col-xl-9  ">
                  <div className="d-flex flex-column flex-sm-row align-items-center">
                    <div className="d-flex align-items-center  ">
                      <p className="mb-0 mr-3">Show</p>
                      <div className=" w-100  mr-3">
                        <Select
                          options={LimitOptions}
                          className="show_select"
                          onChange={(e) => {
                            setLimit(e.value);
                          }}
                          value={LimitOptions.find((it) => it.value == Limit)}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-3 mt-sm-0">
                      {csv && (
                        <CSVLink className="convert_btn mr-3" {...csvReport}>
                          {" "}
                          CSV
                        </CSVLink>
                      )}
                      {excel && (
                        <button
                          className="convert_btn mr-3"
                          onClick={exportXcel}
                        >
                          Excel
                        </button>
                      )}
                    </div>
                    {print && (
                      <div
                        className="d-flex align-items-center mt-3 mt-sm-0"
                        onClick={() => window.print()}
                      >
                        <button className="convert_btn mr-3">Print</button>
                      </div>
                    )}
                  </div>
                </div>
                {search && (
                  <div className="col-12 col-md-4  col-lg-4 col-xl-3  mt-3 mt-md-0   mt-xl-0">
                    <div className="search_input d-flex align-items-center w-100 w-sm-75">
                      <FiSearch className="search_icon" />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                        onChange={(e) => {
                          myFunction(e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="table-responsive">
                <table className="table" id="html_table">
                  <thead>
                    <tr >
                      {column.map((it, ind) => {
                        return <th className="table"> {it.label}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {Rows?.length > 0 ? (
                      Rows
                    ) : (
                      <tr>
                        <td colSpan={colcount}>No data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-between  pagination_row mt-3 mx-0 pagination_scrl_none">
        <p
          className="mb-0 pagination_btn"
          onClick={() => {
            if (page == 1) {
              setpage(page);
            } else {
              // setPageList(PageList.filter(it => it != page - 1))
              setpage(page - 1);
            }
          }}
        >
          Prev
        </p>
        <div className="pagination_overflow">
          <div className="dis_inline_parent">
            {PageList.map((it) => {
              return (
                <div
                  onClick={() => setpage(it)}
                  className={
                    it == page
                      ? "pagination_active convert_btn reset_btn dis_inline"
                      : "pagination_active convert_btn dis_inline"
                  }
                >
                  {it}
                </div>
              );
            })}
          </div>
        </div>

        <p
          className="mb-0 pagination_btn "
          onClick={() => {
            if (data.length == 0) {
              setpage(page);
            } else {
              setPageList([...new Set([...PageList, page + 1])]);
              setpage(page + 1);
            }
          }}
        >
          Next
        </p>
      </div>
    </div>
  );
};

export default Table;
