
import React, { useEffect } from 'react'
import useAxiosFile from './actions/useaxios'
export default function Common(){
  const admin = useAxiosFile()
const Auth = localStorage.getItem('Auth')


    useEffect(()=>{
      if(Auth == "yes")  AlreadyLogin()
    },[Auth])
    const AlreadyLogin = async() => {
        
        await admin.AlreadyExits()
    }
    return(
        <></>
    )

    
}