import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import Table from "../table/table";
import { FiSettings } from "react-icons/fi";
import { Form } from "react-bootstrap";
import { isEmpty } from "../../lib/common";
import useAxiosFile from "../actions/useaxios";
import { toast } from "react-toastify";
import config from "../actions/config";
import { useHistory } from 'react-router-dom';

const options = [
  { value: "all", label: "All" },
  { value: "drivers", label: "Drivers" },
  { value: "riders", label: "Riders" },
];
const options1 = [
  { value: "all", label: "All" },
  { value: "Active", label: "Active" },
  { value: "InActive", label: "InActive" },
];
const surchargeDetails = [
  {
    id: 1,
    location: "Madurai Airport",
    pickupSurcharge: "1.50",
    dropoffSurcharge: "1.50",
    vehicleType: "Basics",
    status: "Active",
    action: <FiSettings />,
  },
  {
    id: 2,
    location: "Madurai Airport",
    pickupSurcharge: "1.50",
    dropoffSurcharge: "1.50",
    vehicleType: "Basics",
    status: "Active",
    action: <FiSettings />,
  },
];

const Faq = () => {
  const axiosFile = useAxiosFile();
  const history = useHistory()

  const [Faq, setFaq] = useState([]);
  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);


  useEffect(() => {
    if (pages && limit) {
      Faqlist()
    }
  }, [pages, limit])

  const Faqlist = async (data) => {
    let senddata = {
      type: "all",
      page: pages,
      limit: limit,
    };

    var res = await axiosFile.FAQlist(senddata);

    setFaq(res?.data?.data);


  };
  const deletefaq = async (data) => {
    var senddata = {
      _id: data,
      type: "delete",
    };
    var data = await axiosFile.FAQlist(senddata);
    if (data.sucess = 'success') {

      toast.success(data.msg)
      Faqlist()

    }
    else {
      toast.error(data.msg)

    }
  };






  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    { key: "question", label: "Heading", html: "" },
    {
      key: "answer", label: "Content", html: (cell, row, rowind, colind) => <div>
        <text dangerouslySetInnerHTML={{ __html: row.answer }}></text>

      </div>
    },




    {
      key: "_id",
      label: "Delete",
      html: (cell, row, rowind, colind) => (
        <div>
          <button
            className="active_btn delte_btn mr-3"
            onClick={() => {
              deletefaq(row?._id);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
    {
      key: "_id",
      label: "Edit",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button
            className="active_btn"

            onClick={() =>
              (history.push('/editsupport', row))}
          >
            Edit
          </button>{" "}
        </div>
      ),
    },
  ];

  const Pagination = (limit, page) => {
    setpages(page);
    setLimit(limit);
  };
  return (
    <>
      <div style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Support
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card card_bg">
              <div className="card-body">
                <div className="row mx-auto">
                  <div className="col-12 col-md-7  px-0 col-xl-9">

                  </div>

                  <div className="col-12 col-md-5  mt-4 px-0 mt-md-0 col-xl-3">
                    <div className="row mx-auto">

                      <div className="col-8 col-sm-8 col-md-7 col-xl-12 pr-0  ">
                        <button
                          className="d-flex align-items-center justify-content-center rst_btn surcharge_btn  w-100"
                          onClick={() => history.push('/addsupport', { status: "add" })}
                        >
                          Add Support
                          <span>
                            <AiOutlinePlus className="ml-1 ml-sm-3" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 grid-margin">
                    <div className="card ride_card bor_none">
                      <div className="card-body table_card_body">
                        <div className="">
                          <Table
                            column={column}
                            data={Faq}
                            Pagination={Pagination}
                            excel={true}
                            csv={true}
                            print={true}
                            search={true}
                            fileName={"FAQ"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Faq;
