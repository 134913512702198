import { Admin_section } from "./constants";

const initial_wallet = {
   admindetails : {},
   token:''

}

function Youcabreducer(state = initial_wallet,action){
   
    switch (action.type) {
        case Admin_section:
          return {
          ...state,
          ...action.Admin_section
          };
        default:
          return state;
}

}

export default Youcabreducer;