import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  AiOutlinePlus,
  AiOutlineClose,
  AiOutlineConsoleSql,
} from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import Table from "../table/table";
import { toast } from "react-toastify";
import { datetimeFormat } from "../../lib/common";


const options = [
  { value: "all", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "verified", label: "Verified" },
  { value: "noitems", label: "No Items" },
];
const options1 = [
  { value: "driverstatus all", label: "All" },
  { value: "online", label: "Online" },
  { value: "offline", label: "Offline" },
];
const options2 = [
  { value: "Name all", label: "All" },
  { value: "ascending", label: "A-Z" },
  { value: "descending", label: "Z-A" },
];


const Drivers = () => {
  const axiosFile = useAxiosFile();

  const [listdriverdata, setListdriverdata] = useState([]);

  const [all, setAll] = useState("all")
  const [Alllabel, setAlllabel] = useState("All")

  const [Driverstatus, setDriverstatus] = useState("all")
  const [Driverstatuslabel, setDriverstatuslabel] = useState("All")

  const [names, setNames] = useState("");
  const [nameslabel, setNameslabel] = useState("All");

  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);


  useEffect(() => {
    if (pages && limit) {
      listDriverData("data");
    }
  }, [all, Driverstatus, names])

  useEffect(() => {
    if (pages && limit) {
      listDriverData("pages");
    }
  }, [pages, limit]);

  const listDriverData = async (data) => {
    try {
      //
      if (data == "pages") {

          let senddata = {
            action: "driversstatus",
            status: all, 
            driverstatus: Driverstatus, name: names, page: pages, limit: limit
          }

          var data = await axiosFile.listDriverdata(senddata)
         

          setListdriverdata(data?.data?.data??[])
          
          //

      }
      else {

        let senddata = {
          action: "driversstatus",
          status: all, driverstatus: Driverstatus, name: names, page: pages, limit: limit
        }

        var res = await axiosFile.listDriverdata(senddata);
        setListdriverdata(res?.data?.data??[]);
      }
    } catch (e) {

    }

  }

  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    // {
    //   key: "driverid",""
    //   label: "Driver ID",
    //   html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    // },
    { key: 'driverid', label: 'Driver ID', html: ''},

    { key: 'name', label: 'Driver Name', html: ''},
    { key: 'email', label: 'Mail Id', html: '' },
    { key: 'mobile', label: 'Mobile Number', html: '' },
    { key: 'city', label: 'City', html: '' },
    { key: 'createdAt', label: 'Register At',  html: (cell, row, rowind, colind) => (

      <div>
          {datetimeFormat(row.createdAt)}
      </div>
    ), },
    { key: 'status', label: 'Document Status', html: (cell, row, rowind, colind) => <div> <text

  >

    {row.kyc?"Verified":"Pending"}
  </text></div>  },
    //   { key: 'status', label: 'Status', html: (cell, row, rowind, colind) => <div> <button
    //   className={
    //     row.status === "pending"
    //       ? "active_btn orange_btn"
    //       : row.status === "blocked"
    //         ? "active_btn red_btn"
    //         : "active_btn green_btn"
    //   }
    // >
    //   {row.status}
    // </button></div>  },
    { key: 'driverstatus', label: 'Driver Status', html: (cell, row, rowind, colind) => <div>
      <text
    className={
      row.driverstatus === "online"
        ? "active_btn green_btn"
        : row.driverstatus === "offline"
          ? "active_btn red_btn"
          : "active_btn green_btn"
    }
  >
    {row.driverstatus}
  </text>
   </div>},
    {
      key: '_id', label: 'Document', html: (cell, row, rowind, colind) => <div>
        {
          ((row?.documents?.length == 0) || (row?.documents?.[0]?.files.length == 0))?
          <NavLink
          className="active_btn skyblue_gradient "
  
          to={{
  
            pathname:"/driver-add",
  
          state: {Documents:row}
        }
     
    }
  
        >{((row?.documents?.length == 0) || (row?.documents?.[0]?.files.length == 0))?"No Itmes":"Document"}
         
        </NavLink>
       :
          <NavLink
          className="active_btn skyblue_gradient "
  
          to={{
  
            pathname:"/driver-documents",
  
          state: {Documents:row}
        }
     
    }
  
        >{((row?.documents?.length == 0) || (row?.documents?.[0]?.files.length == 0))?"No Itmes":"Document"}
          {row.actionsOne}
        </NavLink>
        
       
    },
    </div>},
   
   
    {
      key: "_id",
      label: "Ride history",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <NavLink
            className="active_btn mt-3 blue_gradient "
            to={{pathname:"/drivers-ride-history",state: {driverid:row._id}}}


          >
            Ride history
            {row.actionTwo}
          </NavLink>{" "}
        </div>
      ),
    },
    // {
    //   key: "_id",
    //   label: "Statement",
    //   html: (cell, row, rowind, colind) => (
    //     <div>
    //       {" "}
    //       <NavLink
    //         className="active_btn mt-3 violet_gradient"
    //         to="/driver-statement"
    //       >
    //         Statement
    //         {row.actionThree}
    //       </NavLink>
    //     </div>
    //   ),
    // },
  ];

  const Pagination = (limit, page) => {

    setpages(page);
    setLimit(limit);
  };
  const resetbutton =  () => {
    setAll('all')
    setAlllabel('All')
    setDriverstatus('all')
    setNames('')
    setNameslabel('All')
    setDriverstatuslabel('All')
    setDriverstatus('all')
    setpages(1)
    setLimit(5)
  }
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Total Drivers
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row">
                <div className="col-12  d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                  <p className="table_title">Filter by</p>
                  <div className="select_div w-100 ml-md-3 mt-3 mt-sm-0 slt_div">
                    <div>Document Status</div>
                    <Select
                      options={options}
                      placeholder="Status"
                      value={{value:Alllabel,label:Alllabel}}
                      onChange={(e) => (setAll(e.value),setAlllabel(e.label))}
                    />
                  </div>
                  <div className="select_div w-100 ml-md-3  mt-3 mt-sm-0 slt_div">
                  <div>Driver Status</div>
                    
                    <Select
                      options={options1}
                      placeholder="Driverstatus"
                      value={{label:Driverstatuslabel}}
                      onChange={(e) => (setDriverstatus(e.value),setDriverstatuslabel(e.label))}
                    />
                  </div>
                  <div className="select_div w-100 ml-md-3 mt-3 mt-sm-0 slt_div">
                  <div>Name Sorting</div>
                   
                    <Select
                      options={options2}
                      placeholder="Name"
                      value={{label:nameslabel}}
                      onChange={(e) => (setNames(e.value),setNameslabel(e.label))}
                    />
                  </div>
                  <div className="ml-sm-auto mt-3 ipt_wid_100">

<button class="convert_btn reset_btn ml-sm-3 mt-3 mt-sm-0" onClick={() => resetbutton()}>
          Reset
        </button>
        </div>
                </div>
                {/* <div className="col-12 col-md-3 mt-4 mt-md-0 "> */}
                {/* <NavLink
                    className="d-flex align-items-center rst_btn mx-auto ml-sm-auto mx-sm-0  surcharge_btn justify-content-center"
                    to="/add-new-driver"
                  >
                    Add New Driver
                    <span>
                      <AiOutlinePlus />
                    </span>
                  </NavLink> */}
                {/* </div> */}
              </div>

              <div className="row mt-md-3">
                <div className="col-12 grid-margin">
                  <div className="card ride_card bor_none">
                    <div className="card-body table_card_body">
                      <div className="">
                        <Table
                          column={column}
                          data={listdriverdata}
                          Pagination={Pagination}
                          excel={true}
                          csv={true}
                          print={true}
                          search={true}
                          fileName={"Drivers"}
                        />
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Drivers;
