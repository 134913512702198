import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAxiosFile from '../actions/useaxios';
import Success from './Success';
import Error404 from './Error404';

const NavNew=()=>{
    const {Id} = useParams()
    const axiosFile = useAxiosFile()
    // const history = useHistory()
    const [views,setviews] = useState('')
    useEffect(()=>{
        listDriverData(Id);
    },[Id])

    const listDriverData = async (data) => {
        try {
           
            var res = await axiosFile.Status({Id});
           
            if(res.data) setviews('success')
            else setviews('failure')
            
        } catch (e) {
      
        }
      
      }
    return (
      <div>
       {views == "success" ?
        <Success/>:
        views == "failure"?
        <Error404/>
        :<></>
           
    }
      </div>
    )
  
}

export default NavNew
