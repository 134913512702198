import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect, Navigate } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import RateCard from "./youcabComponents/RateCard";
import Riders from "./youcabComponents/Riders";
import Drivers from "./youcabComponents/Drivers";
import TripRoute from "./youcabComponents/TripRoute";
import DiscountsAndOffers from "./youcabComponents/DiscountsAndOffers";
import Revenue from "./youcabComponents/Revenue";
import CanceledRides from "./youcabComponents/CanceledRides";
import TotalRevenue from "./youcabComponents/TotalRevenue";
import VehicleType from "./youcabComponents/VehicleType";
import DriversOrRideHistory from "./youcabComponents/DriversOrRideHistory";
import UsersOrRideHistory from "./youcabComponents/UserOrRideHistory";

import AddNewRiders from "./youcabComponents/AddNewRiders";
import RiderStatus from "./youcabComponents/RiderStatus";
import DriverStatus from "./youcabComponents/DriverStatus";
import DriverMode from "./youcabComponents/DriverMode";
import DriverDocuments from "./youcabComponents/DriverDocuments";
import CarAvailability from "./youcabComponents/CarAvailability";
import Booking from "./youcabComponents/Booking";
import AddDiscountAndOffers from "./youcabComponents/AddDiscountAndOffers";
import PushNotifications from "./youcabComponents/PushNotifications";
import GeolocationBoundary from "./youcabComponents/GeolocationBoundary";
import TrackCabs from "./youcabComponents/TrackCabs";
import Profile from "./youcabComponents/Profile";
import ServerMonitoring from "./youcabComponents/ServerMonitoring";
import RiderHistory from "./youcabComponents/RiderHistory";
import DriverStatement from "./youcabComponents/DriverStatement";
import UserStatement from "./youcabComponents/userstatement";
import DriverInvoiceStatement from './youcabComponents/DriverinvoiceStatement'
import UserInvoiceStatement from './youcabComponents/UserinvoiceStatement'
import AddNewDriver from "./youcabComponents/AddNewDriver";
import RevenueDetails from "./youcabComponents/RevenueDetails";
import DriverBankDetails from "./youcabComponents/DriverBankDetails";
import AddVehicleType from "./youcabComponents/AddVehicleType";
import PriceCalculations from "./youcabComponents/PriceCalculations";
import Driverfetch from "./youcabComponents/Driverfetch"
import Faq from "./youcabComponents/Faq";
import Addfaq from "./youcabComponents/Addfaq";
import Editfaq from "./youcabComponents/Editfaq";
import Cms from "./youcabComponents/Cms";
import Editcms from "./youcabComponents/Editcms";



import DriverDueDetails from "./youcabComponents/DriverDueDetails";
import Settings from "./youcabComponents/Settings";
import NotificationHistory from "./youcabComponents/NotificationHistory";
import TotalRides from "./youcabComponents/TotalRides";
import Protected from "./Authroute";
import Common from "./Common";
import Report from "./youcabComponents/Report";
import TimeBasedReport from "./youcabComponents/timebasedreport";
import NavNew from "./error-pages/NavNew";
import Success from "./error-pages/Success";
import UserReport from "./youcabComponents/UserReport";
import DriverReport from "./youcabComponents/DriverReport";
import RideReport from "./youcabComponents/RideReport";
import Driverval from "./youcabComponents/Driverval";
import VehicleVarients from "./youcabComponents/vehiclevarients";
import Mcd from "./youcabComponents/mcd.js";
import DriversAdd from "./youcabComponents/DriversAdd.js";
import EditVehicleType from "./youcabComponents/editvehicletype.js";
import DriverALlReport from "./youcabComponents/DriverALlReport";
import DriverStatementTrip from './youcabComponents/driver_statement_trip'


const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Forgetpassword = lazy(() => import("./user-pages/Forgetpassword"));
const Verifyotp = lazy(() => import("./user-pages/Verifyotp"));
const Changepassword = lazy(() => import("./user-pages/Changepassword"));




class AppRoutes extends Component {
  constructor(props) {
    super();
    this.auth = "red";
  }

  render() {

    return (
      <>
        <Common />
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route
              exact
              path="/login"
              render={() => (
                <Protected isSignedIn={this.props.isAuth} isLogin={true}>
                  <Login />
                </Protected>
              )}
            />
            <Route
              exact
              path="/forgetpassword"
              render={() => (
                <Protected isSignedIn={this.props.isAuth} isLogin={true}>
                  <Forgetpassword />
                </Protected>
              )}
            />
            <Route
              exact
              path="/verifyotp"
              render={() => (
                <Protected isSignedIn={this.props.isAuth} isLogin={true}>
                  <Verifyotp />
                </Protected>
              )}
            />
            <Route
              exact
              path="/changepassword"
              render={() => (
                <Protected isSignedIn={this.props.isAuth} isLogin={true}>
                  <Changepassword />
                </Protected>
              )}
            />
            <Route
              exact
              path="/dashboard"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Dashboard />
                </Protected>
              )}
            />

            <Route
              exact
              path="/user-statement"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <UserStatement />
                </Protected>
              )}
            />


            <Route
              exact
              path="/ratecard-and-surge"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <RateCard />
                </Protected>
              )}
            />
            <Route
              exact
              path="/riders"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Riders />
                </Protected>
              )}
            />
            <Route
              exact
              path="/drivers"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Drivers />
                </Protected>
              )}
            />
             <Route
              exact
              path="/driver-add"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriversAdd />
                </Protected>
              )}
            />
            <Route
              exact
              path="/trip-route"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <TripRoute />
                </Protected>
              )}
            />
            <Route
              exact
              path="/discounts_and_offers"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DiscountsAndOffers />
                </Protected>
              )}
            />
            <Route
              exact
              path="/revenue"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Revenue />
                </Protected>
              )}
            />
            <Route
              exact
              path="/canceled-rides"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <CanceledRides />
                </Protected>
              )}
            />
            <Route
              exact
              path="/total-revenue"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <TotalRevenue />
                </Protected>
              )}
            />
            <Route
              exact
              path="/vehicle-type"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <VehicleType />
                </Protected>
              )}
            />
            <Route
              exact
              path="/drivers-ride-history"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriversOrRideHistory />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driverinvoice"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriversOrRideHistory />
                </Protected>
              )}
            />
            <Route
              exact
              path="/users-ride-history"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <UsersOrRideHistory />
                </Protected>
              )}
            />
            <Route
              exact
              path="/add-new-riders"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <AddNewRiders />
                </Protected>
              )}
            />

            <Route
              exact
              path="/rider-status"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <RiderStatus />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driver-mode"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverMode />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driver-documents"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverDocuments />
                </Protected>
              )}
            />
            <Route
              exact
              path="/car-availablity"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <CarAvailability />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driver-status"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverStatus />
                </Protected>
              )}
            />
              <Route
              exact
              path="/mcd"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Mcd/>
                </Protected>
              )}
            />
                      
              <Route
              exact
              path="/mcd-add"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Mcd/>
                </Protected>
              )}
            />
              <Route
              exact
              path="/mcd-edit"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Mcd/>
                </Protected>
              )}
/>
               <Route
              exact
              path="/driver-value"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Driverval />
                </Protected>
              )}
            />
            <Route
              exact
              path="/booking"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Booking />
                </Protected>
              )}
            />
            <Route
              exact
              path="/add_discount_offers"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <AddDiscountAndOffers />
                </Protected>
              )}
            />
            <Route
              exact
              path="/push-notifications"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <PushNotifications />
                </Protected>
              )}
            />
            <Route
              exact
              path="/geolocation-boundary"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <GeolocationBoundary />
                </Protected>
              )}
            />
               <Route
              exact
              path="/vehiclearients"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <VehicleVarients />
                </Protected>
              )}
            />
              <Route
              exact
              path="/vehiclearients-add"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <VehicleVarients />
                </Protected>
              )}
            />
             <Route
              exact
              path="/vehiclearients-edit"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <VehicleVarients />
                </Protected>
              )}
            />


              <Route
            exact
            path="/report-statement"
            render={() => (
              <Protected isSignedIn={this.props.isAuth}>
                <TimeBasedReport />
              </Protected>
            )}
          />
           <Route
            exact
            path="/statement"
            render={() => (
              <Protected isSignedIn={this.props.isAuth}>
                <UserStatement />
              </Protected>
            )}
          />
            <Route
              exact
              path="/geolocation-add"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <GeolocationBoundary />
                </Protected>
              )}
            />  <Route
            exact
            path="/status/:Id"
            render={() => (
              <Protected isSignedIn={this.props.isAuth}>
                <NavNew />
              </Protected>
            )}
          />
           <Route
            exact
            path="/success"
            render={() => (
              <Protected isSignedIn={this.props.isAuth}>
                <Success />
              </Protected>
            )}
          />
           <Route
            exact
            path="/failure"
            render={() => (
              <Protected isSignedIn={this.props.isAuth}>
                <Error404 />
              </Protected>
            )}
          />
            <Route
              exact
              path="/geolocation-edit"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <GeolocationBoundary />
                </Protected>
              )}
            />
            <Route
              exact
              path="/track-cabs"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <TrackCabs />
                </Protected>
              )}
            />
            <Route
              exact
              path="/profile"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Profile />
                </Protected>
              )}
            />
            <Route
              exact
              path="/server-monitoring"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <ServerMonitoring />
                </Protected>
              )}
            />
            <Route
              exact
              path="/rider-history"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <RiderHistory />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driver-statement"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverStatement />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driver-invoice"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverInvoiceStatement />
                </Protected>
              )}
            />
            <Route
              exact
              path="/rider-invoice"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <UserInvoiceStatement />
                </Protected>
              )}
            />
            <Route
              exact
              path="/add-new-driver"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <AddNewDriver />
                </Protected>
              )}
            />
            <Route
              exact
              path="/revenue-details"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <RevenueDetails />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driver-bank-details"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverBankDetails />
                </Protected>
              )}
            />
            <Route
              exact
              path="/add-vehicle-type"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <AddVehicleType />
                </Protected>
              )}
            />
            <Route
              exact
              path="/price-calculations"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <PriceCalculations />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driverfetch"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Driverfetch />
                </Protected>
              )}
            />
            <Route
              exact
              path="/driver-due-details"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverDueDetails />
                </Protected>
              )}
            />
            <Route
              exact
              path="/settings"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Settings />
                </Protected>
              )}
            />
            <Route
              exact
              path="/notification-history"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <NotificationHistory />
                </Protected>
              )}
            />
            <Route
              exact
              path="/total-rides"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <TotalRides />
                </Protected>
              )}
            />
            <Route
              exact
              path="/support"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Faq />
                </Protected>
              )}
            />
            <Route
              exact
              path="/addsupport"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Addfaq />
                </Protected>
              )}
            />
            <Route
              exact
              path="/editsupport"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Editfaq />
                </Protected>
              )}
            />
            <Route
              exact
              path="/cms"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Cms />
                </Protected>
              )}
            />

            <Route
              exact
              path="/editcms"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <Editcms />
                </Protected>
              )}
            />
              <Route
              exact
              path="/editvehicleprice"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <EditVehicleType />
                </Protected>
              )}
            />
   <Route
              exact
              path="/driver_all_report"
              render={() => (
                <Protected isSignedIn={this.props.isAuth}>
                  <DriverALlReport />
                </Protected>
              )}
            />
            <Route
            exact
            path="/driver_statement_trip"
            render={() => (
              <Protected isSignedIn={this.props.isAuth}>
                <DriverStatementTrip />
              </Protected>
            )}
          />
            
            <Route path="/user-report" component={UserReport} />
            <Route path="/driver-report" component={DriverReport} />
            <Route path="/ride-report" component={RideReport} />

            <Route path="/basic-ui/buttons" render={() => <Buttons />} />
            <Route path="/basic-ui/dropdowns" render={() => <Dropdowns />} />
            <Route path="/basic-ui/typography" render={() => <Typography />} />
            <Route
              path="/form-Elements/basic-elements"
              render={() => <BasicElements />}
            />
            <Route path="/tables/basic-table" component={BasicTable} />
            <Route path="/icons/mdi" component={Mdi} />
            <Route path="/charts/chart-js" component={ChartJs} />

            <Route path="/user-pages/register-1" component={Register1} />
            <Route path="/error-pages/error-404" component={Error404} />
            <Route path="/error-pages/error-500" component={Error500} />
            <Redirect to="/login" />
          </Switch>
        </Suspense>
      </>
    );
  }
}

export default AppRoutes;
