import React,{useEffect, useState} from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import {useSelector} from 'react-redux'
import { Form } from "react-bootstrap";
import profileImg from "../../assets/images/youcab/profile.svg";
import { NavLink } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import config from '../../app/actions/config'
import { toast } from "react-toastify";
import { LoadToast, isEmpty, updateToast, validation } from "../../lib/common";
import { useHistory } from 'react-router-dom';

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const riderDetails = [
  {
    id: 1,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 2,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 3,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 4,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 5,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 6,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 7,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 8,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 9,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
  {
    id: 10,
    rideId: "125444144",
    rideName: "Testing",
    date: "28/09/2023",
    pickupAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    dropAddress: {
      door: "No 70,",
      street: "Periyar Bus Stand",
      Landmark: "Railway Station",
      city: "Madurai",
    },
    cancelBy: "Rider",
    cancellationReason: "Lorem Ipsum",
  },
];

const Profile = () => {
  const axios = useAxiosFile()
  const {admindetails}=useSelector((state) => state.Youcabdata)

  var history=useHistory()
  //
  const initial = {
    from : "update",
    fullname:"",
    email:"",
    mobileno:"",
    profile:""
  };
   const [formdata,setFormdata]=useState(initial)
   const [err, setErr] = useState({});


   useEffect(()=>{
    if(admindetails){
      setFormdata({...formdata,...{...admindetails}})
    }
   },[admindetails])

   const onchange=(e)=>{
    const {id,value,files}=e.target;
    if(files){
      if(files[0]?.type?.includes('image')){
      setFormdata({...formdata,...{[id]:files[0]}})
      }
      else{
        toast.warning('Please upload a image files only')
      }
    }
    else{
      setFormdata({...formdata,...{[id]:value}})
    }
   }

   const onsubmit =async ()=>{
    var senddata={
      _id : formdata?._id,
      from : formdata.from,
      fullname : formdata?.fullname,
      emailid : formdata.email,
      mobileno : formdata?.mobileno,
      profile : formdata?.profile,
    }
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    if (Object.keys(err).length > 0) {
      updateToast(id, "fix");
      setErr(err);
      return;
    }
    var resp = await axios.updateprofile(senddata)

    updateToast(
      id,
      !isEmpty(resp) ? resp?.msg: "Try again",
      resp.success
    );
    if(resp?.success == 'success'){
      setErr({})
      await axios.AlreadyExits()
      history.push('/dashboard')
    }

   }




  return (
    <div style={{ color: "black" }}>
      <div className="row">
        <div className=" col-10">
          <h4 className="card-title new_headings mt-3  mb-4 ">Profile</h4>
        </div>
        <div className=" col-2 mt-3 d-flex justify-content-end ">
          <a
            href="/"
            className=" text-sm-right mb-4"
            style={{ color: "black" }}
          >
            Back
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row">
                <div className="col-12 d-flex justify-content-center flex-column align-items-center">
                  <div className="img_round">

                    <img className="img_round" src={(typeof(formdata?.profile)!='string'&&formdata?.profile?.type)?
                    URL.createObjectURL(formdata?.profile):
                    admindetails.profile?
                  `${config.ImG}/adminimages/${admindetails._id}/${admindetails.profile}`:
                  profileImg} alt="" />

                  </div>
                  <input type="file" id="profile" className="active_btn update_btn mt-2" onChange={(e)=>onchange(e)}/>
                  {err?.profile && (
                      <p className="text-left err_msg_txt">{err?.profile}</p>
                    )}
                  <p className="mt-2">Update Profile</p>


                </div>

                <div className="col-12 mt-4">
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1" className="label-color">
                      Full Name
                    </label>

                    <Form.Control
                      type="email"
                      className="form-control new_input"
                      id="fullname"
                      value ={formdata?.fullname}
                      onChange={onchange}
                      placeholder="Enter your full name"
                    />
                     {err?.fullname && (
                      <p className="text-left err_msg_txt">{err?.fullname}</p>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1" className="label-color">
                      Email
                    </label>

                    <Form.Control
                      type="email"
                      className="form-control new_input"
                      id="email"
                      value ={formdata?.email}
                      onChange={onchange}
                      placeholder="Enter your mail id"
                    />
                    {err?.email && (
                      <p className="text-left err_msg_txt">{err?.email}</p>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1" className="label-color">
                      Mobile number
                    </label>

                    <Form.Control
                      type="text"
                      max={10}
                      className="form-control new_input"
                      id="mobileno"
                      value ={formdata?.mobileno}
                      onChange={onchange}
                      placeholder="Enter your mobile number"
                    />
                     {err?.mobileno && (
                      <p className="text-left err_msg_txt">{err?.mobileno}</p>
                    )}
                  </Form.Group>
                  <button
                    type="button"
                    className=" convert_btn reset_btn mr-4 w-100"
                    onClick={()=>onsubmit()}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
          <img src={profileImg} alt="profile" className="img-fluid w-75" />
        </div>
      </div>
    </div>
  );
};

export default Profile;
