import React, { useState } from "react";
import Select from "react-select";

import { SlCalender } from "react-icons/sl";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import DatePicker from "react-datepicker";
import dailyEarnings from "../../assets/svg/dailyEarnings.svg";
import totalRides from "../../assets/svg/totalrides.svg";
import dsCancel from "../../assets/svg/ds_cancel.svg";
import completeRides from "../../assets/svg/completerides.svg";
import totalRev from "../../assets/svg/totalrevenue.svg";
import { NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

const dueSummaryDetails = [
  {
    id: 1,
    invoice: "14,546,6565",
    received: "12,546,6565",
    totalAmountDue: "6,256,555",
  },
];
const driverDetails = [
  {
    id: 1,
    sNo: "1",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 2,
    sNo: "2",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 3,
    sNo: "3",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 4,
    sNo: "4",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 5,
    sNo: "5",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 6,
    sNo: "6",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 7,
    sNo: "7",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 8,
    sNo: "8",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 9,
    sNo: "9",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
  {
    id: 10,
    sNo: "10",
    name: "Demo Account",
    amountDue: "6,256,555",
  },
];

const options = [
  { value: "ValueOne", label: "Value One" },
  { value: "ValueTwo", label: "Value Two" },
  { value: "ValueThree", label: "Value Three" },
];

const DriverDueDetails = () => {
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Drivers Due Details
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-12 col-md-6 d-flex  align-items-center">
          <p className="table_title mb-0" style={{ whiteSpace: "nowrap" }}>
            Filter by
          </p>
          <div className="select_div min-wid w-100  ml-1 ml-sm-3 ml-md-3 ">
            <Select options={options} placeholder="Amount Due" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
              <p className="table_p">Due Summary details</p>

              <div className="table-responsive">
                <table className="table table_bgNone">
                  <thead>
                    <tr>
                      <th className="">Invoice (INR)</th>
                      <th className="">Received (INR)</th>
                      <th className="">Total Amount Due (INR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dueSummaryDetails.map((val) => {
                      return (
                        <tr key={val.rideId}>
                          <td className="">{val.invoice}</td>
                          <td className="">{val.received}</td>
                          <td className="">{val.totalAmountDue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          
          <div className="card card_bg">
            <div className="card-body">
              <div className="row mt-4">
             
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row mt-5">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
              <p className="table_p">Driver details</p>

              <div className="table-responsive">
                <table className="table table_bgNone">
                  <thead>
                    <tr>
                      <th className="" style={{ textAlign: "start" }}>
                        S.No
                      </th>
                      <th className="" style={{ textAlign: "start" }}>
                        Name
                      </th>
                      <th className="">Amount Due(INR)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {driverDetails.map((val) => {
                      return (
                        <tr key={val.rideId}>
                          <td className="" style={{ textAlign: "start" }}>
                            {val.sNo}
                          </td>
                          <td className="" style={{ textAlign: "start" }}>
                            {val.name}
                          </td>
                          <td className="">{val.amountDue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          
          <div className="card card_bg">
            <div className="card-body">
              <div className="row mt-4">
             
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DriverDueDetails;
