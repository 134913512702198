import React, { useEffect, useRef, useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
} from "react-google-maps";
import Car from "../assets/images/youcab/sedan.png";
import User from "../assets/images/faces/face1.jpg";
import { isEmpty } from "../lib/common";
// import { isEmpty } from "../..nction";
export default function GMap({ from, Alls, SetIsOpen, center, page }) {
  const Gmap = useRef(null);
  const [getCenter, SetgetCenter] = useState(
    center ? { lat: center?.[0], lng: center?.[1] } : []
  );
  const [Address, SetAddresses] = useState("");
  const [Clicked, SetClicked] = useState("");
  const GetLatLng = async (e) => {
    SetAddresses(Address);
  };
  useEffect(() => {
    GetLatLng();
  }, [Clicked]);

  var userImage = {
    url: User,
    scaledSize: new window.google.maps.Size(50, 50),
  };
  var carImage = {
    url: Car,
    scaledSize: new window.google.maps.Size(50, 50),
  };

  useEffect(() => {
    if (center?.length > 0) {
      SetgetCenter({ lat: center?.[0], lng: center?.[1] });
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        SetgetCenter(pos);
        SetClicked(pos);
      });
    }
  }, [center]);
  const MapContainer = withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={15}
      ref={Gmap}
      defaultCenter={getCenter}
      onClick={(e) => {
        SetIsOpen([e?.latLng?.lat(), e?.latLng?.lng()]);
      }}
    >
      {page == "user" && (
        <Marker
          //    clickable={true}
          // animation={}
          animation={2}
          draggable={true}
          position={getCenter}
          onDragEnd={(position) => {
            var pos = {
              lat: position.latLng.lat(),
              lng: position.latLng.lng(),
            };
            SetgetCenter(pos);
          }}
          style={{ width: "10px", height: "10px" }}
          // icon={from == "user" ? User : Car}
          onClick={(e) => {
            SetIsOpen(true);
          }}
        ></Marker>
      )}

      {Alls?.length > 0 &&
        Alls?.map((it) => {
          return (
            it && (
              <Marker
                clickable={false}
                // draggable
                position={{
                  lat: it?.currentLocation?.coordinates?.[0],
                  lng: it?.currentLocation?.coordinates?.[1],
                }}
                // onDrag={(e)=>{
                //   SetIsOpen(false)
                //   GetLatLng(e)
                //  }}
                style={{ width: "10px", height: "10px" }}
                // icon={from == "user" ? User : Car}
                icon={from == "user" ? userImage : carImage}
                onClick={(e) => {
                  SetIsOpen(true);
                }}
              />
            )
          );
        })}
    </GoogleMap>
  ));
  return (
    <>
      {Clicked && !isEmpty(getCenter) && (
        <MapContainer
          containerElement={<div style={{ height: `400px`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%`, width: "100%" }} />}
        />
      )}
    </>
  );
}
