import React, {  useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Success=()=>{
    const [sec,setsec]=useState(5)
    const history = useHistory()

    useEffect(()=>{
        var ind = setInterval(() => {
            setsec(sec-1)
        }, 1000);
        if(sec == 0){
            clearInterval(ind)
            history.push('/revenue')
        }
    },[sec])

 
    return (
      <div>
        <div className="d-flex align-items-center text-center error-page bg-primary pt-5 pb-4 h-100">
          <div className="row flex-grow">
            <div className="col-lg-8 mx-auto text-white">
              <div className="row align-items-center d-flex flex-row">
              
                <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>Success</h2>
                  <h3 className="font-weight-light">The payment succesfully executed. Will redirect in {sec} seconds</h3>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    )
  
}

export default Success
