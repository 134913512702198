import React, { useState } from "react";
import Select from "react-select";

import { SlCalender } from "react-icons/sl";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import DatePicker from "react-datepicker";
import dailyEarnings from "../../assets/svg/dailyEarnings.svg";
import totalRides from "../../assets/svg/totalrides.svg";
import dsCancel from "../../assets/svg/ds_cancel.svg";
import completeRides from "../../assets/svg/completerides.svg";
import totalRev from "../../assets/svg/totalrevenue.svg";
import { NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

const driverStatementData = [
  {
    id: 1,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    date: "28/09/23",
    earned: "2500",
    status: "Approved",
  },
  {
    id: 2,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    date: "28/09/23",
    earned: "2500",
    status: "Pending",
  },
  {
    id: 3,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    date: "28/09/23",
    earned: "2500",
    status: "Approved",
  },
  {
    id: 4,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    date: "28/09/23",
    earned: "2500",
    status: "Approved",
  },
  {
    id: 5,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    date: "28/09/23",
    earned: "2500",
    status: "Approved",
  },
];

const DriverStatement = () => {
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Drivers / Statements
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row row_width mb-5 mt-5">
        <div className="col-sm-6 col-md-4 col-xl-3">
          <div className="card border_none">
            <div className="card-body card_padding card_earnign violet">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">Total Rides</p>
                  <p className="mb-0 earn_amount mt-1">24</p>
                </div>
                <div className="earn_img">
                  <img
                    src={totalRides}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="report pads d-flex align-items-center justify-content-between">
                <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 mt-sm-0 col-sm-6 col-md-4 col-xl-3">
          {" "}
          <div className="card border_none">
            <div className="card-body card_padding card_earnign red">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">Cancelled Ride</p>
                  <p className="mb-0 earn_amount mt-1">6</p>
                </div>
                <div className="earn_img">
                  <img
                    src={dsCancel}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="report pads d-flex align-items-center justify-content-between">
                <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-3 mt-4 mt-md-0">
          {" "}
          <div className="card border_none">
            <div className="card-body card_padding card_earnign blue">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">Completed Rides</p>
                  <p className="mb-0 earn_amount mt-1">10</p>
                </div>
                <div className="earn_img">
                  <img
                    src={completeRides}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="report pads d-flex align-items-center justify-content-between">
                <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-3 mt-4 mt-xl-0">
          {" "}
          <div className="card border_none">
            <div className="card-body card_padding card_earnign green">
              <div className="earnings d-flex align-items-center justify-content-between pads">
                <div className="earn_details">
                  <p className="mb-0 earn_duration">Total Revenue</p>
                  <p className="mb-0 earn_amount mt-1">$ 2153</p>
                </div>
                <div className="earn_img">
                  <img
                    src={totalRev}
                    alt="Daily Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="report pads d-flex align-items-center justify-content-between">
                <p className="mb-0 earn_duration">View Report</p>
                <BiChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className=""> Ride Id </th>

                      <th className="" style={{ textAlign: "start" }}>
                        {" "}
                        Pickup Address
                      </th>
                      <th className="" style={{ textAlign: "start" }}>
                        {" "}
                        Drop Address
                      </th>
                      <th className=""> Date</th>
                      <th className=""> Earned</th>
                      <th className=""> Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {driverStatementData.map((val) => {
                      return (
                        <tr key={val.rideId}>
                          <td className="">{val.rideId}</td>
                          <td style={{ textAlign: "start" }}>
                            <p className="">{val.pickupAddress.doorNo}</p>
                            <p className="">{val.pickupAddress.street}</p>
                            <p className="">{val.pickupAddress.landmark}</p>
                            <p className="">{val.pickupAddress.city}</p>
                          </td>
                          <td className="" style={{ textAlign: "start" }}>
                            <p className="">{val.dropAddress.doorNo}</p>
                            <p className="">{val.dropAddress.street}</p>
                            <p className="">{val.dropAddress.landmark}</p>
                            <p className="">{val.dropAddress.city}</p>
                          </td>
                          <td className="">{val.date}</td>
                          <td className="">{val.earned}</td>
                          <td>
                            <button
                              className={
                                val.status === "Pending"
                                  ? "active_btn orange"
                                  : val.status === "Approved"
                                  ? "active_btn"
                                  : ""
                              }
                            >
                              {val.status}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          
          <div className="card card_bg">
            <div className="card-body">
              <div className="row mt-4">
             
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DriverStatement;
