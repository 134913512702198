import React from 'react'
import {  Redirect } from "react-router-dom"
function Protected(props) {
  if(props.isLogin){
if(!props.isSignedIn){
  return props.children
}else{
  return <Redirect to="/dashboard"/>
}
  }
  else{
    if (!props.isSignedIn) {
      return <Redirect to="/"/>
    }else{
      return props.children
    }
  }
  
}
export default Protected