export const handleCallback = (callback) => {
    return async (data) => {
      try {
         return await callback(data);
      } catch (error) {
        console.error("error TRY CATCH",error)
       return ({ 
        error:error,
          success :"error",
          data : [],
          msg  : `${'From user' + error.toString()}`
      } )
      }
    };
  };