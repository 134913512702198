import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { isEmpty } from "../../lib/common";

const options = [
  { value: "Approved", label: "Approved" },
  { value: "Blocked", label: "Blocked" },
];


const Driverval = () => {

  const axiosFile = useAxiosFile()
  const history = useHistory();
  const [updatedata, setUpdatedata] = useState()
  const location = useLocation()
  const [Driver, SetDriver] = useState([])
  useEffect(() => {
  if (location?.state) {
      SetDriver(location?.state?.state)
      
    }
  }, [location?.state])

 
  const GetDriverData = async () => {
    var data = await axiosFile.listDriverdata({
      type: "listall",
      _id: location?.state?.state
    })
   
    SetDriver(data?.data?.[0])
  }

  const [Error,setError] = useState({})

  const dataupdate = async () => {

   
    if(isEmpty(Transaction)) {
      setError({Transaction:'Transaction Id Required'})
    return false
    }
  var senddata = {
   _id:Driver?._id,
   Transaction,
   TotalAmount_id:Driver?.TotalPrice_id,
    Cancel_id:Driver?.Cancel_id
    }

    var data = await axiosFile.updateridedetail(senddata)
   
    if (data?.success == "success") {
      toast.success('Status Changed Successfully')

      history.push('/driver-report')

    }
    else {
      toast.error('Try Again')

    }

    // }
  }
  const changefn = (e, name) => {

    var { value } = e
    setUpdatedata(value)

  };

  // const [Amount , SetAmount] = useState(0)
  const [Transaction , SetTransaction] = useState("")
  

  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Drivers/Status
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9 col-md-7 col-xl-6 grid-margin stretch-card">
          <div className="card form-card">
            <div className="card-body pt-5">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    
                    accountnumber
                  </label>
                  <Form.Control
                    type="text"
                    disabled="true"
                    className="new_input"
                    value={Driver?.accountdetails?.accountnumber}

                  />
                </Form.Group>


                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    ifsc
                  </label>
                  <Form.Control
                    type="text"
                    disabled="true"
                    className="new_input"
                    value={Driver?.accountdetails?.ifsc}

                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    holdername
                  </label>
                  <Form.Control
                    type="text"
                    disabled="true"
                    className="new_input"
                    value={Driver?.accountdetails?.holdername}

                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    branch
                  </label>
                  <Form.Control
                    type="text"
                    disabled="true"
                    className="new_input"
                    value={Driver?.accountdetails?.branch}

                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    bank
                  </label>
                  <Form.Control
                    type="text"
                    disabled="true"
                    className="new_input"
                    value={Driver?.accountdetails?.bank}

                  />
                </Form.Group>

                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                   Amount to be paid ( Total Amount + Cancel Amount)
                  </label>
                  <Form.Control
                    type="text"
                    disabled="true"
                    className="new_input"
                    value={Driver?.TotalAmount + Driver?.cancel}

                  />
                </Form.Group>
               
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                  Transaction Id
                  </label>
                  <Form.Control
                    type="text"
                    
                    className="new_input"
                   onChange={(e)=>SetTransaction(e?.target?.value)}

                  />
            <span className="error-msg">{Error?.Transaction}</span>

                </Form.Group>
                <div className="mt-5">

                  <div
                    onClick={() => dataupdate()}
                    className=" convert_btn reset_btn"
                  >
                    Save
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driverval;
