import React from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const options = [
  { value: "ValueOne", label: "Value One" },
  { value: "ValueTwo", label: "Value Two" },
  { value: "ValueThree", label: "Value Three" },
];
const riderHistoryDetails = [
  {
    id: 1,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    driverName: "James",
    pickupTime: "12:26:02 PM",
    dropTime: "02:26:02 PM",
    vehicleType: "Sedan",
    status: "Pending",
  },
  {
    id: 2,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    driverName: "James",
    pickupTime: "12:26:02 PM",
    dropTime: "02:26:02 PM",
    vehicleType: "Sedan",
    status: "Pending",
  },
  {
    id: 3,
    rideId: "12544414",
    pickupAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    dropAddress: {
      doorNo: "No 78,",
      street: "Periyar Bus stand",
      landmark: "Railway station",
      city: "Madurai",
    },
    driverName: "James",
    pickupTime: "12:26:02 PM",
    dropTime: "02:26:02 PM",
    vehicleType: "Sedan",
    status: "Pending",
  },
];

const RiderHistory = () => {
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Rider History
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
            
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-9 d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                  <p className="table_title">Filter by</p>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="Status" />
                  </div>
                  <div className="select_div w-100 ml-1 ml-md-3  mt-3 mt-sm-0">
                    <Select options={options} placeholder="Import" />
                  </div>
                  <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                    <Select options={options} placeholder="Export" />
                  </div>
                </div>
                <div className="col-12 col-md-3 mt-4 mt-md-0 ">
                  <NavLink
                    className="d-flex align-items-center justify-content-center rst_btn mx-auto ml-sm-auto mx-sm-0  surcharge_btn"
                    style={{ whiteSpace: "nowrap" }}
                    to="/add-new-riders"
                  >
                    Add New Riders
                    <span>
                      <AiOutlinePlus />
                    </span>
                  </NavLink>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 grid-margin">
                  <div className="card ride_card">
                    <div className="card-body table_card_body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className=""> Ride Id </th>
                              <th className="" style={{ textAlign: "start" }}>
                                Pickup Address{" "}
                              </th>
                              <th className="" style={{ textAlign: "start" }}>
                                {" "}
                                Drop Address
                              </th>
                              <th className=""> Driver Name </th>
                              <th className=""> Pickup Time</th>
                              <th className=""> Drop Time</th>
                              <th className=""> Vehicle Type</th>
                              <th className=""> Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {riderHistoryDetails.map((val) => {
                              return (
                                <tr key={val.rideId}>
                                  <td className="">{val.rideId}</td>
                                  <td style={{ textAlign: "start" }}>
                                    <p className="">
                                      {val.pickupAddress.doorNo}
                                    </p>
                                    <p className="">
                                      {val.pickupAddress.street}
                                    </p>
                                    <p className="">
                                      {val.pickupAddress.landmark}
                                    </p>
                                    <p className="">{val.pickupAddress.city}</p>
                                  </td>
                                  <td
                                    className=""
                                    style={{ textAlign: "start" }}
                                  >
                                    <p className="">{val.dropAddress.doorNo}</p>
                                    <p className="">{val.dropAddress.street}</p>
                                    <p className="">
                                      {val.dropAddress.landmark}
                                    </p>
                                    <p className="">{val.dropAddress.city}</p>
                                  </td>

                                  <td className="">{val.driverName}</td>
                                  <td className="">{val.pickupTime}</td>
                                  <td className="">{val.dropTime}</td>
                                  <td className="">{val.vehicleType}</td>
                                  <td>
                                    <button
                                      className={
                                        val.status === "Pending"
                                          ? "active_btn orange"
                                          : ""
                                      }
                                    >
                                      {val.status}
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-end justify-content-md-end pagination_row mt-3 mx-0">
        <p className="mb-0 pagination_btn">Prev</p>
        <div className="pagination_overflow">
          <div className="dis_inline_parent">
            <div className="pagination_active convert_btn reset_btn dis_inline">
              1
            </div>
            <div className="pagination_count convert_btn dis_inline">2</div>
            <div className="pagination_count convert_btn dis_inline">3</div>
            <div className="pagination_count convert_btn dis_inline">4</div>
            <div className="pagination_count convert_btn dis_inline">5</div>
          </div>
        </div>

        <p className="mb-0 pagination_btn ">Next</p>
      </div>
    </div>
  );
};

export default RiderHistory;
