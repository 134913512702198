
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import Table from "../table/table";
import { FiSettings } from "react-icons/fi";
import { Form, NavLink } from "react-bootstrap";
import { isEmpty } from "../../lib/common";
import useAxiosFile from "../actions/useaxios";
import { toast } from "react-toastify";
import config from "../actions/config";

const options = [
  { value: "all", label: "All" },
  { value: "drivers", label: "Drivers" },
  { value: "riders", label: "Riders" },
];
const options1 = [
  { value: "all", label: "All" },
  { value: "Active", label: "Active" },
  { value: "InActive", label: "InActive" },
];
const surchargeDetails = [
  {
    id: 1,
    location: "Madurai Airport",
    pickupSurcharge: "1.50",
    dropoffSurcharge: "1.50",
    vehicleType: "Basics",
    status: "Active",
    action: <FiSettings />,
  },
  {
    id: 2,
    location: "Madurai Airport",
    pickupSurcharge: "1.50",
    dropoffSurcharge: "1.50",
    vehicleType: "Basics",
    status: "Active",
    action: <FiSettings />,
  },
];

const RateCard = () => {
  const axiosFile = useAxiosFile();

  const [surchargeDetails, setSurchargeDetails] = useState([]);
  const [errors, setError] = useState({});
  const [addnewdata, setAddnewdata] = useState({});
  
  const [all, setAll] = useState("all");
  const [Alllabel, setAlllabel] = useState("All");

  const [status, setStatus] = useState("all");
  const [statuslabel, setStatuslabel] = useState("All");

  const [deletedid, setDeletedid] = useState("");
  const [updateId, setupdateId] = useState("");

  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    if (pages && limit) {
      ratesurcharge();
      setAddnewdata(addnewdata);
      deleteratesurcharge();
    }
  }, [ updateId]);

  useEffect(() => {
    if (pages && limit) {
      ratesurcharge("data");
    }
  }, [all, status]);

  useEffect(() => {
    if (pages && limit) {
      ratesurcharge("pages");
    }
  }, [pages, limit])

  // useEffect(() => {
  //   if(pages && limit){
  //     if (e.target.checked == true) {
  //       setAddnewdata({ ...addnewdata, ...{ [e.target.id]: "Active" } })
  //     }
  //     else {
  //       setAddnewdata({ ...addnewdata, ...{ [e.target.id]: "InActive" } })
  //     }
  //   }
  // }, [])
  const ratesurcharge = async (data) => {
    if (data == "pages") {
      let senddata = {
        action: "filter",
        page: pages,
        limit: limit,
      };
      if (all == "drivers") {
        senddata.drivers = status;
      } else if (all == "riders") {
        senddata.riders = status;
      }
      var res = await axiosFile.ratesurcharge(senddata);
      
      setSurchargeDetails(res?.data?.data);
    } else {
      let senddata = {
        action: "filter",
        page: pages,
        limit: limit,
      };
      if (all == "drivers") {
        senddata.drivers = status;
      } else if (all == "riders") {
        senddata.riders = status;
      }
      var res = await axiosFile.ratesurcharge(senddata);
      
      setSurchargeDetails(res?.data?.data);
    }
  };
  const deleteratesurcharge = async () => {
    var senddata = {
      _id: deletedid,
      action: "delete",
    };
    var data = await axiosFile.deleteratesurcharge(senddata);
    if(data.sucess='success'){
      toast.success(data.msg)
      ratesurcharge();


    }
    else{
      toast.error(data.msg)

    }
  };

  const changefn = (e) => {
    setError("");
    var { id, value } = e.target;
    setAddnewdata({ ...addnewdata, ...{ [e.target.id]: e.target.value } });
  };

  const changefn1 = (e) => {

    setAddnewdata({ ...addnewdata, ...{ [e.target.id]: e.target.checked } })
    if (e.target.checked == true) {
      setAddnewdata({ ...addnewdata, ...{ [e.target.id]: "Active" } });
    } else {
      setAddnewdata({ ...addnewdata, ...{ [e.target.id]: "InActive" } });
    }
  };

  const resetbutton = async () => {
    // setAddnewdata({})
    setAll("all")
    setAlllabel('All')
    setStatuslabel('All')
    setStatus("all")
    setpages(1)
    setLimit(5)
    }

  const proceedbutton = async (e) => {
    // debugger
    var value = addvalidation()
    
    if (!isEmpty(value)) {
      setError(value);
    } else {
      let senddata;
      if (addnewdata._id != undefined) {
        senddata = {
          ...addnewdata,
          ...{ _id: addnewdata._id, action: "update" },
        };
      } else {
        senddata = { ...addnewdata, ...{ action: "add" } };
      }

      var data = await axiosFile.ratesurcharge(senddata);
      if(!isEmpty(data)){
toast.success("Surcharege added successfully")
      document.getElementById("closeexampleModalCenter").click();
      ratesurcharge();
      }
    }
  };

  const addvalidation = () => {
    

    let errors = {};
    var Numbervalisdation=config.NumOnly

    if (isEmpty(addnewdata?.name)) {
      errors.name = "Name field is required";
    }
    if (isEmpty(addnewdata?.rate)) {
      errors.rate = "Rate field is required";
    }
    
    else if(!Numbervalisdation.test(addnewdata?.rate)){
      errors.rate = "Rate field must be number";
    }
    if (isEmpty(addnewdata?.location)) {
      errors.location = "Location field is required";
    }
    if (isEmpty(addnewdata?.pickup)) {
      errors.pickup = "Pickup field is required";
    }
    else if(!Numbervalisdation.test(addnewdata?.pickup)){
      errors.pickup = "Pickup field must be number";
    }
    if (isEmpty(addnewdata?.dropoff)) {
      errors.dropoff = "Dropoff field is required";
    }
    else if(!Numbervalisdation.test(addnewdata?.dropoff)){
      errors.dropoff = "Dropoff  field must be number";
    }
    if (isEmpty(addnewdata?.vehicletype)) {
      errors.vehicletype = "Vehicletype field is required";
    }

    return errors;
  };
  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    { key: "location", label: "Location", html: "" },
    { key: "pickup", label: "Pickup Surcharge", html: "" },

    { key: "dropoff", label: "Dropoff Surcharge", html: "" },

    { key: "vehicletype", label: "Vehicle", html: "" },

    { key: "drivers", label: "Drivers", html: "" },

    { key: "riders", label: "Riders", html: "" },

    {
      key: "_id",
      label: "Delete",
      html: (cell, row, rowind, colind) => (
        <div>
          <button
            className="active_btn delte_btn mr-3"
            data-toggle="modal"
            data-target="#exampleModalCenterdelete"
            onClick={() => {
              setDeletedid(row?._id);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
    {
      key: "_id",
      label: "Edit",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button
            className="active_btn"
            data-toggle="modal"
            data-target="#exampleModalCenter"
            onClick={() => {
              setAddnewdata(row);
            }}
          >
            Edit
          </button>{" "}
        </div>
      ),
    },
  ];

  const Pagination = (limit, page) => {
    
    setpages(page);
    setLimit(limit);
  };
  
  return (
    <>
      <div style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Rate Card and Surge Price Management
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card card_bg">
              <div className="card-body">
                <div className="row mx-auto justify-content-between">
                  <div className="col-12 col-md-7  px-0 col-xl-9">
                    <div className="row ">
                      <div className="col-12 col-sm-6 col-xl-4 max_wid_25">
                        <div className="row align-items-center">
                          <div className="col-4 pr-md-0 ">
                            <p className="table_title ">Filter by</p>
                          </div>

                          <div className="col-8">
                            <Select
                              options={options}
                              value={{label:Alllabel}}
                              onChange={(e) => (setAll(e.value),setAlllabel(e.label))}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-xl-4  mt-4 mt-sm-0  max_wid_25">
                        <div className="row align-items-center">
                          <div className="col-4 pr-md-0">
                            <p className=" table_title">Status</p>
                          </div>
                          <div className="col-8 ">
                            <Select
                              options={options1}
                              value={{label:statuslabel}}
                              onChange={(e) => (setStatus(e.value),setStatuslabel(e.label))}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-none d-xl-block  max_wid_15 ">
                        <button
                          className="convert_btn reset_btn"
                          onClick={() => resetbutton()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 px-0 mt-md-0 col-12 col-md-5  px-0 col-xl-3">
                    <div className="row mx-auto justify-contern-center">
                      <div className="col-4 col-sm-4 col-md-5 pl-0 pl-md-3 d-xl-none">
                        <button
                          className="rst_btn mt-sm-0 w-100 px-0"
                          onClick={() => resetbutton()}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-8 col-sm-8 col-md-7 col-xl-12 pr-0 pl-lg-0">
                        <button
                          className="d-flex align-items-center justify-content-center rst_btn surcharge_btn  w-100 mx_width_cont_bt"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          onClick={() => setAddnewdata({})}
                        >
                          Add Surcharge
                          <span>
                            <AiOutlinePlus className="ml-1 ml-sm-3" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 grid-margin">
                    <div className="card ride_card bor_none">
                      <div className="card-body table_card_body">
                        <div className="">
                          <Table
                            column={column}
                            data={surchargeDetails}
                            Pagination={Pagination}
                            excel={true}
                            csv={true}
                            print={true}
                            search={true}
                            fileName={RateCard}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Modal content */}
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Add Surchargess
              </h5>
              <button
                type="button"
                id="closeexampleModalCenter"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setAddnewdata(null);
                  setError({});
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <Form.Group>
                <label
                  htmlFor="exampleInputConfirmPassword1"
                  className="label-color d-flex align-items-center justify-content-between"
                >
                  Name
                  <p className="required_txt mb-0">* Required</p>
                </label>
                <Form.Control
                  type="text"
                  className="form-control new_input"
                  id="name"
                  placeholder="Name"
                  value={addnewdata == null ? "" : addnewdata?.name}
                  onChange={(e) => changefn(e)}
                />
                <span id="Name-error" className="error-msg">
                  {errors && errors.name}
                </span>
              </Form.Group>

              <Form.Group>
                <label
                  htmlFor="exampleInputConfirmPassword1"
                  className="label-color d-flex align-items-center justify-content-between"
                >
                  Location
                </label>
                <Form.Control
                  type="text"
                  className="form-control new_input"
                  id="location"
                  placeholder="Location"
                  value={addnewdata == null ? "" : addnewdata?.location}
                  onChange={(e) => changefn(e)}
                />
                <span id="location-error" className="error-msg">
                  {errors && errors.location}
                </span>
              </Form.Group>

              <Form.Group>
                <label
                  htmlFor="exampleInputConfirmPassword1"
                  className="label-color d-flex align-items-center justify-content-between"
                >
                  Pickup Surcharge
                </label>
                <Form.Control
                  type="number"
                  className="form-control new_input"
                  id="pickup"
                  placeholder="Pickup Surcharge"
                  value={addnewdata == null ? "" : addnewdata?.pickup}
                  onChange={(e) => changefn(e)}
                />
                <span id="pickup Surcharge-error" className="error-msg">
                  {errors && errors.pickup}
                </span>
              </Form.Group>

              <Form.Group>
                <label
                  htmlFor="exampleInputConfirmPassword1"
                  className="label-color d-flex align-items-center justify-content-between"
                >
                  Dropoff Surcharge
                </label>
                <Form.Control
                  type="number"
                  className="form-control new_input"
                  id="dropoff"
                  placeholder="Dropoff Surcharge"
                  value={addnewdata == null ? "" : addnewdata?.dropoff}
                  onChange={(e) => changefn(e)}
                />
                <span id="dropoff Surcharge-error" className="error-msg">
                  {errors && errors.dropoff}
                </span>
              </Form.Group>

              <Form.Group>
                <label
                  htmlFor="exampleInputConfirmPassword1"
                  className="label-color d-flex align-items-center justify-content-between"
                >
                  Surcharge Type
                </label>
                <Form.Control
                  type="text"
                  className="form-control new_input"
                  id="vehicletype"
                  placeholder="Surcharge Type"
                  value={addnewdata == null ? "" : addnewdata?.vehicletype}
                  onChange={(e) => changefn(e)}
                />
                <span id=" Surcharge Typeerror" className="error-msg">
                  {errors && errors.vehicletype}
                </span>
              </Form.Group>
              <Form.Group>
                <label
                  htmlFor="exampleInputConfirmPassword1"
                  className="label-color d-flex align-items-center justify-content-between"
                >
                  Rate
                  <p className="required_txt mb-0">* Required</p>
                </label>
                <Form.Control
                  type="number"
                  className="form-control new_input"
                  id="rate"
                  placeholder="Delivery Fees"
                  value={addnewdata == null ? "" : addnewdata?.rate}
                  onChange={(e) => changefn(e)}
                />
                <span id="Rate-error" className="error-msg">
                  {errors && errors.rate}
                </span>
              </Form.Group>
              <Form.Group>
                <label
                  htmlFor="exampleInputConfirmPassword1"
                  className="label-color d-flex align-items-center justify-content-between"
                >
                  Enable For
                </label>
                <div className="modal_switch mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0">Drivers </p>
                    <label class="switch">
                      

                      <input type="checkbox" id="drivers" checked={addnewdata?.drivers== "Active" ? true : false} value={addnewdata == null ? "" : addnewdata?.drivers} onChange={(e) => changefn1(e)} />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="mb-0">Riders </p>
                    <label class="switch">
                      <input type="checkbox" id="riders" checked={addnewdata?.riders== "Active" ? true : false} value={addnewdata == null ? "" : addnewdata?.riders} onChange={(e) => changefn1(e)} />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button
                type="button"
                class="active_btn"
                onClick={() => proceedbutton()}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModalCenterdelete"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Confirm Delete
              </h5>
              <button
                 
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure delete this Rate card?</p>
              <div className="del_div mt-3 d-flex align-items-center justify-content-end">
                <button className="del_btn mr-3 active_btn " 
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                    deleteratesurcharge()
                
                  }}>Delete</button>
                <NavLink
                  to=""
                  className="can_btn active_btn"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of Modal */}
    </>
  );
};

export default RateCard;
