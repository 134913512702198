import React from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { NavLink } from "react-router-dom";
// import carAvailableImg from "../../assets/images/youcab/carAvailableMap.PNG";

const AddNewDriver = () => {
  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Add New Driver
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card form-card">
            <div className=" pt-5">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Driver Name
                  </label>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Driver Name"
                    className="new_input"
                  />
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleInputEmail1" className="label-color">
                    Mail Id
                  </label>

                  <Form.Control
                    type="email"
                    className="form-control new_input"
                    id="exampleInputEmail1"
                    placeholder="Email"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputPassword1"
                    className="label-color"
                  >
                    Password
                  </label>
                  <Form.Control
                    type="password"
                    className="form-control new_input"
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputConfirmPassword1"
                    className="label-color"
                  >
                    Confirm Password
                  </label>
                  <Form.Control
                    type="password"
                    className="form-control new_input"
                    id="exampleInputConfirmPassword1"
                    placeholder="Confirm Password"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Contact Number
                  </label>
                  <Form.Control
                    type="text"
                    id="exampleInputUsername1"
                    placeholder="Contact Number"
                    className="new_input"
                  />
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Location
                  </label>
                  <div className="form_map position-relative">
                    <div className="row justify-content-center">
                      <div className="col-9 col-xl-6 mt-4">
                        <div className="search_input form_search_input d-flex align-items-center ">
                          <FiSearch className="search_icon" />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search here"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <div className="mt-5">
                  <button className="mr-4 convert_btn ">Cancel</button>
                  <NavLink
                    to="/dashboard"
                    type="submit"
                    className=" convert_btn reset_btn"
                  >
                    Submit
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewDriver;
