import React from "react";
import { NavLink } from "react-router-dom";
export default function Delete({ show, Deletes, setshow, _id }) {
  return (
    <>
      <div
        className={show ? "modal show" : "modal"}
        style={{ display: "block" }}
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Confirm Delete
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => setshow(!show)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure delete the selected data ?</p>
              <div className="del_div mt-3 d-flex align-items-center justify-content-end">
                <button
                  className="del_btn mr-3 active_btn "
                  onClick={() => Deletes()}
                >
                  Delete
                </button>
                <button
                  className="can_btn active_btn"
                  type="button"
                  onClick={() => setshow(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={show ? "modal-backdrop fade show" : "modal-backdrop fade"}
      ></div>
    </>
  );
}
