import React from "react";
import Select from "react-select";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import sedan from "../../assets/images/youcab/sedan.png";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const vehicleTypeData = [
  {
    id: 1,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 2,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 3,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 4,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 5,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 6,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 7,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 8,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 9,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
  {
    id: 10,
    carId: "021586",
    carType: sedan,
    carName: "Sedan",
    costPerKm: "$ 42",
    status: "On",
  },
];

const VehicleType = () => {
  return (
    <>
      <div style={{ color: "black" }}>
        <div className="title_fixed">
          <div className="row">
            <div className="col-9 col-sm-10">
              <h4 className="card-title new_headings mt-3  mb-4 ">
                Vehicle Type
              </h4>
            </div>
            <div className="col-3 col-sm-2 mt-3 ">
              <NavLink
                className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
                to="/dashboard"
                
              >
                Back
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="card card_bg">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-9 d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                    <p className="table_title">Filter by</p>
                    <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                      <Select options={options} placeholder="Status" />
                    </div>
                    <div className="select_div w-100  ml-1 ml-md-3  mt-3 mt-sm-0">
                      <Select options={options} placeholder="Import" />
                    </div>
                    <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                      <Select options={options} placeholder="Export" />
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 grid-margin">
                    <div className="card ride_card">
                      <div className="card-body table_card_body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className=""> ID </th>
                                <th className=""> Car Type</th>
                                <th className=""> Car Name</th>
                                <th className=""> Cost per Km</th>
                                <th className=""> Status</th>
                                <th className=""> Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {vehicleTypeData.map((val) => {
                                return (
                                  <tr key={val.rideId}>
                                    <td className="">{val.carId}</td>
                                    <td className="">
                                      <img src={val.carType} alt="" />
                                    </td>
                                    <td className="">{val.carName}</td>

                                    <td className="">{val.costPerKm}</td>
                                    <td className="">{val.status}</td>
                                    <td className="d-flex align-items-center justify-content-center  gap-5">
                                      <button className="active_btn mr-3">
                                        Edit
                                      </button>
                                      <button
                                        className="active_btn delte_btn "
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal content */}
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Confirm Delete
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure delete this car type?</p>
              <div className="del_div mt-3 d-flex align-items-center justify-content-end">
                <button className="del_btn mr-3 active_btn ">Delete</button>
                <NavLink to="/dashboard" className="can_btn active_btn">
                  Cancel
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleType;
