import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import { DateTimeForm, LoadToast, datetimeFormat, isEmpty, updateToast, validation } from "../../lib/common";
import useAxiosFile from "../actions/useaxios";
import config from "../actions/config";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const options = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
];
const options2 = [
  { value: "Active", label: "Active" },
  { value: "Offline", label: "Offline" },
];

const AddDiscountAndOffers = () => {
  let data = useLocation();
  // const search = data?.state?.data._id != null ? data?.state?.data._id : undefined


  const axiosFile = useAxiosFile()
  const history = useHistory();
  const [addnewcoupondata, setAddnewcoupondata] = useState({})
  const [errors, setError] = useState({})
  var initialdata = {
    type: "add",
    question: "",
    answer: ""
  }
  const [faqdata, setFaqdata] = useState(initialdata)



  //   useEffect(() => {
  //     if (data?.state?.data) {
  //       setAddnewcoupondata(data?.state?.data)

  //     }
  //   }, [])
  const Submit = async () => {
    const id = LoadToast();
    let err = await validation(Object.keys(initialdata), faqdata);

    if (Object.keys(err).length > 0) {
      updateToast(id, "fix");
      setError(err);
      return;
    }


    var resp = await axiosFile.FAQlist(faqdata)

    updateToast(
      id,
      !isEmpty(resp) ? resp?.msg : "Try again",
      resp.success
    );
    if (resp?.success == 'success') {
      setError({})
      history.push('/support')
    }


  }




  const changefn = (e) => {
    var { id, value } = e.target

    setFaqdata({ ...faqdata, ...{ [e.target.id]: e.target.value } })

  };

  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Add Support         </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card form-card">
            <div className="pt-5 ">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Heading                  </label>
                  <Form.Control
                    type="text"
                    id="question"
                    placeholder="Heading"
                    className="new_input" value={faqdata?.question} onChange={(e) => changefn(e)}
                  />
                  <span id="Coupon Code-error" className="error-msg">{errors && errors.question}</span>
                </Form.Group>
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Content                  </label>
                  <CKEditor

                    editor={ClassicEditor}
                    data={faqdata?.answer}


                    onChange={(event, editor) => {
                      const data = editor.getData();

                      setFaqdata({ ...faqdata, ...{ ["answer"]: data } })

                    }}


                  />

                </Form.Group>
                <span id="Coupon Code-error" className="error-msg">{errors && errors.answer}</span>

                {/* <div>

             
                            </div> */}

                <div className="mt-5">
                  <button type="button" className=" convert_btn reset_btn" onClick={() => Submit()}>
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDiscountAndOffers;
