import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import dailyEarnings from "../../assets/svg/dailyEarnings.svg";
import { NavLink } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../table/table";
import useAxiosFile from "../actions/useaxios";
import { Link, useHistory } from "react-router-dom";



const DriverReport = () => {
  const history = useHistory()
  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);
  const [Count, setcount] = useState({
    day: 0,
    week: 0,
    month: 0,
    year: 0
  });
  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },

    { key: 'fullname', label: 'Driver Name', html: '' },
    { key: 'driverid', label: 'Driver Id', html: '' },
    { key: 'email', label: 'Email ID', html: '' },
    { key: 'mobile', label: 'Mobile Number', html: '' },
    
    
    { key: 'TotalAmount', label: 'Complete Trip Value', html: '' },
    { key: 'adminearning', label: 'Admin Earning', html: '' },
    { key: 'cancel', label: 'Cancelled Trip Value', html: '' },
    {
      key: "_id", label: "Update Payment", html: (cell, row, rowind, colind) => <div onClick={() => (history.push("/driver-value", { state: row }))}>
        View
      </div>
    },
  ];

  const Pagination = (limit, page) => {

    setpages(page);
    setLimit(limit);
  };
  const axiosFile = useAxiosFile();

  const [listdriverdata, setListdriverdata] = useState([]);

  useEffect(() => {
    // if (pages && limit) {
    listDriverData("data");
    // }
  }, [pages, limit])

  const listDriverData = async (data) => {
    try {
      let senddata = {
        page: pages, limit: limit
      }
      var res = await axiosFile.DriverReport(senddata);
      setListdriverdata(res?.data);
      setcount({
        day: res.day,
        week: res.week,
        month: res.month,
        year: res.year
      })

    } catch (e) {

    }

  }



  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row align-items-center py-3">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings  mb-0">
              {/* DriverReport and Transaction Reports */}
              Clear Dues
            </h4>
          </div>
          <div className="col-3 col-sm-2 ">
            <NavLink
              className="text-left text-sm-right  d-flex align-items-center justify-content-end "
              to="/dashboard"
              style={{ color: "black", cursor: "pointer" }}
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>

      <div className="row mb-5 mt-5">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
              <Table
                column={column}
                data={listdriverdata}
                Pagination={Pagination}
                excel={true}
                csv={true}
                print={true}
                search={true}
                fileName={"Drivers"}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          
          <div className="card card_bg">
            <div className="card-body">
              <div className="row mt-4">
             
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DriverReport;
