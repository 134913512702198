import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import useAxiosFile from "../actions/useaxios";
import { LoadToast, datetimeFormat, isEmpty, updateToast } from "../../lib/common";
import Table from "../table/table";
import Delete from "../delete/delete";
import { useSelector } from "react-redux";

const options = [
  { label: "★", value:1 },
  { label: "★★", value:2},
  { label: "★★★", value:3},
  { label: "★★★★", value:4},
  { label: "★★★★★", value:5},
];


const Settings = () => {
  const axiosFile = useAxiosFile()
  const { admindetails } = useSelector((state) => state.Youcabdata);
  const [filter,setFilter] = useState({
    star : 5,
    from:'all'
  })
  const [show, setshow] = useState(false)
  const [pages, Setpages] = useState(1)
  const [Limit, setLimit] = useState(0)
  const [List, SetList] = useState([])
  const [form, setform] = useState({})
  const Edit = (_id) => {
    // setbutton('Start')
    // history.push(`/geolocation-edit`, { state: _id })
  }
  const Deletes = (_id) => {
    setshow(true)
    setform({ ...form, ...{ ['_id']: _id } })
  }
  const updateval = async () => {
    const id = LoadToast()
       var { success, error, data, msg } = await axiosFile.rateview({
      action: 'delete',...form,adminid:admindetails?._id
    })
      updateToast(id, msg, success)
    if (!isEmpty(success)) {
     setshow(!show); getList()


    }
    else { setshow(true) }
  }
  const column = [
    { key: '', label: 'S.No', html: (cell, row, rowind, colind) => <div>{rowind + 1}</div> },
    { key: 'rideid', label: 'Rider ID'},
    { key: 'driverid', label: 'Driver Name', html: ""},
    { key: 'userid', label: 'User Name', html: ""},
    { key: 'starrating', label: 'Ratings', html: ''},
    // { key: 'reason', label: 'Comments', html:''},
    { key: 'createdAt', label: 'date', html: (cell, row, rowind, colind) => <div> {datetimeFormat(cell)}</div> },
    { key: '_id', label: 'Delete', html: (cell, row, rowind, colind) => <div> <button class="active_btn green_btn" onClick={() => Deletes(cell)}>  Delete </button> </div> },
  ]


  const Pagination = (limit,page) => {

    Setpages(page)
    setLimit(limit)
  }



  useEffect(() => {
     getList()
  }, [ pages,Limit,filter])

  const getList = async () => {
    // debugger
    var { success, data, msg } = await axiosFile.rateview({
      page: pages,
      limit: Limit,
      filter
    })

      if(!isEmpty(data)) SetList([...data])
      else SetList([])
  }
  return (
    <>
      <div>
        <div className="title_fixed">
          <div className="row">
            <div className="col-9 col-sm-10">
              <h4 className="card-title new_headings mt-3  mb-4 ">Review/Ratings</h4>
            </div>
            <div className="col-3 col-sm-2 mt-3 ">
              <NavLink
                className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
                to="/dashboard"
                
              >
                Back
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card form-card">
              <div className="pt-5">
                <form className="forms-sample">
                  <Form.Group>
                    <label
                      htmlFor="exampleInputUsername1"
                      className="label-color"
                    >
                      Select Type
                    </label>
                    <Select
                      options={[{label:'All',value:'all'},{label:'Driver',value:'driver'},{label:'Rider',value:'user'}]}

                      placeholder="All"
                      className="react_input_select new_input"
                      onChange={(e)=>{setFilter({...filter, ...{['from']:e.value}})}}
                 />

                  </Form.Group>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row  mx-0">
          <h4 className="card-title new_headings mt-3  mb-4 ">Feedbacks</h4>
          <div className="col-12 px-0">
            <div className="card card_bg">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-9  d-flex align-items-center justify-content-between  justify-content-md-start flex-column flex-sm-row ">
                    <div className="select_div w-100  ml-1 ml-md-3 mt-3 mt-sm-0">
                      <Select
                        options={options}
                        placeholder="★★★★★"
                        className="select_border_clr"
                        onChange={(e)=>{setFilter({...filter, ...{['star']:e.value}})}}
                      />
                    </div>

                  </div>

                </div>

        <Table
          column={column}
          data={List}

          Pagination={Pagination}
          excel={true}
          csv={true}
          print={true}
          search={true}
          fileName={"feedback"}
        />
       </div>
       </div>
       </div>
       </div>
      </div>


      {show &&
          <Delete
            show={show}
            _id={form?._id}
            setshow={setshow}
            Deletes={updateval}
          />}
    </>
  );
};

export default Settings;
