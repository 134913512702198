import React, { useEffect, useState } from "react";
import carMap from "../../assets/images/youcab/caravailablemap.PNG";
// import cartrack from "../../assets/images/youcab/";
import pick from "../../assets/images/youcab/pick.svg";
import drop from "../../assets/images/youcab/drop.svg";
import GMap from "../../map/GMap";
// import socket from "../actions/socket";
import { key,socket } from "../actions/socket";

const TrackCabs = () => {
  const handleCabSelect = (e) => {
    const classN = e.currentTarget.classList[1];

    if (classN === "trans_border") {
      e.currentTarget.classList.remove("trans_border");
      e.currentTarget.classList.add("border");
    } else {
      e.currentTarget.classList.remove("border");
      e.currentTarget.classList.add("trans_border");
    }
  };

  /** lp */
  const [Alls, SetAll] = useState([]);

  // useEffect(() => {
  //   TrackCabsSocket();
  // }, []);

  // const TrackCabsSocket = () => {
  //   socket?.emit(key.getalldriver);
  //   socket?.on(key?.[`alldriver`], (data) => {

  //     SetAll(data.filter((it) => it.ride != ""));
  //   });
  // };

  /** lp */

  return (
    <div style={{ color: "black" }}>
      <div className="row">
        <div className="col-12">
          <h4 className="card-title new_headings mt-3  mb-4 ">Track Cabs</h4>
        </div>
      </div>

      <div className="div_scroll d-flex align-items-center ">
        {Alls?.length > 0 ? (
          Alls?.map((it, ind) => {
            return (
              it?.ride != "" && (
                <div
                  className="track_cab trans_border"
                  onClick={handleCabSelect}
                  id={ind + 1}
                >
                  <div className="cab_data">
                    <div className="cab_no d-flex align-items-center">
                      <p className="mb-0">{it.id}</p>
                      <button className="active_btn ml-4 px-3 active_fs">
                        + {it.ride} Trip
                      </button>
                    </div>
                    <div className="cab_pick_drop position-relative d-flex mt-3">
                      <div className="car_gif_data ">
                        <div className="car_left d-flex flex-column align-items-center">
                          <img src={pick} alt="pick" className="img-fluid" />
                          <div className="dotted_line"></div>
                          <img src={drop} alt="drop" className="img-fluid" />
                        </div>
                      </div>
                      <div className="car_right w-100 ml-2">
                        <div className="cab_pickup">
                          <p className="mb-1 pickup_para">Pickup</p>
                          <p className="location mb-3">
                            {it?.triplocation?.pickup?.area}
                          </p>
                        </div>
                        <div className="cab_dropoff mt-2">
                          <p className="mb-2 pickup_para drop_clr">Dropoff</p>
                          <p className="mb-0 location">
                            {it?.triplocation?.dropoff?.area}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })
        ) : (
          <>No cab is on ride</>
        )}
      </div>
      <div className="row mt-4 mx-0">
        <h4 className="csard-title new_headings mt-3  mb-4 ">Map View</h4>
        <div className="col-12 px-0">
          <div className="position-relative">
            <GMap
              from={"driver"}
              Alls={Alls}
              page={"track"}
              // isOpen={isOpen}
              // SetIsOpen={SetIsOpen}
              // center={center}
            />

            <div className="driver_dtls_whole">
              <div className="driver_dtls">
                <div className="single_driver_dtls">
                  <img
                    className="mr-3"
                    src={require("../../assets/images/youcab/locationOne.svg")}
                    alt=""
                  />
                  <p>Approved Driver</p>
                </div>
                <div className="single_driver_dtls">
                  <img
                    className="mr-3"
                    src={require("../../assets/images/youcab/locationOne.svg")}
                    alt=""
                  />
                  <p>Approved Driver</p>
                </div>
                <div className="single_driver_dtls">
                  <img
                    className="mr-3"
                    src={require("../../assets/images/youcab/locationOne.svg")}
                    alt=""
                  />
                  <p>Approved Driver</p>
                </div>
                <div className="single_driver_dtls">
                  <img
                    className="mr-3"
                    src={require("../../assets/images/youcab/locationOne.svg")}
                    alt=""
                  />
                  <p>Approved Driver</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackCabs;
