import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { AiFillEye } from "react-icons/ai";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import Table from "../../app/table/table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Config from "../actions/config";
import { toast } from "react-toastify";
import { isEmpty } from "../../lib/common";
import Select from "react-select";

const DriverDocuments = () => {
  const axiosFile = useAxiosFile();
  var history = useHistory();

  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);
  const [vehiclenumber, setvehiclenumber] = useState("");
  const [vechicletype, setvechicletype] = useState("");
  const [Documents, setDocuments] = useState([]);
  const location = useLocation();
  var propsdata = location?.state?.Documents;

  const [index, setindex] = useState(0);

  const [show, setShow] = useState(false);
  const [id, setId] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setindex(data);
  };

  useEffect(() => {
    setvechicletype(propsdata?.vechicletype?._id)
    setvehiclenumber(propsdata?.vehiclenumber)
    setDocuments(propsdata);
    console.log("propsdata", propsdata);
  }, []);

  const Statuschange = async (data, value) => {
    let senddata = {
      driverid: Documents.driverid,
      action: "kyverifystatus",
      idtype: value,
      email: Documents?.email,

      verifystatus:
        data == "pending"
          ? "verified"
          : data == "verified"
          ? "cancelled"
          : "verified",
    };
    var data = await axiosFile.listDriverdata(senddata);
    if (!isEmpty(data.success)) {
      toast.success(data.msg);
      setDocuments(data.data);

      if (data.data.kyc) {
        history.push("/drivers");
      }
    } else {
      toast.error("Status Doesn't Change");
    }
  };
  const column = [
    {
      key: "rideId",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    {
      key: "name",
      label: "Id type",
      html: (cell, row, rowind, colind) => <div> {row.type}</div>,
    },

    {
      key: "Driverstatus",
      label: "View",
      html: (cell, row, rowind, colind) => (
        <div>
          <button
            className={
              row.Driverstatus === "online"
                ? "active_btn green_btn"
                : row.Driverstatus === "offline"
                ? "active_btn red_btn"
                : "active_btn green_btn"
            }
            onClick={() => handleShow(rowind)}
          >
            {"View"}
          </button>{" "}
        </div>
      ),
    },

    {
      key: "Driverstatus",
      label: "Document Status",
      html: (cell, row, rowind, colind) => (
        <div>{row?.status ? row?.status : "pending"}</div>
      ),
    },
    {
      key: "Driverstatus",
      label: "Document Status",
      html: (cell, row, rowind, colind) => (
        <div>
          <button
            className={
              row.status === "verified"
                ? "active_btn red_btn"
                : row.status === "cancelled"
                ? "active_btn green_btn"
                : "active_btn orange_btn"
            }
            onClick={() => Statuschange(row?.status, row.type)}
          >
            {row?.status == "verified" ? "Cancel" : "Verify"}
          </button>{" "}
        </div>
      ),
    },
  ];
  const Pagination = (limit, page) => {
    setpages(page);
    setLimit(limit);
  };

  const [List, SetList] = useState([]);
  const [varients, setvarients] = useState([]);
  
  useEffect(()=>{
    getboundryList();
    
  },[])
  const getboundryList = async (_id) => {
    try{
    

    var data = await axiosFile.geolocation({
      action: "boundaryfromprice",
      _id: _id,
      page: 1,
      limit: 5
    })
   
    
    if(!isEmpty(data)) {
      
      SetList(data?.saved?.data);setvarients(data?.saved1?.data)}
      else SetList([])
  }
  catch(e){
    console.error("coming error",e)
  }
  }
  

  async function addvehicledata(params,params1) {
    
    let senddata = {
      driverid: Documents._id,
      action: params,
      ...{[params]:params1}
      };
    console.log("params",params,params1,senddata)
    var data = await axiosFile.listDriverdata(senddata);
    if (!isEmpty(data.success)) {
      toast.success(data.msg);
      // setDocuments(data.data);

      if (data.data.kyc) {
        history.push("/drivers");
      }
    } else {
      toast.error("Status Doesn't Change");
    }
  }


  return (
    <div>
      <div className="">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Drivers / Documents
            </h4>
          </div>

          {/* <div>
          <Popup trigger={<button> Trigger</button>} position="right center">
      <div>Popup content here !!</div>
    </Popup>
    </div> */}
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="col-3 col-sm-3 mt-3 mb-3 ">
        <div
          // className="text-center text-sm-right mb-4 d-flex align-items-center justify-content-end "
          // to="/dashboard"
          style={{ color: "black", cursor: "pointer" }}
        >
          {/* Vehicle Number */}
           {/* - */}
           {/* {Documents?.vehiclenumber} */}
           <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                   Vehicle Number *
                  </label>
                  <Form.Control
                    type="text"
                    id="CouponTitle"
                    placeholder="Enter Coupon Title"
                    className="new_input" 
                    value={vehiclenumber} 
                    onChange={(e) => setvehiclenumber(String(e?.target?.value)?.toUpperCase())}
                  />
                    <button
                    type="button"
                    className=" convert_btn reset_btn mr-4"
                    onClick={() => addvehicledata("vehiclenumber",vehiclenumber)}
                  >
                    Update
                  </button>
                  {/* <span id="Coupon Code-error" className="error-msg">{errors && errors.CouponTitle}</span> */}
                </Form.Group>

        </div>
        <div
          // className="text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
          // to="/dashboard"
          style={{ color: "black", cursor: "pointer" }}
        >
                  <Form.Group>
                  <label
                    htmlFor="exampleInputPassword1"
                    className="label-color"
                  >
                    Vehicle varients
                  </label>
                 
                      <Select
                        id="vehiclevarients"
                        options={varients}
                        placeholder="SUV"
                          className="react_input_select"
                          onChange={(e) =>
                            setvechicletype(e.value)
                          }
                          value={{label:varients?.find((it)=>it._id == vechicletype)?.label,value:vechicletype}}
                        />
                  <span id="vehiclevarients-error" className="error-msg">
                    {/* {errors && errors.vehiclevarients} */}
                  </span>
                  <button
                    type="button"
                    className=" convert_btn reset_btn mr-4"
                    onClick={() => addvehicledata("vechicletype",vechicletype)}

                    // onClick={() => addvehicledata()}
                  >
                    Update
                  </button>
                </Form.Group>
         
          {/* Vehicle Type - {Documents?.vechicletype?.name} */}
        </div>
        {console.log("DocumentsDocumentsDocumentsDocuments", Documents)}
      </div>
      <div></div>
      <div className="row mt-0">
        <div className="col-12 grid-margin">
          <div className="card ride_card card_bg">
            <div className="card-body card_driver_bog">
              <div>{}</div>
              <div className="">
                <Table
                  column={column}
                  data={Documents?.documents}
                  Pagination={Pagination}
                  excel={false}
                  csv={false}
                  print={false}
                  search={true}
                  fileName={"DriverDocuments"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Uploaded Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="upload_doc_div_flex">
            {Documents?.documents?.map((data, idx) => {
              return (
                index == idx && (
                  data?.files?.map(
                    (item) =>
                      !isEmpty(item) && (
                        <>
                        <div className="img_div_upl">
                        <div className="">
                              <a
                                target="_blank"
                                href={`${Config.ImG}/driver/${Documents?._id}/${data.key}/${item}`}
                                download
                              >
                                {" "}
                                <img
                                  alt={item}
                                  src={`${Config.ImG}/driver/${Documents?._id}/${data.key}/${item}`}
                                  height={250}
                                  width={250}
                                />
                                {/* </p> */}
                              </a>
                              <br />
                              </div>
                              </div>
                            </>
                          )
                      )
                   
                  )
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DriverDocuments;
