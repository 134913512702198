import React, { useEffect, useState } from "react";
import concurrent from "../../assets/images/youcab/concurrent.svg";
import http from "../../assets/images/youcab/httd.svg";
import cpu from "../../assets/images/youcab/cpu.svg";
import ram from "../../assets/images/youcab/ram.svg";
import { NavLink } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";


const ServerMonitoring = () => {
  const axiosFile = useAxiosFile()

  const [ Perfomance , SetPerfomance] = useState()
  useEffect(()=>{
    GetServerPerformance()
  },[])
  
  const GetServerPerformance = async() => {
      var resp = await axiosFile.GetServerPerformance()
      SetPerfomance(resp)
  }

  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Add Discount
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <div className="card card_bg">
            <div className="card-body">
              <div className="monitor_div d-flex justify-content-between align-items-center">
                <div className="monitor_left d-flex align-items-center align-items-center">
                  <div className="left_img ">
                    <img src={http} alt="" className="img-fluid w-75" />
                  </div>
                  <div className="left_content ml-1 ml-lg-3">
                    <p className="mb-0 location font_s">HTTDP Process</p>
                    <p className=" pickup_para mb-0">No of process of runing</p>
                  </div>
                </div>
                <div className="monitor_right">
                  <p className="location font_s">10</p>
                </div>
              </div>
              <div className="monitor_div d-flex justify-content-between align-items-center mt-4">
                <div className="monitor_left d-flex align-items-center align-items-center">
                  <div className="left_img ">
                    <img src={concurrent} alt="" className="img-fluid w-75" />
                  </div>
                  <div className="left_content ml-1 ml-lg-3">
                    <p className="mb-0 location font_s">
                      Concurrent Apache Connections
                    </p>
                    <p className=" pickup_para mb-0">No of process of runing</p>
                  </div>
                </div>
                <div className="monitor_right">
                  <p className="location font_s">6</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 col-lg-6">
          <div className="card card_bg">
            <div className="card-body">
              <div className="cpu d-flex align-items-center justify-content-between">
                <div className="cpu_left d-flex align-items-center ">
                  <img
                    src={ram}
                    alt=""
                    className="img-fluid"
                    style={{ width: "50px" }}
                  />
                  <p className="location font_s ml-3 mb-0">CPU</p>
                </div>
                <div className="cpu_right location font_s">7.4%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-3 mt-lg-0">
          <div className="card card_bg">
            <div className="card-body">
              <div className="cpu d-flex align-items-center justify-content-between">
                <div className="cpu_left d-flex align-items-center ">
                  <img
                    src={cpu}
                    alt=""
                    className="img-fluid"
                    style={{ width: "50px" }}
                  />
                  <p className="location font_s ml-3 mb-0">RAM</p>
                </div>
                <div className="cpu_right location font_s">48.22%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerMonitoring;
