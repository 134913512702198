import axios from 'axios';
import config from './config';
import { DecryptData, Decryptdata, EncryptData, isEmpty, updateToast } from '../../lib/common';
import { useSelector, useDispatch } from 'react-redux';
import { handleCallback } from './errorhandler';
import { BsHandIndex } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';



const useAxiosFile = () => {


//* AUTH TOKEN SAVE FORMAT = 'token_a' + "=" + Resp?.token + ";" + ";path=/" ;//
const usertoken = ("; " + document.cookie)
    .split(`; token_a=`)
    .pop()
    .split(";")[0];
const authToken = useSelector((state) => state.Youcabdata?.token);
const history = useHistory()
var token = usertoken ? usertoken : authToken;


axios.defaults.baseURL = config.Back_Url;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["authorization"] = token;
axios.defaults.timeout=10000

const dispatch = useDispatch();

const axiosFunc = (async (data) => {
    try{

        let Resp = await axios(data)
       

        if (Resp?.data) {
            Resp.data = Resp.data ? isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data) : Resp.data
        }

            return Resp.data
    }
    catch(e){
       console.error("error cinufdsfds",e)
        if(e?.response?.status == 401){
            history.push('/login')
            localStorage.clear();
            document.cookie = 'token_a' + "=" + "" + ";" + ";path=/"
            dispatch({
                type: 'Admindata',
                Admin_section:{}
            })
            updateToast('','UnAuthorised User','error')
        }
        return false
    }
})

const AppenData = (data) => {

    var formdata = new FormData()
    var SendDta = Object.entries(data).map((item) => {

        if (Array.isArray(item[1])) {
            var come = item[1].map(data => {

                if (data?.type && data?.size) {
                    formdata.append(item[0], data)
                }
                else {
                    formdata.append(item[0], EncryptData(data))
                }
                return formdata
            })
            return come
        }
        else {
            if (item[1]?.type && item[1]?.size) {

                formdata.append(item[0], item[1])
            }
            else {
                formdata.append(item[0], EncryptData(item[1]))
            }
            return formdata
        }
    })
    return SendDta
}

const adminlogin = async (data) => {
    let formdata = AppenData(data)


    let SendData = {
        'method': 'POST',
        'url': `/admin/login`,
        'data': formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'authorization': "skip",
        }
    }
    // debugger
    let Resp = await axiosFunc(SendData)
    
    if (Resp?.success == 'success') {
            document.cookie = 'token_a' + "=" + Resp?.token + ";" + ";path=/"
            localStorage.setItem('Auth', "yes")
            dispatch({
                type: 'Admindata',
                Admin_section:{admindetails:Resp?.data[0],token:Resp.token}
            })

    }
    return Resp


}

const Trips =handleCallback (async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
        'method': 'POST',
        'url': `/admin/ridehistory`,
        'data': formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
        }

    }
    let Resp = await axiosFunc(SendData)
   
        return Resp

})

const AlreadyExits = async (data) => {
    let SendData = {
        'method': 'POST',
        'url': `/admin/login`,
        data:{data:EncryptData({from:'get'})},
        }

    let Resp = await axiosFunc(SendData)
    // debugger
    if (Resp?.success == 'success') {
        document.cookie = 'token_a' + "=" + Resp?.token + ";" + ";path=/"
        localStorage.setItem('Auth', "yes")

        dispatch({
            type: 'Admindata',
            Admin_section:{admindetails:Resp?.data[0],token:Resp.token}
        })
    }
        return Resp
}

const listdata = handleCallback(async (data) => {

    var senddata = {
        method: 'post',
        url: `/admin/riders`,
        data: {data : EncryptData(data)}
    }
    let Resp = await axiosFunc(senddata)

    return Resp;
})

const GetServerPerformance = handleCallback(async (data) => {
    var senddata = {
        method: 'post',
        url: '/admin/perfomance',
    }
    var resp = await axiosFunc(senddata)
    return resp
})

const rateview = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/rateview',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
    return resp
})

const geolocation = handleCallback(async (payload) => {
    

    var senddata = {
        method: 'post',
        url: '/admin/boundarymanagement',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
    
    return resp
})

const mcd = handleCallback(async (payload) => {
//

    var senddata = {
        method: 'post',
        url: '/admin/mcd',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
    
    return resp
})

const dataupdate = handleCallback(async (data) => {

    var senddata = {
        method: 'post',
        url: `/admin/riders`,
        data:{data :EncryptData(data)},

    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

const getrecords =  handleCallback(
     async function(){
    var senddata={
        method: 'get',
        url: '/admin/dashboarddetails',
        'headers' : {
            'authorization' : token
        }
    }
    var resp = await axiosFunc(senddata)

    return resp
    }
)

const updateprofile = handleCallback(async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
    'method': 'POST',
        'url': `/admin/login`,
        'data': formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
        }
    }
    let Resp = await axiosFunc(SendData)
    dispatch({
        type: 'Admindata',
        Admin_section:{admindetails:{}}
    })

    return Resp;

})
//forget
const forgetpassword = handleCallback(async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
    'method': 'POST',
        'url': `/admin/login`,
        'data': formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'authorization': "skip",

        }
    }
    let Resp = await axiosFunc(SendData)
    return Resp;

})

const changepassword = handleCallback(async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
    'method': 'POST',
        'url': `/admin/login`,
        'data': formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'authorization': "skip",
        }
    }
    let Resp = await axiosFunc(SendData)
    return Resp;

})

//driver
const listDriverdata = handleCallback(async (data) => {
    // debugger
    var senddata = {
        method: 'POST',
        url: `/admin/youcabdrivers`,
        "data":{data :EncryptData(data)},
        headers:{
            "Content-Type":"application/json",
        }
    }
    //
    let Resp = await axiosFunc(senddata)
    return Resp;

})

const ratesurcharge = handleCallback(async (data) => {

    var senddata = {
        method: 'post',
        url: `/admin/ratesurcharge`,
        data:{data : EncryptData(data)},

    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

//discount and offers
const discountandoffer = handleCallback(async (data) => {
    let formdata = AppenData(data)

    var senddata = {
        method: 'post',
        url: `/admin/coupons`,
        data: formdata[0],

    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

const addcoupondata = handleCallback(async (data) => {
    let formdata = AppenData(data)
    

    var senddata = {
        method: 'post',
        url: `/admin/coupons`,
        data:formdata[0],
        headers:{
            'Content-Type': 'multipart/form-data',

        }

    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

const deletecoupondata = handleCallback(async (data) => {

    var senddata = {
        method: 'post',
        url: `/admin/coupons`,
        data:{data : EncryptData(data)},

    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

const deleteratesurcharge = handleCallback(async (data) => {

    var senddata = {
        method: 'post',
        url: `/admin/ratesurcharge`,
        data:{data : EncryptData(data)},

    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

const ActivityAction = handleCallback(async (data) => {
    var senddata = {
        method: 'post',
        url: `/admin/activity`,
        data:{data : EncryptData(data)},
    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

const vehicletypelist = handleCallback(async (data) => {
    let formdata = AppenData(data)

    var senddata = {
        method: 'post',
        url: `/admin/vehicletype`,
        'headers': {
            'Content-Type': 'multipart/form-data',
        },
        data:formdata[0],
    }
    let Resp = await axiosFunc(senddata)
    return Resp;

})

const addvehicledata = handleCallback(async (data) => {

    let formdata = AppenData(data)


    var senddata = {
        method: 'post',
        url: `/admin/vehicletype`,
        'headers': {
            'Content-Type': 'multipart/form-data',
        },
        data:formdata[0],
    }
    let Resp = await axiosFunc(senddata)
    
    return Resp;

})

const deletevehicletype = handleCallback(async (data) => {


    var senddata = {
        method: 'post',
        url: `/admin/vehicletype`,
        data:{data : EncryptData(data)},
    }
    let Resp = await axiosFunc(senddata)

    return Resp;

})

const FAQlist = handleCallback(async (data) => {

    var senddata = {
        method: 'post',
        url: `/admin/Faq`,
        "data":{data :EncryptData(data)},

        headers:{
            "Content-Type":"application/json",
        }
    }
    //
    let Resp = await axiosFunc(senddata)
    return Resp;

})

const CMSlist = handleCallback(async (data) => {

    var senddata = {
        method: 'post',
        url: `/admin/cms`,
        "data":{data :EncryptData(data)},

        headers:{
            "Content-Type":"application/json",
        }
    }
    //
    let Resp = await axiosFunc(senddata)
    return Resp;

})

const Driverfecth = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/driverfecth',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
    return resp
})

const revenue = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/revenue',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
   
    return resp
})

const MakePayment = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/payment',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
   
    return resp
})

const Status = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/status',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
   
    return resp
})

const DriverReport = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/driverreport',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
   
    return resp
})
const UserReport = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/userreport',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
   
    return resp
})


const updateridedetail = handleCallback(async (payload) => {

    var senddata = {
        method: 'post',
        url: '/admin/updateridedetail',
        data : {data : EncryptData(payload)}
    }
    var resp = await axiosFunc(senddata)
  
    return resp
})

const vehiclevarient = handleCallback(async (payload) => {
    let formdata = AppenData(payload)
    var senddata = {
        method: 'post',
        url: '/admin/vehiclevarient',
        data : formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
          
        }
    }
    var resp = await axiosFunc(senddata)
  
    return resp
})


const UploadExcel = handleCallback(async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
    'method': 'POST',
        'url': `/admin/excel`,
        'data': formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
        }
    }
    let Resp = await axiosFunc(SendData)
    dispatch({
        type: 'Admindata',
        Admin_section:{admindetails:{}}
    })

    return Resp;

})

const driverupdatename = handleCallback(async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
    'method': 'POST',
        'url': `/admin/driverupdatename`,
        'data': formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
        }
    }
    let Resp = await axiosFunc(SendData)
    dispatch({
        type: 'Admindata',
        Admin_section:{admindetails:{}}
    })

    return Resp;

})


const Idproofupload = handleCallback(async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
    'method': 'POST',
        'url': `/admin/idproofupload`,
        'data': formdata[0],
        'headers': {
            'Accept': "application/pdf",
            'Content-Type': 'multipart/form-data',
        }
    }
    let Resp = await axiosFunc(SendData)
    // dispatch({
    //     type: 'Admindata',
    //     Admin_section:{admindetails:{}}
    // })

    return Resp;

})


const pushnotification = handleCallback(async(data)=>{
    let formdata = AppenData(data)
    let SendData = {
    'method': 'POST',
        'url': `/admin/pushnotification`,
        'data': formdata[0],
        'headers':{
            'Content-Type': 'multipart/form-data',

        }
    }
  

    let Resp = await axiosFunc(SendData)
  
    return Resp;

})


    const DriverReportIndividualTrip = handleCallback(async(data)=>{
        var senddata = {
            method: 'post',
            url: '/admin/reportindividual',
            data : {data : EncryptData(data)}
        }
        var resp = await axiosFunc(senddata)
      
        return resp
    })


return (
    {
        
        pushnotification,
        DriverReport,UploadExcel,
        Status,  
        dataupdate,
        listDriverdata,
        ratesurcharge,
        discountandoffer,
        addcoupondata,
        deletecoupondata,
        deleteratesurcharge,
        vehicletypelist,
        addvehicledata,
        deletevehicletype,
        rateview,
        listdata,
        geolocation,
        GetServerPerformance,
        adminlogin,
        Trips,MakePayment,
        AlreadyExits,
        getrecords,ActivityAction,
        updateprofile,
        forgetpassword,
        changepassword,
        FAQlist,
        CMSlist,UserReport,
        Driverfecth,
        revenue,
        updateridedetail,vehiclevarient,mcd,driverupdatename,Idproofupload,DriverReportIndividualTrip
     }
)

}




export default useAxiosFile;