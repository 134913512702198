import React,{useEffect,useState} from "react";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { useLocation,useHistory } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import { toast } from "react-toastify";

const options = [
  { value: "Approved", label: "Approved" },
  { value: "Blocked", label: "Blocked" },
];
const RiderStatus = () => {
     let data = useLocation();
    const search = data.state._id
    // 
    const axiosFile = useAxiosFile()
    const history = useHistory();

    const [updatedata, setUpdatedata] = useState()

  
    const dataupdate = async () => {
  
      // 
      // if (search !== "" && search !== undefined) {
        var senddata = {
          from:"update",
          _id: search,actions:updatedata
        }
        var data = await axiosFile.dataupdate(senddata)

        if (data?.success == "success") {
          toast.success('Status Changed Successfully')

          history.push('/riders')
  
        }
        else{
          toast.error('Try Again')

        }
  
      // }
    }
    const changefn = (e,name) => {
      
      var {  value } = e
      setUpdatedata(value)
  
  };

  return (
    <div>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Rider Status
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9 col-md-7 col-xl-6 grid-margin stretch-card">
          <div className="card form-card">
            <div className="card-body pt-5">
              <form className="forms-sample">
                <Form.Group>
                  <label
                    htmlFor="exampleInputUsername1"
                    className="label-color"
                  >
                    Rider Status
                  </label>
                  <Select
                    options={options}
                    placeholder="select"
                    className="react_input_select"  onChange={(e) => setUpdatedata(e.value)}
                
                 />
                </Form.Group>

                <div className="mt-5  d-flex align-items-center">
                  <NavLink className="mr-4 convert_btn " to="/">
                    Cancel
                  </NavLink>
                  <div
                   onClick={()=>dataupdate()}
                    className=" convert_btn reset_btn"
                    
                  >
                    Save
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiderStatus;
