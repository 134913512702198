import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, NavLink } from "react-bootstrap";
import Lottie from "lottie-react";
import logo from "../../assets/images/youcab/youcabLogo.png";
import { LoadToast, isEmpty, updateToast, validation } from "../../lib/common";
import { toast } from "react-toastify";
import useAxiosFile from "../actions/useaxios";
import loginjson from "../../assets/json/loginbg.json";

export const Login = () => {
  const admin = useAxiosFile();
  const history = useHistory();

  const initial = {
    from: "login",
    emailid: "",
    password: "",
  };

  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [passwordView, setPasswordView] = useState(false);

  const handlePasswordIconChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
    setPasswordView(!passwordView);
  };

  const onchange = (e) => {
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const submit = async () => {
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err).length > 0) {
      updateToast(id, "fix");
      setErr(err);
      return;
    }
    setErr({});
    
    var { success, error, msg } = await admin.adminlogin(formdata);
    
    updateToast(
      id,
      !isEmpty(success) ? "Login Successfully" : "Try again",
      success
    );
    if (!isEmpty(success)) {
      history.push("/dashboard");
    } else setErr(error);
  };

  return (
    <>
      <div style={{ color: "black" }} className="login_screen_center">
        {/* <div className="d-flex flex-column justify-content-center align-items-center auth px-0 gif_bg"> */}
        <Lottie animationData={loginjson} className="login_gif" loop={true} />
        <div className="d-flex flex-column justify-content-center align-items-center auth px-0 gif_bg">
          <div className="d-flex justify-content-center">
            <img src={logo} alt="" className="img-fluid w-75" />
          </div>
          <div className="row w-100 mx-0 mt-5">
            <div className="col-sm-9 col-lg-7 col-xl-4 mx-auto">
              <div className="card text-center py-5 px-4 px-sm-5 card_bg">
                <div className="brand-logo"></div>
                <h4>Login to Admin Panel</h4>
                {/* <h6 className="font-weight-light">Sign in to continue.</h6> */}
                <Form className="pt-3">
                  <Form.Group className="search-field">
                    <Form.Control
                      type="email"
                      placeholder="Enter your mail id"
                      size="lg"
                      id="emailid"
                      value={formdata.emailid}
                      onChange={onchange}
                      className="h-auto new_input login_input"
                    />
                    
                    {err?.emailid && (
                      <p className="text-left err_msg_txt">{err?.emailid}</p>
                    )}
                  </Form.Group>



                  <Form.Group className="search-field input_with_eye">
                    <Form.Control
                      type={!passwordView?"password":"text"}
                      placeholder="Password"
                      size="lg"
                      id="password"
                      value={formdata.password}
                      onChange={onchange}
                      className="h-auto new_input login_input"
                    />

<div>
                              <span onClick={handlePasswordIconChange}>
                                {passwordView ? (
                                  <i
                                    class="fa fa-eye pss_icns"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-eye-slash pss_icns"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </span>
                            </div>
                   
                  </Form.Group>

                  {err?.password && (
                      <p className="text-left err_msg_txt">{err?.password}</p>
                    )}
                  <div className="mt-3">
                    <div
                      onClick={()=>submit()}
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn convert_btn reset_btn login_hover"
                      // to="/dashboard"
                    >
                      Login
                    </div>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      {/* <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label> */}
                    </div>
                 
                    <a href="" onClick={ ()=>{history.push("/forgetpassword")}} className="auth-link text-muted">Forgot password?</a>
                  </div>
                  {/* <div className="mb-2">
                    <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                      <i className="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                  </div> */}
                  {/* <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link to="/user-pages/register" className="text-primary">Create</Link>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
